import { TextField } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Styles, { MuiButton } from 'Styles';

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5px;
`;
const StyledLink = styled(Link)`
  display: flex;
  text-decoration: none;
  width: 100%;
  margin-top: 5px;
`;
const StyledForm = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 80vw;
  min-width: 20vw;
`;

interface LoginProps {
  username: string;
  setUsername: any;
  password: string;
  setPassword: any;
  onLogin: any;
  loginMessage: string;
}

/**
 * Basic username/password login form.
 */
const Login = (props: LoginProps) => {
  return (
    <Styles.Page style={{ alignItems: 'flex-start' }}>
      <Styles.PageHeader>Please sign in</Styles.PageHeader>
      <StyledForm onSubmit={(e) => e.preventDefault()}>
        <StyledLabel>
          Username
          <TextField
            autoCapitalize="none"
            autoComplete="username"
            autoFocus={true}
            type="text"
            value={props.username}
            onChange={(e) => {
              props.setUsername(e.target.value);
            }}
          />
        </StyledLabel>
        <StyledLabel>
          Password
          <TextField
            autoCapitalize="none"
            autoComplete="current-password"
            type="password"
            value={props.password}
            onChange={(e) => {
              props.setPassword(e.target.value);
            }}
          />
        </StyledLabel>
        <StyledLink
          to="/search"
          style={{
            display: 'flex',
            textDecoration: 'none',
            width: '100%',
            marginTop: '5px',
          }}
        >
          <MuiButton
            variant="contained"
            style={{ width: '100%' }}
            disabled={!(props.username.length > 0 && props.password.length > 0)}
            type="submit"
            onClick={() => props.onLogin()}
          >
            Sign In
          </MuiButton>
        </StyledLink>
        <label>{props.loginMessage}</label>
      </StyledForm>
    </Styles.Page>
  );
};

export default Login;
