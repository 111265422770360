import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { PDFViewer } from '@react-pdf/renderer';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import PrinterConfigForm from './PrinterConfigForm';
import { sendToPrintNode } from 'utilities/APICalls';
import { UserContext } from 'utilities/DataTypes';
import { useSnackbar } from 'notistack';

interface PrintModalProps {
  previewComponent: ReactElement<Document>;
  onCancelClick: Function;
  dimensions: [number, number];
  open: boolean;
  pdfBlob: Blob;
  pdfType: string;
  setShowPrintPreview: Function;
}



const PrintModal = (props: PrintModalProps) => {
  const [printB64, setPrintB64] = useState<string>('');
  const userContext = useContext(UserContext);
  const {enqueueSnackbar} = useSnackbar();

  const onModalPrint = () => {
    sendToPrintNode(userContext.accountName, props.pdfType, printB64)
    .catch((_) => {
        enqueueSnackbar(
          'Error printing. Please check printer settings. ',
          { variant: 'error' }
        );
      });
    props.setShowPrintPreview(false);
  };

  useEffect(() => {
    setPrintB64('')
    if (props.pdfBlob) {
      const reader = new FileReader();
      reader.onload = function () {
        if (typeof reader.result === 'string') {
          setPrintB64(reader.result.replace(/^data:.+;base64,/, ''));
        }
      };

      reader.readAsDataURL(props.pdfBlob);
    }
  }, [props.pdfBlob]);
  return (
    <Dialog
      maxWidth={false}
      onClose={() => props.onCancelClick()}
      open={props.open}
    >
      <DialogTitle>Print Preview</DialogTitle>
      <DialogContent>
        <PDFViewer
          width={props.dimensions[0]}
          height={props.dimensions[1]}
          showToolbar={false}
          style={{
            border: 'none',
            backgroundColor: 'white',
          }}
        >
          {props.previewComponent}
        </PDFViewer>
        <DialogActions>
          <Button onClick={() => props.onCancelClick()}>Cancel</Button>
          <PrinterConfigForm />
          <Button
            variant="contained"
            disabled={!printB64}
            onClick={() => onModalPrint()}
          >
            Print
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default PrintModal;
