import React, { useContext, useEffect, useState } from 'react';
import Styles, { Colors } from 'Styles';
import { API_URL } from '../env';
import Log from './Logger';
import { PAGE_WIDTH, UserContext } from '../utilities/DataTypes';
import { getBFHeaderColors, useIsMounted } from '../utilities/Helpers';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';

const StyledCaption = styled.caption`
  font-size: 20px;
  text-align: left;
  font-family: Poppins, sans-serif;
  padding: 5px;
`;
const StyledTable = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  margin-bottom: 15px;
`;

const getLoadTotals = (loadName: string, auth: string): Promise<any> => {
  return fetch(API_URL + 'getLoadTotals', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: auth,
    },
    body: JSON.stringify({
      load_name: loadName,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      Log(0, `getLoadTotals response: ${JSON.stringify(response.data)}`);
      if (
        Object.keys(response.data.totals).length > 0 ||
        Object.keys(response.data.missing).length > 0
      ) {
        return response.data;
      } else {
        return 'no data';
      }
    })
    .catch((err) => {
      Log(2, err);
      return 'error';
    });
};

const getLoadExpectedQuantity = (
  loadName: string,
  auth: string
): Promise<any> => {
  return fetch(API_URL + 'getLoadExpectedQuantity', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: auth,
    },
    body: JSON.stringify({
      load_name: loadName,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      Log(
        0,
        `getLoadExpectedQuantity response: ${JSON.stringify(response.data)}`
      );
      if (Object.keys(response.data).length > 0) {
        return response.data;
      } else {
        return 'no data';
      }
    })
    .catch((err) => {
      Log(2, err);
      return 'error';
    });
};

const getLoadExpectedMoney = (loadName: string, auth: string): Promise<any> => {
  return fetch(API_URL + 'getLoadExpectedMoney', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: auth,
    },
    body: JSON.stringify({
      load_name: loadName,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      Log(0, `getLoadExpectedMoney response: ${JSON.stringify(response.data)}`);
      if (Object.keys(response.data).length > 0) {
        return response.data;
      } else {
        return 'no data';
      }
    })
    .catch((err) => {
      Log(2, err);
      return 'error';
    });
};

interface LoadTotalsProps {
  loadName: string;
}

const LoadTotals = (props: LoadTotalsProps) => {
  const [totals, setTotals]: [any, any] = useState({});
  const [totalsMoney, setTotalsMoney]: [any, any] = useState({});
  const [totalsMissing, setTotalsMissing]: [any, any] = useState({});
  const [expectedQuantity, setExpectedQuantity]: [any, any] = useState({});
  const [expectedMoney, setExpectedMoney]: [any, any] = useState({});
  const [refreshLoop, setRefreshLoop] = useState(false);
  const userContext = useContext(UserContext);
  const isMounted = useIsMounted();
  useEffect(() => {
    if (isMounted()) {
      setTotals({});
      setExpectedMoney({});
      setExpectedQuantity({});
      getLoadTotals(props.loadName, userContext.auth).then((data: any) => {
        setTotals(data.totals);
        setTotalsMoney(data.money);
        setTotalsMissing(data.missing);
      });
      getLoadExpectedQuantity(props.loadName, userContext.auth).then(
        (data: any) => setExpectedQuantity(data)
      );
      getLoadExpectedMoney(props.loadName, userContext.auth).then((data: any) =>
        setExpectedMoney(data)
      );
    }
  }, [props.loadName, userContext.auth, isMounted]);

  useEffect(() => {
    if (isMounted() && refreshLoop) {
      setRefreshLoop(false);
      getLoadTotals(props.loadName, userContext.auth).then((data: any) => {
        setTotals(data.totals);
        setTotalsMissing(data.missing);
      });
      getLoadExpectedQuantity(props.loadName, userContext.auth).then(
        (data: any) => setExpectedQuantity(data)
      );
      getLoadExpectedMoney(props.loadName, userContext.auth).then((data: any) =>
        setExpectedMoney(data)
      );
    }
  }, [isMounted, props.loadName, userContext.auth, refreshLoop]);

  useEffect(() => {
    if (!refreshLoop && isMounted()) {
      setTimeout(() => setRefreshLoop(true), 60000);
    }
  }, [isMounted, refreshLoop]);

  if (
    totals.hasOwnProperty('RGT') &&
    totalsMoney.hasOwnProperty('RGT') &&
    totalsMissing.hasOwnProperty('RGT')
  ) {
    return (
      <Styles.Page
        style={{
          flex: '0 0 auto',
          minHeight: 0,
          minWidth: PAGE_WIDTH * 0.7,
          alignItems: 'flex-start',
        }}
      >
        {/* Expected Totals */}
        {expectedQuantity.hasOwnProperty('RGT') &&
          expectedMoney.hasOwnProperty('RGT') && (
            <StyledTable
              style={{
                width: Object.keys(expectedQuantity).length * 130,
              }}
            >
              <StyledCaption>Expected Totals</StyledCaption>
              <thead>
                <tr>
                  {Object.keys(expectedQuantity).map((key: string) => (
                    <Styles.StyledTH
                      style={{
                        width: '100%',
                        fontSize: '16px',
                        backgroundColor: getBFHeaderColors(key),
                      }}
                      key={'EXPECTED-TOTALS-HEADER-' + key}
                    >
                      {key
                        .replace(/_/g, ' ')
                        .replace(/BLACK FRIDAYS.*/, 'BLACK FRIDAYS')}
                    </Styles.StyledTH>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr style={{ borderBottom: 'thin solid grey' }}>
                  {Object.keys(expectedQuantity).map((key: string) => (
                    <Styles.StyledTD key={'QUANTITY-ROW-' + key}>
                      {expectedQuantity[key].toLocaleString()}
                    </Styles.StyledTD>
                  ))}
                </tr>
                <tr style={{ borderBottom: 'thin solid grey' }}>
                  {Object.keys(expectedMoney).map((key: string) => (
                    <Styles.StyledTD key={'MONEY-ROW-' + key}>
                      {expectedMoney[key] > 0
                        ? '$' + expectedMoney[key].toLocaleString()
                        : ''}
                    </Styles.StyledTD>
                  ))}
                </tr>
              </tbody>
            </StyledTable>
          )}
        {/* Found */}
        <StyledTable
          style={{
            width: Object.keys(totals).length * 130,
          }}
        >
          <StyledCaption>Totals Found</StyledCaption>
          <thead>
            <tr>
              {Object.keys(totals).map((key: string) => (
                <Styles.StyledTH
                  style={{
                    width: '100%',
                    fontSize: '16px',
                    backgroundColor: getBFHeaderColors(key),
                  }}
                  key={'TOTALS-HEADER-' + key}
                >
                  {key
                    .replace(/_/g, ' ')
                    .replace(/BLACK FRIDAYS.*/, 'BLACK FRIDAYS')}
                </Styles.StyledTH>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr style={{ borderBottom: 'thin solid grey' }}>
              {Object.keys(totals).map((key: string) => (
                <Styles.StyledTD key={'TOTALS-ROW-' + key}>
                  {totals[key].toLocaleString()}
                </Styles.StyledTD>
              ))}
            </tr>
            <tr style={{ borderBottom: 'thin solid grey' }}>
              {Object.keys(totalsMoney).map((key: string) => (
                <Styles.StyledTD key={'TOTALS-MONEY-ROW-' + key}>
                  {totalsMoney[key] > 0
                    ? '$' + totalsMoney[key].toLocaleString()
                    : ''}
                </Styles.StyledTD>
              ))}
            </tr>
          </tbody>
        </StyledTable>
        {/* Not Found */}
        <StyledTable
          style={{
            width: Object.keys(totalsMissing).length * 130,
          }}
        >
          <StyledCaption
            style={{
              fontSize: '20px',
              textAlign: 'left',
              fontFamily: 'Poppins, sans-serif',
              padding: '5px',
            }}
          >
            Totals Not Found
          </StyledCaption>
          <thead>
            <tr>
              {Object.keys(totalsMissing).map((key: string) => (
                <Styles.StyledTH
                  style={{
                    width: '100%',
                    fontSize: '16px',
                    backgroundColor: getBFHeaderColors(key),
                  }}
                  key={'MISSING-TOTALS-HEADER-' + key}
                >
                  {key
                    .replace(/_/g, ' ')
                    .replace(/BLACK FRIDAYS.*/, 'BLACK FRIDAYS')}
                </Styles.StyledTH>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr style={{ borderBottom: 'thin solid grey' }}>
              {Object.keys(totalsMissing).map((key: string) => (
                <Styles.StyledTD key={'MISSING-TOTALS-ROW-' + key}>
                  {totalsMissing[key].toLocaleString()}
                </Styles.StyledTD>
              ))}
            </tr>
          </tbody>
        </StyledTable>
      </Styles.Page>
    );
  } else if (totals === 'error') {
    return (
      <Styles.Page>
        <Styles.Text style={{ color: Colors.red }}>
          Error loading data.
        </Styles.Text>
      </Styles.Page>
    );
  } else {
    return (
      <Styles.Page>
        <CircularProgress sx={{ mx: 'auto' }} />
      </Styles.Page>
    );
  }
};

export default LoadTotals;
