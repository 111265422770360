import { Colors } from 'Styles';
import { useCallback, useEffect, useRef } from 'react';
import { CancelablePromise, PalletItemRow, PalletProduct } from './DataTypes';

// Helpers.tsx - Friendly helper functions :)

/**
 * Downloads an object to the end user device.
 * @param object File blob to download.
 * @param name Name for downloaded file.
 */
export const createDownload = (object: Blob, name: string) => {
  let anchor = document.createElement('a');
  anchor.download = name;
  anchor.href = (window.webkitURL || window.URL).createObjectURL(object);
  anchor.dataset.downloadurl = ['text/csv', anchor.download, anchor.href].join(
    ':'
  );
  anchor.click();
  (window.webkitURL || window.URL).revokeObjectURL(anchor.href);
};
//
// export const getDestinationColor = (text: string, userRole?: string): Promise<string> => {
//   return fetch(API_URL + "getDestinations")
//     .then((response) => response.json())
//     .then((response: { destinations: any[] }) => {
//       let rValue = "";
//       response.destinations.forEach((destination: any) => {
//         if (text === destination.destination_name) {
//           rValue = destination.destination_color;
//         }
//       });
//       if (userRole !== undefined && userRole === "novotex-sorter" && rValue.length <= 0) {
//         rValue = "#ff0000";
//       }
//       return rValue;
//     });
// }

export const getBFHeaderColors = (text: string): string => {
  if (text.includes('BLUE')) {
    return Colors.blackfridaysblue;
  } else if (text.includes('GREEN')) {
    return Colors.blackfridaysgreen;
  } else if (text.includes('YELLOW')) {
    return Colors.blackfridaysyellow;
  } else {
    return '';
  }
};

/**
 * Formats dates to send to API
 * @param date Date to format
 */
export const formattedDate = (date?: string | Date | null): string => {
  return typeof date === 'string'
    ? date.slice(0, 10)
    : date !== null && date !== undefined
    ? date.toISOString().slice(0, 10)
    : new Date().toISOString().slice(0, 10);
};

/**
 * Returns a color based on a percentage.
 *
 * @param percent current percentage to color by
 * @param colors optional set of colors to use {low: 0-50%, mid: 50-80%, high: 80-100%}
 */
export const colorByPercent = (
  percent: number,
  colors = {
    low: Colors.red,
    mid: Colors.yellow,
    high: Colors.green,
  }
) => {
  if (percent >= 80) {
    return colors.high;
  }
  if (percent >= 50) {
    return colors.mid;
  }
  return colors.low;
};

export const useIsMounted = () => {
  const isMountedRef = useRef(true);
  const isMounted = useCallback(() => isMountedRef.current, []);

  useEffect(() => {
    return () => void (isMountedRef.current = false);
  }, []);

  return isMounted;
};

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const getPalletProductQuantities = (palletItems: PalletProduct[]) => {
  let newItems: PalletItemRow[] = [];
  palletItems.forEach((item: PalletProduct) => {
    const existingIndex = newItems.findIndex(
      (e) => e.palletProductTitle === item.pallet_product_title
    );
    if (existingIndex >= 0) {
      newItems[existingIndex].palletProductQuantity += (item.pallet_product_quantity ?? 1);
    } else {
      newItems.push({
        palletProductTitle: item.pallet_product_title,
        palletProductQuantity: (item.pallet_product_quantity ?? 1),
        palletProductPrice: item.pallet_product_retail_price,
      });
    }
  });
  return newItems;
};

export const makeCancelable = (
  promise: Promise<any>
): CancelablePromise<any> => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};
