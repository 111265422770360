import React, {useState } from 'react';
import Styles, { MuiButton } from '../Styles';
import {
  PAGE_WIDTH,
  PalletItemRow,
  PalletStats,
} from '../utilities/DataTypes';
import PalletSearchTable from './PalletSearchTable';
import PalletInfoDetails from 'components/PalletInfoDetails';
import PalletProductsTable from 'components/PalletProductsTable';
import PalletPdf from './PalletPdf';
import { pdf } from '@react-pdf/renderer';
import {
  getPalletStats,
  getProducts,
} from '../utilities/APICalls';
import { getPalletProductQuantities } from '../utilities/Helpers';
import PrintModal from './PrintModal';

interface ModifyProductsProps {
  palletSku: string;
  setShowModify: any;
  readOnlyView: boolean;
  setReadOnlyView: any;
  retailPercent: number | undefined | null;
}

const ModifyProducts = (props: ModifyProductsProps) => {
  const [reloadProducts, setReloadProducts] = useState(false);
  const [currentPalletStats, setCurrentPalletStats] = useState<PalletStats>({
    avg_cost_per_unit: 0,
    avg_retail_price_per_piece: 0,
    avg_percent_of_retail: 0,
    total_price: 0,
    total_products: 0,
    total_retail: 0,
  });
  const [currentProducts, setCurrentProducts] = useState<PalletItemRow[]>([]);
  const [showPrintPreview, setShowPrintPreview] = useState<boolean>(false);
  const [pdfBlob, setPdfBlob] = useState<Blob>(new Blob());

  const onCancelClick = () => {
    setShowPrintPreview(false);
    setPdfBlob(new Blob());
  };

  return (
    <Styles.Page
      style={{
        justifyItems: 'flex-start',
        maxWidth: PAGE_WIDTH,
        maxHeight: 'none',
      }}
    >
      <Styles.PageHeader style={{ alignSelf: 'flex-start' }}>
        {props.palletSku} | {props.readOnlyView ? 'View' : 'Modify'} Products
      </Styles.PageHeader>
      {!props.readOnlyView && (
        <PalletSearchTable
          palletSku={props.palletSku}
          setReloadProducts={() => setReloadProducts(!reloadProducts)}
          addType="pallet"
        />
      )}
      <Styles.FlexDiv
        style={{
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: props.readOnlyView ? '30px' : 0,
        }}
      >
        <PalletInfoDetails
          palletSku={props.palletSku}
          reloadProducts={reloadProducts}
        />
        <MuiButton
          variant="contained"
          color="secondary"
          onClick={() => {
            getPalletStats(props.palletSku).then((palletStats) => {
              getProducts(props.palletSku).then((products) => {
                setCurrentPalletStats(palletStats);
                setCurrentProducts(getPalletProductQuantities(products));
                pdf(
                  <PalletPdf
                    palletItems={getPalletProductQuantities(products)}
                    palletStats={palletStats}
                    barcode={props.palletSku}
                    retailPercent = {props.retailPercent}
                  />
                ).toBlob().then((result) => {
                  setPdfBlob(result);
                  setShowPrintPreview(true);
                })
              });
            });
          }}
        >
          Print
        </MuiButton>
      </Styles.FlexDiv>
      <PalletProductsTable
        palletSku={props.palletSku}
        view={props.readOnlyView}
        reloadProducts={reloadProducts}
        setReloadProducts={setReloadProducts}
      />
      <Styles.FlexDiv
        style={{
          width: PAGE_WIDTH * 0.9,
          alignItems: 'center',
          marginBottom: 10,
        }}
      >
        {!props.readOnlyView && (
          <MuiButton
            variant="contained"
            style={{ marginRight: '20px' }}
            onClick={(_) => {
              props.setShowModify(false);
              props.setReadOnlyView(false);
            }}
          >
            Save Pallet
          </MuiButton>
        )}
        <MuiButton
          onClick={(_) => {
            props.setShowModify(false);
            props.setReadOnlyView(false);
          }}
          style={{ cursor: 'pointer' }}
        >
          Back to pallet list
        </MuiButton>
      </Styles.FlexDiv>
      <PrintModal
        previewComponent={
          <PalletPdf
            palletStats={currentPalletStats}
            palletItems={currentProducts}
            barcode={props.palletSku}
            retailPercent={props.retailPercent}
          />
        }
        onCancelClick={() => onCancelClick()}
        dimensions={[792, 612]}
        open={showPrintPreview}
        pdfBlob={pdfBlob}
        pdfType={'Pallet'}
        setShowPrintPreview={(value: boolean) => setShowPrintPreview(value)}
      />
    </Styles.Page>
  );
};

export default ModifyProducts;
