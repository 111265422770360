import React, {useState} from 'react';
import {MuiButton} from 'Styles';
import styled from 'styled-components';
import {
    TextField,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
  } from '@mui/material';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 10px;
`;
interface RejectReasonProps {
    showRejectFeedback: boolean;
    setShowRejectFeedback: any;
    setRejectFeedback: any;
}

const RejectReason = (props: RejectReasonProps) => {
    const [feedback, setFeedback] = useState('');

    return (
    <Dialog
      maxWidth={'sm'}
      fullWidth
      onClose={() => props.setShowRejectFeedback(false)}
      open={props.showRejectFeedback}
      style={{
        fontFamily: 'Open Sans, sans-serif',
      }}
    >
        <DialogTitle>
            Reject Feedback
        </DialogTitle>
        <DialogContent>
            <StyledDiv>
                <TextField
                placeholder = "Reason for rejecting Ex. Empty boxes, low-value product in high-value box, etc."
                value = {feedback}
                onChange = {(e)=> setFeedback(e.target.value)}
                />
            </StyledDiv>
        </DialogContent>
        <DialogActions
        sx={{
          m: 2,
        }}
      >
        <MuiButton
          variant="contained"
          color="info"
          onClick={(_) => {
            setFeedback('');
            props.setShowRejectFeedback(false);
        }}
        >
          Cancel
        </MuiButton>
        <MuiButton
        variant="contained"
        onClick={(_) => {
            props.setRejectFeedback(feedback);
            props.setShowRejectFeedback(false);
        }}
        >
            Submit
        </MuiButton>
      </DialogActions>
    </Dialog>
    )
}
export default RejectReason;