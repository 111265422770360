import React, { useContext, useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import {
  CancelablePromise,
  Printer,
  PrinterTypes,
  UserContext,
} from '../utilities/DataTypes';
import {
  addPrinter,
  deletePrinter,
  getPrinters,
  listPrinters,
  updatePrinter,
} from '../utilities/APICalls';
import Log from './Logger';
import { makeCancelable } from '../utilities/Helpers';
import { useSnackbar } from 'notistack';

const PrinterConfigForm = () => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [printerOptions, setPrinterOptions] = useState<Printer[]>([]);
  const [existingPrinters, setExistingPrinters] = useState<{
    [p: string]: number;
  }>({});
  const [selectedPrinters, setSelectedPrinters] = useState<{
    [p: string]: number;
  }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const userContext = useContext(UserContext);

  const getSelectedPrinter = (printerId: number): Printer | undefined => {
    return printerOptions.find((printer) => printer.printerId === printerId);
  };

  useEffect(() => {
    if (showDialog) {
      const listPrintersPromise = listPrinters();
      const getPrintersPromise = getPrinters(userContext.accountName);

      const cancelable = makeCancelable(
        Promise.all([listPrintersPromise, getPrintersPromise]).then(
          (response) => {
            const [availablePrinters, savedPrinters] = response;
            setPrinterOptions([
              ...availablePrinters,
              { printerName: '', computerName: '', printerId: 0 },
            ]);
            setSelectedPrinters(savedPrinters);
            setExistingPrinters(savedPrinters);
            PrinterTypes.filter(
              (type) => !Object.keys(savedPrinters).includes(type)
            ).forEach((printerType) => {
              setSelectedPrinters((prevState) => ({
                ...prevState,
                [printerType]: 0,
              }));
            });
            setLoading(false);
          }
        )
      );
      return () => {
        cancelable.cancel();
      };
    }
  }, [userContext.accountName, showDialog]);

  const onFormSave = () => {
    let requestQueue: CancelablePromise<Response>[] = [];
    Object.entries(selectedPrinters).forEach(([printerType, printerId]) => {
      if (!(existingPrinters[printerType] === printerId)) {
        if (printerId > 0) {
          if (Object.keys(existingPrinters).includes(printerType)) {
            requestQueue.push(
              makeCancelable(
                updatePrinter(userContext.accountName, printerType, printerId)
              )
            );
          } else {
            requestQueue.push(
              makeCancelable(
                addPrinter(userContext.accountName, printerType, printerId)
              )
            );
          }
        } else {
          requestQueue.push(
            makeCancelable(deletePrinter(userContext.accountName, printerType))
          );
        }
      }
    });
    Promise.all(requestQueue.map((a) => a.promise))
      .then((_) => {
        setLoading(true);
        setShowDialog(false);
        enqueueSnackbar('Saved printer settings', { variant: 'success' });
      })
      .catch((err) => {
        Log(2, err);
      });
  };

  const onFormClose = () => {
    setLoading(true);
    setShowDialog(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setShowDialog(true)}
      >
        Printer Settings
      </Button>
      <Dialog open={showDialog} sx={{ mb: '25%' }}>
        <DialogTitle>Printer Settings</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 4 }}>
            Select a printer for each type of label. This printer will be used
            every time you print a label.
          </DialogContentText>
          {loading ? (
            <CircularProgress />
          ) : (
            PrinterTypes.map((printerType) => (
              <Autocomplete
                disableClearable
                key={printerType}
                sx={{ mb: 4 }}
                getOptionLabel={(option) => {
                  const printer = getSelectedPrinter(option);
                  if (printer !== undefined && option > 0) {
                    return `${printer.printerName} - ${printer.computerName}`;
                  } else {
                    return `None`;
                  }
                }}
                options={printerOptions.map((option) => option.printerId)}
                value={selectedPrinters[printerType]}
                renderInput={(params) => (
                  <TextField {...params} label={printerType} />
                )}
                onChange={(event, value) => {
                  if (value !== null) {
                    setSelectedPrinters((prevState) => ({
                      ...prevState,
                      [printerType]: value,
                    }));
                  }
                }}
              />
            ))
          )}
        </DialogContent>
        <DialogActions sx={{ mr: 2, mb: 2 }}>
          <Button variant="text" onClick={() => onFormClose()}>
            Close
          </Button>
          <Button autoFocus variant="contained" onClick={() => onFormSave()}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PrinterConfigForm;
