import React, { useContext, useState } from 'react';
import Styles, { Colors, MuiButton } from 'Styles';
import { UserContext } from '../utilities/DataTypes';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import { API_URL } from '../env';
import Log from './Logger';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

const FormRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin-bottom: 10px;
  font-family: Open Sans, sans-serif;
`;

const addPalletProduct = (
  palletProductInfo: any,
  palletSku: string,
  auth: string
): Promise<boolean> => {
  return fetch(API_URL + 'addPalletProduct', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: auth,
    },
    body: JSON.stringify({
      fields: {
        pallet_sku: palletSku,
        pallet_product_title: palletProductInfo.pallet_product_title,
        pallet_product_retail_price:
          palletProductInfo.pallet_product_retail_price,
        pallet_product_retail_percentage: null,
        pallet_product_cost_per_unit: null,
        pallet_product_quantity: parseInt(palletProductInfo.pallet_product_quantity)
      },
    }),
  })
    .then((response) => {
      return response.status.toString().substring(0, 1) === '2';
    })
    .catch((err) => {
      Log(2, err);
      return false;
    });
};

interface AddUnlistedPalletProductProps {
  setShowForm: any;
  showForm: boolean;
  palletSku: string;
  setReloadProducts: any;
}

const AddUnlistedPalletProduct = (props: AddUnlistedPalletProductProps) => {
  const [palletProductInfo, setPalletProductInfo] = useState({
    pallet_product_title: '',
    pallet_product_retail_price: '',
    pallet_product_quantity: ''
  });
  const [showMistakes, setShowMistakes] = useState(false);
  const userContext = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Dialog open={props.showForm} maxWidth={'sm'} fullWidth>
      <DialogTitle> Add Unlisted Product </DialogTitle>
      <DialogContent>
        <FormRow>
          <label>Product Title</label>
          <TextField
            onChange={(e) =>
              setPalletProductInfo({
                ...palletProductInfo,
                pallet_product_title: e.target.value,
              })
            }
          />
          {showMistakes &&
            palletProductInfo.pallet_product_title.length === 0 && (
              <Styles.Text style={{ margin: 0, color: Colors.red }}>
                Product Title required
              </Styles.Text>
            )}
        </FormRow>
        <FormRow>
          <label>Retail Price</label>
          <TextField
            placeholder="$0.00"
            value={'$' + palletProductInfo.pallet_product_retail_price}
            onChange={(e) =>
              setPalletProductInfo({
                ...palletProductInfo,
                pallet_product_retail_price: e.target.value.replace(
                  /[^\d.]/g,
                  ''
                ),
              })
            }
          />
          {showMistakes &&
            palletProductInfo.pallet_product_retail_price.length <= 0 && (
              <Styles.Text style={{ margin: 0, color: Colors.red }}>
                Retail Price required
              </Styles.Text>
            )}
        </FormRow>
        <FormRow>
          <label>Quantity</label>
          <TextField
            type="number"
            placeholder="1"
            value={palletProductInfo.pallet_product_quantity}
            onChange={(e)=>{
              setPalletProductInfo({
                ...palletProductInfo,
                pallet_product_quantity: e.target.value
              })
            }}
          />
          {showMistakes &&
            (palletProductInfo.pallet_product_quantity === '' ? true : parseInt(palletProductInfo.pallet_product_quantity) <= 0) && (
              <Styles.Text style={{ margin: 0, color: Colors.red }}>
                Product quantity required
              </Styles.Text>
            )}
        </FormRow>
      </DialogContent>

      <DialogActions
        sx={{
          m: 2,
        }}
      >
        <MuiButton
          variant="contained"
          color="info"
          onClick={(_) => props.setShowForm(false)}
        >
          Cancel
        </MuiButton>
        <MuiButton
          variant="contained"
          disabled={
            (palletProductInfo.pallet_product_title.length <= 0 ||
              palletProductInfo.pallet_product_retail_price.length <= 0 ||
              (palletProductInfo.pallet_product_quantity === '' ? true : parseInt(palletProductInfo.pallet_product_quantity) <= 0) ) &&
            showMistakes
          }
          onClick={(_) => {
            if (
              palletProductInfo.pallet_product_title.length <= 0 ||
              palletProductInfo.pallet_product_retail_price.length <= 0 ||
              (palletProductInfo.pallet_product_quantity === '' ? true : parseInt(palletProductInfo.pallet_product_quantity) <= 0)
            ) {
              setShowMistakes(true);
            } else {
              addPalletProduct(
                palletProductInfo,
                props.palletSku,
                userContext.auth
              ).then((success) => {
                if (success) {
                  enqueueSnackbar(
                    `Added product "${palletProductInfo.pallet_product_title}" to pallet.`,
                    { variant: 'success' }
                  );
                  props.setShowForm(false);
                  props.setReloadProducts();
                } else {
                  enqueueSnackbar(
                    'Error adding product. Please check your internet connection.',
                    { variant: 'error' }
                  );
                }
              });
            }
          }}
        >
          Save
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddUnlistedPalletProduct;
