import { Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { MuiButton } from 'Styles';
import { getLoads } from '../utilities/APICalls';
import { UserContext } from '../utilities/DataTypes';

interface SelectLoadPopupProps {
  setDisplayLoad: any;
  displayLoad: boolean;
  saveLoad: any;
  savePallet: any; 
}

const SelectLoadPopup = (props: SelectLoadPopupProps) => {
  const [loadName, setLoadName] = useState('');
  const [loads, setLoads]: [any, any] = useState([]);
  const [palletName, setPalletName] = useState('');
  const userContext = useContext(UserContext);
  useEffect(() => {
    getLoads(props).then((response) => {
      setLoads(response);
    });
  }, [props]);
  const handleChange = (event: { target: { value: any; }; }) => {
    setPalletName(event.target.value);
    props.savePallet(palletName);
  };
  return (
    <Dialog
      maxWidth={'sm'}
      fullWidth
      open={props.displayLoad}
    >
        <DialogTitle>What load are you sorting?</DialogTitle>
        <DialogContent>
          <Autocomplete
            id="loads"
            options={loads.items ?? []}
            renderInput={(params) => <TextField {...params} />}
            value={loadName}
            onChange={(event, newValue: string) => {
              setLoadName(newValue)
            }}
            disableClearable
          />
          <datalist id="loads">{loads.options}</datalist>
        </DialogContent>
        <DialogTitle>What pallet are you sorting?</DialogTitle>
        <DialogContent>
          <input
            style={{
              width: "98%",
              height: "35px"
            }}
            type="text"
            name="palletName"
            id = "palletName"
            onChange = {handleChange}
          />
          {/* <datalist id="pallets">{pallets.options}</datalist> */}
        </DialogContent>
        <DialogActions sx={{m: 2, mt: 0}}>
          <MuiButton
            variant="contained"
            fullWidth
            color="error"
            disabled={userContext.selectedLoad.length <= 0}
            onClick={() => props.setDisplayLoad(false)}
          >
            Cancel
          </MuiButton>
          <MuiButton
            variant="contained"
            fullWidth
            disabled={loadName.length <= 0 || !loads.items.includes(loadName) || !palletName}
            onClick={() => {
              props.saveLoad(loadName);
              props.savePallet(palletName);
              props.setDisplayLoad(false);
            }}
          >
            Submit
          </MuiButton>
        </DialogActions>
    </Dialog>
  );
};

export default SelectLoadPopup;
