import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import Styles, { Colors, MuiButton } from 'Styles';
import { PAGE_HEIGHT, UserContext } from '../utilities/DataTypes';
import { formattedDate } from '../utilities/Helpers';
import { addLoad, updateLoad } from '../utilities/APICalls';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { LoadInfoRow } from '../utilities/DataTypes';
import { DatePicker } from '@mui/x-date-pickers';

interface LoadFormProps {
  setShowForm: any;
  showForm: boolean;
  isEditing?: boolean;
  loadInfo?: any;
  refreshTable: Function;
  loadsTable: any;
}

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  flex: 1;
  margin-bottom: 10px;
  font-family: Open Sans, sans-serif;
`;

const statusOptions = ['Not Started', 'In Progress', 'On Hold', 'Completed'];

const LoadForm = (props: LoadFormProps) => {
  const emptyLoadInfo = {
    load_name: '',
    load_source: '',
    load_status: 'Not Started',
    load_start_date: formattedDate(new Date()),
    load_sort_fuzzy_match: false,
    load_unmanifested: false,
  };
  const [loadInfo, setLoadInfo] = useState(props.loadInfo ?? emptyLoadInfo);
  const [showMistakes, setShowMistakes] = useState(false);
  const userContext = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [rowNames, setRowNames]:[Array<string>, any] = useState([]); 
  const [duplicateRowName, setDuplicateRowName] = useState(false);
  
  const getLoadNamesFromPropsTable = (loadsTable:Array<any>) => {
    let rows:any = [];
    loadsTable.forEach((row:LoadInfoRow)=>{
      rows.push(row.LOAD_NAME);
    })
    setRowNames([...rows]);
    return rows;
  }

  const duplicateNameCheck = (names:string[], name:string) => {
    return names.includes(name);
  }

  useEffect(()=>{
    getLoadNamesFromPropsTable(props.loadsTable);
  }, [props.loadsTable]);

  useEffect(()=>{
    setDuplicateRowName(duplicateNameCheck(rowNames, loadInfo.load_name));
  }, [rowNames, loadInfo.load_name]);

  useEffect(()=> {
    if(duplicateRowName){
      setShowMistakes(true);
    }
    else{
      setShowMistakes(false);
    }
  }, [duplicateRowName]);

  
  return (
    <Dialog maxWidth={'sm'} fullWidth open={props.showForm}>
      <DialogTitle>{props.isEditing ? 'Edit' : 'Add New'} Load </DialogTitle>
      <DialogContent
        style={{
          maxHeight: PAGE_HEIGHT,
        }}
      >
        <FormRow>
          <label>Load Name</label>
          <TextField
            value={loadInfo.load_name}
            disabled={props.isEditing ?? false}
            placeholder="e.g. AMZN-1195, HD-1220, TGT-1204"
            onChange={(e) =>
              setLoadInfo({
                ...loadInfo,
                load_name: e.target.value,
              })
            }
          />
          {showMistakes && loadInfo.load_name.length === 0 && (
            <Styles.Text style={{ margin: 0, color: Colors.red }}>
              Load Name required
            </Styles.Text>
          )}
            {showMistakes && !props.isEditing && duplicateRowName &&
            <Styles.Text style={{margin:0, color:Colors.red}}>
              Duplicate load name
            </Styles.Text>}
        </FormRow>

        <FormRow>
          <label>Store Name</label>
          <TextField
            placeholder="e.g. WOOT, Amazon"
            value={loadInfo.load_source}
            onChange={(e) =>
              setLoadInfo({
                ...loadInfo,
                load_source: e.target.value,
              })
            }
          />
          {showMistakes && (loadInfo.load_source == null || loadInfo.load_source.length === 0) && (
            <Styles.Text style={{ margin: 0, color: Colors.red }}>
              Store Name required
            </Styles.Text>
          )}
        </FormRow>

        <FormRow>
          <label>Status</label>
          <Autocomplete
            disablePortal
            id="status"
            options={statusOptions}
            renderInput={(params) => <TextField {...params} />}
            value={loadInfo.load_status}
            onChange={(event, newValue) => {
              setLoadInfo({
                ...loadInfo,
                load_status: newValue,
              });
            }}
            disableClearable
          />
        </FormRow>

        <FormRow style={{ marginBottom: '5px' }}>
          <label>Date Started</label>
          <DatePicker
            value={loadInfo.load_start_date}
            renderInput={(params) => (
              <TextField {...params} sx={{ width: 175, height: 50 }} />
            )}
            onChange={(newValue) => {
              setLoadInfo({
                ...loadInfo,
                load_start_date: formattedDate(newValue),
              });
            }}
          />
          {showMistakes && (loadInfo.load_start_date == null || loadInfo.load_start_date.length === 0) && (
            <Styles.Text style={{ margin: 0, color: Colors.red }}>
              Date Started required
            </Styles.Text>
          )}
        </FormRow>

        {props.isEditing && (
          <FormRow style={{ marginBottom: '5px' }}>
            <label>Date Ended</label>
            <DatePicker
              value={loadInfo.load_complete_date}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: 175, height: 50 }} />
              )}
              onChange={(newValue) => {
                setLoadInfo({
                  ...loadInfo,
                  load_complete_date: formattedDate(newValue),
                });
              }}
            />
          </FormRow>
        )}

        <FormRow>
          <label
            style={{ display: 'flex', alignItems: 'center', marginTop: '7px' }}
          >
            <Checkbox
              name="sort_fuzzy_match"
              checked={loadInfo.load_sort_fuzzy_match}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setLoadInfo({
                  ...loadInfo,
                  load_sort_fuzzy_match: e.target.checked,
                })
              }
            />
            Match first 8 when sorting
          </label>
        </FormRow>

        <FormRow>
          <label style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              name="sort_fuzzy_match"
              checked={loadInfo.load_unmanifested}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setLoadInfo({
                  ...loadInfo,
                  load_unmanifested: e.target.checked,
                });
              }}
            />
            Unmanifested load
          </label>
        </FormRow>

        {props.isEditing && (
          <FormRow style={{ marginBottom: '5px' }}>
            <label>Load Cost</label>
            <TextField
            placeholder="0.00"
              value={loadInfo.load_cost}
              onChange={(e) =>
                setLoadInfo({
                  ...loadInfo,
                  load_cost: e.target.value.replace(/[^\d.]/g, ''),
                })
              }
            />
          </FormRow>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          m: 2,
        }}
      >
        <MuiButton
          variant="contained"
          color="info"
          onClick={(_) => props.setShowForm(false)}
        >
          Cancel
        </MuiButton>
        <MuiButton
          variant="contained"
          disabled={
            (!props.isEditing && duplicateRowName)
            &&
            (
              loadInfo.load_name.length <= 0 ||
              loadInfo.load_source == null ||
              loadInfo.load_source.length <= 0 ||
              loadInfo.load_status.length <= 0 ||
              loadInfo.load_start_date == null ||
              loadInfo.load_start_date.length <= 0
            ) &&
            showMistakes
          }
          style={{ marginLeft: '20px' }}
          onClick={(_) => {
            if (
              loadInfo.load_name.length <= 0 ||
              loadInfo.load_source == null ||
              loadInfo.load_source.length <= 0 ||
              loadInfo.load_status.length <= 0 ||
              loadInfo.load_start_date == null ||
              loadInfo.load_start_date.length <= 0 ||
              (!props.isEditing && duplicateRowName)
            ) {
              setShowMistakes(true);
            } else if (props.isEditing) {
              updateLoad(userContext.auth, loadInfo).then((success) => {
                if (success) {
                  enqueueSnackbar(
                    `Updated load ${loadInfo.load_name}.`,
                    { variant: 'success' }
                  );
                  props.setShowForm(false);
                  props.refreshTable();
                } else {
                  enqueueSnackbar(
                    'Error updating load. Please check your internet connection.',
                    { variant: 'error' }
                  );
                }
              });
            } else {
              addLoad(loadInfo, userContext.auth).then((success) => {
                if (success) {
                  enqueueSnackbar(
                    `Added load ${loadInfo.load_name} to database, beginning import.`,
                    { variant: 'success' }
                  );
                  history.push(
                    `/import?return=true&load_name=${loadInfo.load_name}&load_source=${loadInfo.load_source}`
                  );
                } else {
                  enqueueSnackbar(
                    'Error adding load. Please check your internet connection.',
                    { variant: 'error' }
                  );
                }
              });
            }
          }}
        >
          {props.isEditing ? 'Update': 'Import'} Load
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
};

export default LoadForm;
