import React, { useState } from 'react';
import { PalletProduct, IndividualProductDefaultRetailPercent, PalletDefaultRetailPercent } from '../utilities/DataTypes';
import { Button } from '@mui/material';
import { exportToShopify, getPalletProductsShopify, getProducts } from '../utilities/APICalls';
import { getPalletProductQuantities } from '../utilities/Helpers';
import { useSnackbar } from 'notistack';

interface ExportToShopifyProps {
  items: any[];
  forceSync: boolean;
  ProductType: string;
}

const NORMAL_SYNC_BUTTON_TEXT = 'Sync to Shopify'
const FORCE_SYNC_BUTTON_TEXT = 'Force Sync to Shopify'

const ExportToShopify = (props: ExportToShopifyProps) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonText, setButtonText] = useState(props.forceSync ? FORCE_SYNC_BUTTON_TEXT : NORMAL_SYNC_BUTTON_TEXT);
  const { enqueueSnackbar } = useSnackbar();

 const onExportClick = async () => {
    setButtonDisabled(true);
    setButtonText('Sync in progress');
    let itemsToSend: any[] = props.items; // if individual product, no need to get products
    let productsQueue: Promise<PalletProduct[]>[] = [];
    
    // handle pallets
    if (props.ProductType === 'p') {
     
      itemsToSend.map((item) => { 
        delete item.products;
        if (!item.RetailPercent) {
          item.RetailPercent = PalletDefaultRetailPercent;
        }
        return item;
      })
      //console.log(itemsToSend);
      let palletSkusToSend: string[] = itemsToSend.map(a=> "'" + a.productSku + "'");
      let palletsConcat: string = palletSkusToSend.join(',');
      
      let palletProducts: any[] = [];
      await getPalletProductsShopify(palletsConcat)
      .then((products)=>{
        //console.log(r);
        palletProducts = products;
      });

      palletProducts.forEach(product => {
        const formattedProduct = {
          palletProductTitle: product.pallet_product_title,
          palletProductQuantity: 0,
          palletProductPrice: Number(product.palletproductprice) ?? 1,
          productDescriptionQuantity: product.sumquantity ?? 1
        }
        const pallet = itemsToSend.find(c => c.productId === product.pallet_sku);
        if (pallet){ //this will update matching pallets in itemsToSend
          if (!pallet.products){
            pallet.products = [];
          }
          pallet.products.push(formattedProduct);
        }
      });
    }

    //handle individual products
    if (props.ProductType === 'ip') {
      itemsToSend.map((item) => { 
        if (!item.RetailPercent) {
          item.RetailPercent = IndividualProductDefaultRetailPercent;
        }
        return item;
      })
    }
    void exportReady(itemsToSend);
  };


  const exportReady = (itemsToSend: any[]) => {
    //console.log("Export ready", itemsToSend);
    // console.log("Items going out", JSON.stringify({ items: itemsToSend, forceSync: false }));

    //Add products array for Shopify sync
   
    itemsToSend.forEach((item: any) => {
      if (!item.isPallet) {
        item.products = [];
      }
    });

    exportToShopify(itemsToSend, props.forceSync).then((response) => {
      //console.log('ExportToShopifyAPIResponse:', response);
      setButtonDisabled(false);
      if (props.forceSync) {
        setButtonText(FORCE_SYNC_BUTTON_TEXT);
      } else {
        setButtonText(NORMAL_SYNC_BUTTON_TEXT);
      }
      enqueueSnackbar('Syncing to Shopify in the background.', { variant: 'success' });
    }).catch((error) => {
      setButtonDisabled(false);
      if (props.forceSync) {
        setButtonText(FORCE_SYNC_BUTTON_TEXT);
      } else {
        setButtonText(NORMAL_SYNC_BUTTON_TEXT);
      }
      enqueueSnackbar(`Error syncing to Shopify. ${error}`, { variant: 'error' });
    });
    
  }

  return (
    <>
      <Button variant="contained" onClick={() => onExportClick()} disabled={buttonDisabled} color={props.forceSync ? 'error' : 'primary'}>
        {buttonText}
      </Button>
    </>
  );
};

export default ExportToShopify;
