import {
  faEdit,
  faList,
  faPlus,
  faPrint,
  faTrashAlt,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Log from 'components/Logger';
import { API_URL } from 'env';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Styles, { Colors, MuiButton } from '../Styles';
import {
  PAGE_HEIGHT,
  PAGE_WIDTH,
  PalletItemRow,
  PalletStats,
  SizeProps,
  SEVEN_DAYS_AGO,
  UserContext,
  getPalletMap
} from '../utilities/DataTypes';
import AddPalletForm from 'components/AddPalletForm';
import ModifyProducts from 'components/ModifyProducts';
import EditPalletForm from 'components/EditPalletForm';
import {
  formattedDate,
  formatter,
  getPalletProductQuantities,
} from 'utilities/Helpers';
import {
  deletePallet,
  getPalletStats,
  getProducts,
} from 'utilities/APICalls';

import PalletPdf from 'components/PalletPdf';
import { pdf } from '@react-pdf/renderer';
import PrintModal from '../components/PrintModal';
import ExportToShopify from '../components/ExportToShopify';
import { Autocomplete, Box, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

const MainTableContainer = styled.div<SizeProps>`
  display: flex;
  flex-direction: column;
  flex: 20 1 0;
  min-height: 0;
  max-height: ${(props) => props.pageHeight}px;
  overflow: scroll;
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: 0 0 5px #0003;
`;
const ActionsContainer = styled.td`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const NewPalletPopup = styled.div<SizeProps>`
  position: absolute;
  z-index: 100;
  margin-top: ${(props) => props.pageHeight * 0.1}px;
`;

const Pallets = () => {
  const userContext = useContext(UserContext);
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showModify, setShowModify] = useState(false);
  const [view, setView] = useState(false);
  const [currentSku, setCurrentSku] = useState('');
  const [currentPalletStats, setCurrentPalletStats] = useState<PalletStats>({
    avg_cost_per_unit: 0,
    avg_retail_price_per_piece: 0,
    avg_percent_of_retail: 0,
    total_price: 0,
    total_products: 0,
    total_retail: 0,
  });
  const [currentProducts, setCurrentProducts] = useState<PalletItemRow[]>([]);
  const [showPrintPreview, setShowPrintPreview] = useState<boolean>(false);
  const [refresh, setRefresh] = useState(false);
  const [editedInfo, setEditedInfo] = useState({
    pallet_sku: '',
    pallet_destination: 'VENTAS',
    pallet_status: 'Not Started',
    pallet_start_date: formattedDate(new Date()),
  });
  const [startDate, setStartDate]: [string, any] = useState(SEVEN_DAYS_AGO.toISOString().slice(0, 10));
  const [endDate, setEndDate]: [string, any] = useState( new Date().toISOString().slice(0, 10));
  const [status, setStatus]: [string, any] = useState('All');
  const [pdfBlob, setPdfBlob] = useState<Blob>(new Blob());
  const [skuMap, setSkuMap] = useState(new Map<string, string>()); // maps (sku:key, category:value) pairs
  const [PalletCategoryMap, setPalletCategoryMap] = useState(new Map<string, string>()); // maps (category:key, sku: value) pairs
  const [PalletLotCategoryMap, setPalletLotCategoryMap] = useState(new Map<string,string>());
  const [retailPercentMap, setRetailPercentMap] = useState(new Map<string, number>()); //maps (sku:key, retail percent: value) pairs
  const [palletCategories, setPalletCategories]: [string[], any] = useState([]);
  const [palletLotCategories, setPalletLotCategories]: [string[], any] = useState([]);

  const PALLETS_FIELDS = [
    'PALLET SKU',
    'CATEGORY',
    'DESTINATION',
    'STATUS',
    'DATE STARTED',
    'TOTAL PIECES',
    'AVG RETAIL PRICE',
    'TOTAL RETAIL',
    'SYNCED',
    'ACTIONS'
  ];

  const statusOptions = [
    'All',
    'Not Started',
    'In Progress',
    'On Hold',
    'Completed',
  ];

  const [results, setResults]: [any, any] = useState([]);
  
  useEffect(()=>{
    getPalletMap().then( (results: any) => {
      setSkuMap(results.skuMap ?? skuMap);
      setPalletCategoryMap(results.PalletCategoryMap ?? PalletCategoryMap);
      setPalletLotCategoryMap(results.PalletLotCategoryMap ?? PalletLotCategoryMap);
      setRetailPercentMap(results.retailPercentMap ?? retailPercentMap);
      setPalletCategories(results.palletCategoriesArray ?? palletCategories);
      setPalletLotCategories(results.palletLotCategoriesArray ?? palletLotCategories);
    });

  }, []) // Empty dependency array ensures this effect runs only on mount.
  
  useEffect(() => {
    getPallets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, status, refresh]);

  const onCancelClick = () => {
    setShowPrintPreview(false);
    setPdfBlob(new Blob());
  };

  const getCategoryFromSku = (sku:any)=> {
    const skuPrefix = sku.substring(0, sku.lastIndexOf('-'));
    if (skuMap.has(skuPrefix)){
      return skuMap.get(skuPrefix);
    } else{
      return "";
    }
  }

  const getRetailPercent = (sku: string) => {
    const skuPrefix = sku.substring(0, sku.lastIndexOf('-'));
    if (retailPercentMap.has(skuPrefix)){
      return retailPercentMap.get(skuPrefix);
    }
    else 
    return null;
  }

  const getPallets = async (): Promise<boolean> => {
    return await fetch(API_URL + 'getPallets', {
      method: 'POST',
      headers: {
        Authorization: userContext.auth,
      },
      body: JSON.stringify({
        start_date: formattedDate(startDate),
        finish_date: endDate,
        status: status,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setResults(response.data);
        return true;
      })
      .catch((err) => {
        Log(2, err);
        return false;
      });
  };

  const listResults = () => {
    let list: Array<any> = [];
    if (results !== undefined && results.length > 0) {
      list = results;
    }
    let items: any;
    items = list.map((result: any) => (
      <tr
        key={'TR-' + result.pallet_sku}
        style={{
          borderBottom: 'thin solid black',
        }}
      >
        {PALLETS_FIELDS.map((field: any) => {
          switch (field) {
            case 'SYNCED':
              return (
                <Styles.StyledTD key={`TD-${field}-${result.pallet_sku}`} style={{textAlign: 'center'}}>
                  {result.pallet_synced_to_shopify && (
                  <FontAwesomeIcon
                      icon={faCheck}
                      style={{ width: '30px', height: '30px', marginBottom: 0 }}
                      color={Colors.green}
                  />
                  )}
                </Styles.StyledTD>
              );
            case 'PALLET SKU':
              return (
                <Styles.StyledTD key={`TD-${field}-${result.pallet_sku}`}>
                  {result.pallet_sku ?? 'NONE'}
                </Styles.StyledTD>
              );
            case 'CATEGORY':
              return (
                <Styles.StyledTD key={`TD-${field}-${result.pallet_sku}`}>
                  {getCategoryFromSku(result.pallet_sku) || 'NONE'}
                </Styles.StyledTD>
              )
            case 'DESTINATION':
              return (
                <Styles.StyledTD key={`TD-${field}-${result.pallet_sku}`}>
                  {result.pallet_destination || 'NONE'}
                </Styles.StyledTD>
              );
            case 'STATUS':
              return (
                <Styles.StyledTD key={`TD-${field}-${result.pallet_sku}`}>
                  {result.pallet_status || 'NONE'}
                </Styles.StyledTD>
              );
            case 'DATE STARTED':
              return (
                <Styles.StyledTD key={`TD-${field}-${result.pallet_sku}`}>
                  {formattedDate(result.pallet_start_date || 'NONE')}
                </Styles.StyledTD>
              );
            case 'TOTAL PIECES':
              return (
                <Styles.StyledTD key={`TD-${field}-${result.pallet_sku}`}>
                  {result.total_pieces?.toLocaleString() || 'NONE'}
                </Styles.StyledTD>
              );
            case 'AVG RETAIL PRICE':
              return (
                <Styles.StyledTD key={`TD-${field}-${result.pallet_sku}`}>
                  {formatter.format(result.avg_retail_price_per_piece) ||
                    'NONE'}
                </Styles.StyledTD>
              );
            case 'TOTAL RETAIL':
              return (
                <Styles.StyledTD key={`TD-${field}-${result.pallet_sku}`}>
                  {formatter.format(result.sum_retail_price) || 'NONE'}
                </Styles.StyledTD>
              );
            case 'ACTIONS':
              return (
                <ActionsContainer key={`TD-${field}-${result.pallet_sku}`}>
                  <Styles.TableButton
                    onClick={() => {
                      setCurrentSku(result.pallet_sku);
                      getPalletStats(result.pallet_sku).then((palletStats) => {
                        getProducts(result.pallet_sku).then((products) => {
                          setCurrentPalletStats(palletStats);
                          setCurrentProducts(
                            getPalletProductQuantities(products)
                          );
                          pdf(
                            <PalletPdf
                              palletItems={getPalletProductQuantities(products)}
                              palletStats={palletStats}
                              barcode={result.pallet_sku}
                              retailPercent={getRetailPercent(result.pallet_sku)}
                            />
                          ).toBlob().then((result:any) => {
                            setPdfBlob(result);
                            setShowPrintPreview(true);
                          });
                        });
                      });
                    }}
                    style={{
                      padding: '10px 15px 2px 0px',
                      backgroundColor: '#FFF0',
                      margin: 0,
                      alignContent: 'center',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPrint}
                      style={{ width: '24px', height: '24px', marginBottom: 0 }}
                      color={Colors.greenBlue}
                    />
                    <p
                      style={{
                        fontSize: 12,
                        marginTop: 0,
                        fontFamily: `Open Sans, sans-serif`,
                      }}
                    >
                      Print
                    </p>
                  </Styles.TableButton>

                  <Styles.TableButton
                    onClick={(_) => {
                      setShowForm(true);
                      setIsEdit(true);
                      setEditedInfo(result);
                    }}
                    style={{
                      padding: '10px 15px 2px 0px',
                      backgroundColor: '#FFF0',
                      margin: 0,
                      alignContent: 'center',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faEdit}
                      style={{ width: '24px', height: '24px', marginBottom: 0 }}
                      color={Colors.gray}
                    />
                    <p
                      style={{
                        fontSize: 12,
                        marginTop: 0,
                        fontFamily: `Open Sans, sans-serif`,
                      }}
                    >
                      Edit
                    </p>
                  </Styles.TableButton>

                  <Styles.TableButton
                    style={{
                      padding: '10px 15px 2px 0px',
                      backgroundColor: '#FFF0',
                      margin: 0,
                    }}
                    onClick={(_) => {
                      setShowModify(true);
                      setCurrentSku(result.pallet_sku);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faList}
                      style={{
                        width: '24px',
                        height: '24px',
                        marginBottom: 0,
                      }}
                      color={Colors.button}
                    />
                    <p
                      style={{
                        fontSize: 12,
                        marginTop: 0,
                        fontFamily: `Open Sans, sans-serif`,
                        textAlign: 'center',
                      }}
                    >
                      Products
                    </p>
                  </Styles.TableButton>

                  <Styles.TableButton
                    onClick={(_) => {
                      if (
                        window.confirm(
                          'Are you sure you want to delete this pallet? Once deleted, it cannot be recovered.'
                        )
                      ) {
                        deletePallet(result.pallet_sku, userContext.auth).then(
                          (_) => {
                            getPallets();
                          }
                        );
                      }
                    }}
                    style={{
                      padding: '10px 15px 2px 0px',
                      backgroundColor: '#FFF0',
                      margin: 0,
                      alignContent: 'center',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      style={{ width: '24px', height: '24px', marginBottom: 0 }}
                      color={Colors.red}
                    />
                    <p
                      style={{
                        fontSize: 12,
                        marginTop: 0,
                        fontFamily: `Open Sans, sans-serif`,
                      }}
                    >
                      Delete
                    </p>
                  </Styles.TableButton>
                </ActionsContainer>
              );
            default:
              return <td key={`TD-${field}-${result.pallet_sku}`} />;
          }
        })}
      </tr>
    ));
    return items;
  };

  return (
    <>
      <Styles.Page
        style={{
          display: showModify ? 'None' : '',
          maxWidth: PAGE_WIDTH,
          maxHeight: PAGE_HEIGHT,
        }}
      >
        <Styles.PageHeader style={{ alignSelf: 'flex-start' }}>
          Production Pallets
        </Styles.PageHeader>
        <MuiButton
          variant="contained"
          color="success"
          style={{
            alignSelf: 'flex-start',
            margin: '4vh 0 1.5vh',
          }}
          onClick={() => {
            setShowForm(true);
          }}
        >
          <FontAwesomeIcon
            icon={faPlus}
            color={Colors.white}
            style={{ marginRight: 10 }}
          />
          Create New Pallet
        </MuiButton>
        <Styles.FlexDiv
          style={{
            flex: '5 1 auto',
            justifyContent: 'center',
            alignSelf: 'flex-start',
            maxHeight: '130px',
          }}
        >
          <Styles.FlexDiv style={{ flexDirection: 'column' }}>
            <Styles.Text style={{ marginBottom: 0 }}>From</Styles.Text>
            <DatePicker
              value={startDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: 175, marginRight: '10px' }}
                />
              )}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
            />
          </Styles.FlexDiv>
          <Styles.FlexDiv style={{ flexDirection: 'column' }}>
            <Styles.Text style={{ marginBottom: 0 }}>To</Styles.Text>
            <DatePicker
              value={endDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: 175, marginRight: '10px' }}
                />
              )}
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
            />
          </Styles.FlexDiv>
          <Styles.FlexDiv style={{ flexDirection: 'column' }}>
            <Styles.Text style={{ marginBottom: 0 }}>Status</Styles.Text>
            <Autocomplete
              className=""
              disablePortal
              id="status"
              options={statusOptions}
              renderInput={(params) => <TextField {...params} />}
              defaultValue={'All'}
              inputValue={status}
              onChange={(event, newValue) => {
                setStatus(newValue);
              }}
              disableClearable
              sx={{ width: 150 }}
            />
          </Styles.FlexDiv>
        </Styles.FlexDiv>

        <Box
          sx={{ ml: 'auto', mb: 2, display: results.length > 0 ? '' : 'none' }}
        >
          <div style={{ display: 'inline', marginRight: 15 }}>
            {userContext.isAdmin && (
            <ExportToShopify
              items={results.map((result: any) => ({
                productId: result.pallet_sku,
                productName: getCategoryFromSku(result.pallet_sku),
                productSku: result.pallet_sku,
                productPrice: result.sum_retail_price,
                RetailPercent: getRetailPercent(result.pallet_sku),
                productPieces: result.total_pieces,
                isPallet: true,
              }))}
              forceSync={true}
              ProductType = {'p'}
            />
            )}
          </div>
          <div style={{ display: 'inline' }}>
            <ExportToShopify
              items={results.map((result: any) => ({
                productId: result.pallet_sku,
                productName: getCategoryFromSku(result.pallet_sku),
                productSku: result.pallet_sku,
                productPrice: result.sum_retail_price,
                RetailPercent: getRetailPercent(result.pallet_sku),
                productPieces: result.total_pieces,
                isPallet: true,
              }))}
              forceSync={false}
              ProductType={'p'}
            />
          </div>
        </Box>
        <MainTableContainer
          pageWidth={PAGE_WIDTH}
          pageHeight={PAGE_HEIGHT}
          style={{
            maxWidth: userContext.isMobile ? PAGE_WIDTH : '90vw',
            minWidth: userContext.isMobile ? PAGE_WIDTH : '90vw',
            overflowX: userContext.isMobile ? 'hidden' : 'scroll',
            padding: userContext.isMobile ? 0 : '30px',
          }}
        >
          <table
            style={{
              borderCollapse: 'collapse',
              tableLayout: userContext.isMobile ? 'fixed' : 'auto',
              width: userContext.isMobile ? window.innerWidth : '88vw',
              alignSelf: 'center',
            }}
          >
            <thead>
              <tr>
                {PALLETS_FIELDS.map((field: any, index) => {
                  return (
                    <Styles.StyledTH
                      key={`TH-${PALLETS_FIELDS.indexOf(field)}-${index}`}
                      style={{
                        width: userContext.isMobile ? '35%' : '',
                        textAlign: 'match-parent',
                      }}
                    >
                      {field}
                    </Styles.StyledTH>
                  );
                })}
              </tr>
            </thead>
            <tbody>{listResults()}</tbody>
          </table>
        </MainTableContainer>

        {showForm && !isEdit && (
          <NewPalletPopup pageWidth={PAGE_WIDTH} pageHeight={PAGE_HEIGHT}>
            <AddPalletForm
              setShowForm={setShowForm}
              getPallets={getPallets}
              showForm={showForm}
              SkuMap={skuMap}
              PalletCategoryMap={PalletCategoryMap}
              PalletLotCategoryMap={PalletLotCategoryMap}
              PalletCategories={palletCategories}
              PalletLotCategories={palletLotCategories}
            />
          </NewPalletPopup>
        )}
        {showForm && isEdit && (
          <NewPalletPopup pageWidth={PAGE_WIDTH} pageHeight={PAGE_HEIGHT}>
            <EditPalletForm
              setShowForm={setShowForm}
              showForm={showForm}
              palletInfo={editedInfo}
              setIsEdit={setIsEdit}
              getPallets={() => setRefresh(!refresh)}
            />
          </NewPalletPopup>
        )}
        <div
          onClick={() => {
            setShowForm(false);
            setIsEdit(false);
          }}
          style={{
            display: showForm ? '' : 'none',
            position: 'absolute',
            left: 0,
            top: window.innerHeight - PAGE_HEIGHT,
            width: PAGE_WIDTH,
            height: PAGE_HEIGHT,
            backgroundColor: '#000C',
            zIndex: 50,
          }}
        />
      </Styles.Page>
      {showModify && (
        <Styles.Page
          style={{
            maxWidth: PAGE_WIDTH,
            maxHeight: PAGE_HEIGHT,
            boxSizing: 'border-box',
            overflowY: 'auto',
            overflowX: 'hidden',
            margin: 0,
            paddingLeft: PAGE_WIDTH * 0.05,
            paddingRight: PAGE_WIDTH * 0.05,
          }}
        >
          <ModifyProducts
            palletSku={currentSku}
            setShowModify={setShowModify}
            readOnlyView={view}
            setReadOnlyView={setView}
            retailPercent={getRetailPercent(currentSku)}
          />
        </Styles.Page>
      )}
      <PrintModal
        previewComponent={
          <PalletPdf
            palletStats={currentPalletStats}
            palletItems={currentProducts}
            barcode={currentSku}
            retailPercent={getRetailPercent(currentSku)}
          />
        }
        onCancelClick={() => onCancelClick()}
        dimensions={[500, 520]}
        open={showPrintPreview}
        pdfBlob={pdfBlob}
        pdfType={'Pallet'}
        setShowPrintPreview={(value: boolean) => setShowPrintPreview(value)}
      />
    </>
  );
};
export default Pallets;
