import {
  faCheck,
  faPencilAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DB_URL, ES_INDEX } from 'env';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import Styles, { MuiButton } from 'Styles';
import Log from './Logger';
import {
  ROLES,
  SUPPORTED_RECORD_FIELDS,
  UserContext,
} from '../utilities/DataTypes';
import styled from 'styled-components';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

const StyledButton = styled.button`
  background-color: #0000;
  margin-left: 1px;
  cursor: pointer;
  outline-width: 0;
  border: none;
  padding: 4px 6px 4px 6px;
`;

interface RecordProps {
  setViewRecord: any;
  viewRecord: any;
  id: string;
  refresh: any;
}

const Record = (props: RecordProps) => {
  const [data, setData]: [any, Dispatch<SetStateAction<any>>] = useState({
    id: '',
  });
  const [loading, setLoading] = useState(false);
  const userContext = useContext(UserContext);

  if (props.id !== data.id && !loading) {
    setLoading(true);
    fetch(DB_URL + ES_INDEX + '/_source/' + props.id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: userContext.auth,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((error) => {
        Log(2, 'Get record exception: ' + error);
      });
  }
  if (data.id !== 0 && props.id === data.id) {
    return (
      <Dialog
        maxWidth={'lg'}
        fullWidth
        onClose={() => props.setViewRecord(false)}
        open={props.viewRecord}
      >
        <DialogTitle>Record Details</DialogTitle>
        <DialogContent>
          {SUPPORTED_RECORD_FIELDS.map((item: any) => (
            <IndividualRecord
              isAdmin={userContext.isAdmin}
              isMobile={userContext.isMobile}
              key={SUPPORTED_RECORD_FIELDS.indexOf(item)}
              id={props.id}
              field={item}
              data={data}
              auth={userContext.auth}
            />
          ))}
        </DialogContent>
        <DialogActions
          sx={{
            m: 1
          }}
        >
          <MuiButton
            variant="contained"
            onClick={() => props.setViewRecord(false)}
          >
            Close
          </MuiButton>
        </DialogActions>
      </Dialog>
    );
  } else {
    return <div />;
  }
};

interface IndividualRecordProps {
  id: any;
  data: any;
  field: any;
  auth: any;
  isMobile: boolean;
  isAdmin: boolean;
}

const updateRecord = (id: string, field: any, value: any, auth: any) => {
  fetch(DB_URL + ES_INDEX + '/_update/' + id, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
    },
    body: JSON.stringify({
      doc: {
        [field]: value,
      },
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      Log(0, 'updateRecord response: ' + response);
    })
    .catch((error) => {
      Log(2, 'updateRecord error: ' + error);
    });
};

const IndividualRecord = (props: IndividualRecordProps) => {
  const [inputValue, setInputValue] = useState(props.data[props.field] || '');
  const [editing, setEditing] = useState(false);
  const userContext = useContext(UserContext);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        borderBottom: 'thin solid black',
      }}
    >
      <Styles.Text
        style={{
          display: userContext.isMobile && editing ? 'none' : 'flex',
          flex: '1',
          marginRight: '5px',
          minWidth: userContext.isMobile ? '50vw' : '10vw',
          flexWrap: 'nowrap',
          whiteSpace: 'nowrap',
        }}
      >
        {userContext.userRole !== ROLES.RGT &&
          userContext.userRole !== ROLES.RGT_REPORTING && (
            <StyledButton onClick={() => setEditing(true)}>
              <FontAwesomeIcon icon={faPencilAlt} />
            </StyledButton>
          )}
        {props.field.charAt(0).toUpperCase() +
          props.field
            .replace('LOAD_NUMBER', 'LOAD_NAME')
            .slice(1)
            .replaceAll('_', ' ')}
        :
      </Styles.Text>

      {(props.field === 'MANIFEST_URL' || props.field === 'AMAZON_URL') && (
        <Styles.Text
          style={{
            wordBreak: 'break-word',
            flexWrap: 'wrap',
            flex: '2',
            minWidth: userContext.isMobile ? '40vw' : '25vw',
            display: editing ? 'none' : 'flex',
          }}
        >
          <a href={inputValue} target="blank">
            {inputValue}
          </a>
        </Styles.Text>
      )}
      {!(props.field === 'MANIFEST_URL' || props.field === 'AMAZON_URL') && (
        <Styles.Text
          style={{
            wordBreak: 'break-word',
            flexWrap: 'wrap',
            flex: '2',
            display: editing ? 'none' : 'flex',
          }}
        >
          {inputValue}
        </Styles.Text>
      )}

      <TextField
        style={{
          fontSize: '16px',
          fontFamily: 'Open Sans',
          display: editing ? 'flex' : 'none',
          flex: '2 0',
        }}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />

      <Styles.StyledButton
        onClick={() => {
          updateRecord(props.id, props.field, inputValue, userContext.auth);
          setEditing(false);
        }}
        style={{
          backgroundColor: '#FFF0',
          color: 'black',
          display: editing ? 'flex' : 'none',
          padding: 5,
          flex: '0',
        }}
      >
        <FontAwesomeIcon icon={faCheck} />
      </Styles.StyledButton>
      <Styles.StyledButton
        onClick={() => {
          setEditing(false);
          setInputValue(props.data[props.field]);
        }}
        style={{
          backgroundColor: '#FFF0',
          color: 'black',
          display: editing ? 'flex' : 'none',
          padding: 5,
          flex: '0',
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </Styles.StyledButton>
    </Box>
  );
};

export default Record;
