import React from 'react';
import logo from '../assets/logo.png';
import { formatter } from '../utilities/Helpers';
import { PalletItemRow, PalletStats, PalletDefaultRetailPercent, PalletLotDefaultRetailPercent, QrCodeUrl } from '../utilities/DataTypes';
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';

Font.register(
  {
  family: 'Libre Barcode 39',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/librebarcode39/v19/-nFnOHM08vwC6h8Li1eQnP_AHzI2K_d709jy92k.ttf',
    }
  ],
});

Font.register(
  {
  family: 'Roboto Mono 700',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/robotomono/v21/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_Of2PQ--5Ip2sSQ.ttf',
    }
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFF',
    margin: '0pt',
  },
  logo: {
    color: 'black',
    width: '18vw',
    height: '6vw',
  },
  text: {
    fontFamily: 'Open Sans',
  },
  statText: {
    color: '#535353',
    fontSize: 12,
    marginTop: '2pt',
    marginBottom: '2pt',
  },
  noWrapText: {
    width: '250pt',
  },
  gridText: {
    fontSize: 10,
  },
  price: {
    fontFamily: 'Open Sans',
    verticalAlign: 'super',
    top: 0,
    textAlign: 'center',
    justifyContent: 'center',
    alignSelf: 'center'
  },
  itemRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    borderBottom: '1px solid gray',
    padding: '10pt 15pt',
    marginTop: '2pt',
  },
  footer: {
    marginTop: '3pt',
    paddingTop: 2,
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
      alignContent: 'space-between',
    marginBottom: '8pt',
  },
  barcode: {
    fontFamily: 'Libre Barcode 39',
    fontSize: '30px',
    marginBottom: '0px',
    padding: '0px',
  },
    qrCode: {
      width: '80px',
        marginRight: '20px'
    }
});

interface PalletPdfProps {
  palletStats: PalletStats;
  palletItems: PalletItemRow[];
  barcode: string;
  retailPercent: number|null|undefined;
}

// Create Document Component
const PalletPdf = (props: PalletPdfProps) => {
  return (
    <Document>
      <Page size="LETTER" orientation="portrait" style={styles.page}>
        <View
          style={{
            display: 'flex',
            flexGrow: 0,
            flexDirection: 'column',
            alignItems: 'center',
            padding: 30,
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 10,
              width: '100%',
              top: 0,
            }}
          >
            <Image style={styles.logo} src={logo} />
            <View
              style={{ display: 'flex',
              alignItems: 'flex-start',
              textAlign: 'center',
              justifyContent: 'center',
              alignSelf: 'center',
              alignContent: 'center',
              width: '50%'
            }}
            >
              <Text style={[styles.price, {marginTop: -5, fontSize: 16}]}>
                Precio / Price:{' '}
              </Text>
              <Text
                  style={[styles.price, { fontWeight: 'bold', fontSize: 28, marginTop: -5 }]}
                >
                  {formatter.format(
                    (props.palletStats?.total_retail || 0) * (props.retailPercent ?? PalletDefaultRetailPercent)
                  )}
                </Text>
            </View>
            <Text style={[styles.text, {fontSize: 15, color: 'gray'}]}> {new Date().toLocaleDateString('en-US')} </Text>
          </View>
          
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 1,
              width: '100%',
              borderTop: '1pt solid gray',
              borderBottom: '1pt solid gray',
            }}
          >
            <View
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'flex-start',
                flexDirection: 'column',
                minWidth: '30%',
              }}
            >
              <Text style={[styles.text, styles.statText, styles.noWrapText]}>
                <Text style={{ fontWeight: 'bold' }}>Precio de Tienda: </Text>{' '}
                {formatter.format(props.palletStats?.total_retail || 0)}
              </Text>
              <Text style={[styles.text, styles.statText, styles.noWrapText]}>
                <Text style={{ fontWeight: 'bold' }}>Productos Totales: </Text>{' '}
                {props.palletStats?.total_products.toLocaleString()}
              </Text>
              <Text style={[styles.text, styles.statText]}>
                <Text style={{ fontWeight: 'bold' }}>
                  Precio Promedio de Tienda por Pieza:
                </Text>
                {' '}
                {formatter.format(
                  props.palletStats?.avg_retail_price_per_piece || 0
                )}
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'flex-end',
                flexDirection: 'column',
              }}
            >
              <Text
                style={[
                  styles.text,
                  styles.statText,
                  styles.noWrapText,
                  { textAlign: 'right' },
                ]}
              >
                <Text style={{ fontWeight: 'bold' }}>Total Retail Value: </Text>{' '}
                {formatter.format(props.palletStats?.total_retail || 0)}
              </Text>
              <Text
                style={[
                  styles.text,
                  styles.statText,
                  styles.noWrapText,
                  { textAlign: 'right' },
                ]}
              >
                <Text style={{ fontWeight: 'bold' }}>Total Products: </Text>{' '}
                {props.palletStats?.total_products.toLocaleString()}
              </Text>
              <Text
                style={[styles.text, styles.statText, { textAlign: 'right' }]}
              >
                <Text style={{ fontWeight: 'bold' }}>
                  AVG Retail Per Piece:{' '}
                </Text>{' '}
                {formatter.format(
                  props.palletStats?.avg_retail_price_per_piece || 0
                )}
              </Text>
            </View>
          </View>
          {props.barcode !== undefined && (
            <View style={styles.footer}>
                <Image style={styles.qrCode} src={QrCodeUrl + props.barcode} />
                <Text style={{fontFamily:'Roboto Mono 700' , fontSize:'15px'}}>{props.barcode}</Text>
            </View>
          )}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              width: '100%',
              alignItems: 'flex-start',
            }}
          >
            <View
              style={[
                styles.itemRow,
                { backgroundColor: 'rgb(245, 245, 250)' },
              ]}
            >
              <Text
                style={[styles.text, styles.gridText, { fontWeight: 'bold', color: '#6A6A6A' }]}
              >
                TÍTULO / TITLE
              </Text>
              <Text
                style={[styles.text, styles.gridText, { fontWeight: 'bold', color: '#6A6A6A' }]}
              >
                VALOR DE VENTA / RETAIL PRICE
              </Text>
            </View>
            
            {props.palletItems.map((item, index) => (
              <View style={styles.itemRow} key={index}>
                <View
                  style={{ display: 'flex', flexDirection: 'row', flex: '1' }}
                >
                  <Text
                    style={[
                      styles.text,
                      styles.gridText,
                      {
                        display: 'flex',
                        width: 40,
                      },
                    ]}
                  >
                    {item.palletProductQuantity}x{' '}
                  </Text>
                  <Text
                    style={[
                      styles.text,
                      styles.gridText,
                      {
                        display: 'flex',
                        flex: '1',
                        maxLines: 1,
                        textOverflow: 'ellipsis',
                      },
                    ]}
                  >
                    {item.palletProductTitle}
                  </Text>
                </View>
                <Text
                  style={[
                    styles.text,
                    styles.gridText,
                    {
                      display: 'flex',
                      marginLeft: 10,
                    },
                  ]}
                >
                  {formatter.format(item.palletProductPrice * item.palletProductQuantity)}
                </Text>
              </View>
            ))}
          </View>
          
        </View>
        
      </Page>
    </Document>
  );
};

export default PalletPdf;