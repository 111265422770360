import React, { useContext, useEffect, useState } from 'react';
import Styles, { Colors } from 'Styles';
import styled from 'styled-components';
import { API_URL } from '../env';
import Log from './Logger';
import { UserContext } from '../utilities/DataTypes';
import { useSnackbar } from 'notistack';
import { formatter } from '../utilities/Helpers';

// Styles

const DetailsPageStyle = styled(Styles.Page)`
  flex-shrink: 1;
  max-height: 90px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const DetailItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
`;

const DetailItem = styled.div`
  min-height: 45px;
  max-height: 45px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

const DetailItemLabel = styled.p`
  font-size: 20px;
  font-family: 'Open Sans', sans-serif;
  color: ${Colors.darkGray};
  font-weight: bold;
  display: inline-block;
  padding: 0;
  margin: 0 10px 0 0;
`;

const DetailItemValue = styled.p`
  font-size: 20px;
  font-family: 'Open Sans', sans-serif;
  color: ${Colors.darkGray};
  display: inline-block;
  margin: 0;
  padding: 0;
`;

// Smart Components

type PalletStats = {
  avg_cost_per_unit: number;
  total_price: number;
  total_products: number;
  avg_percent_of_retail: number;
  total_retail: number;
};

const getPalletStats = (
  palletSku: string,
  auth: string
): Promise<PalletStats> => {
  return fetch(API_URL + 'getPalletProductStats', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
    },
    body: JSON.stringify({
      pallet_sku: palletSku,
    }),
  })
    .then((response) => response.json())
    .then((response: PalletStats) => {
      return response;
    })
    .catch((err) => {
      Log(2, err);
      throw err;
    });
};

interface PalletInfoDetailsProps {
  palletSku: string;
  reloadProducts: boolean;
}

const PalletInfoDetails = (props: PalletInfoDetailsProps) => {
  const [palletStats, setPalletStats]: [PalletStats | undefined, any] =
    useState();
  const userContext = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  // On SKU set:
  useEffect(() => {
    if (props.palletSku.length > 0) {
      getPalletStats(props.palletSku, userContext.auth)
        .then((response) => {
          setPalletStats(response);
        })
        .catch((_) => {
          enqueueSnackbar(
            'Failed to load pallet info. Please check your internet connection and try again.',
            { variant: 'error' }
          );
        });
    } else {
      enqueueSnackbar('Missing Pallet SKU. Refresh the page to try again.', {
        variant: 'error',
      });
    }
  }, [
    userContext.auth,
    props.palletSku,
    enqueueSnackbar,
    props.reloadProducts,
  ]);

  return (
    <DetailsPageStyle>
      <DetailItemsContainer
        style={{ borderRight: `thin solid ${Colors.gray}` }}
      >
        <DetailItem style={{ borderBottom: `thin solid ${Colors.gray}` }}>
          <DetailItemLabel>Retail Value:</DetailItemLabel>
          <DetailItemValue>
            {formatter.format(palletStats?.total_retail || 0)}
          </DetailItemValue>
        </DetailItem>
        <DetailItem>
          <DetailItemLabel>Total Products:</DetailItemLabel>
          <DetailItemValue>
            {palletStats?.total_products.toLocaleString()}
          </DetailItemValue>
        </DetailItem>
      </DetailItemsContainer>
      <DetailItemsContainer
        style={{ borderRight: `thin solid ${Colors.gray}` }}
      >
        <DetailItem style={{ borderBottom: `thin solid ${Colors.gray}` }}>
          <DetailItemLabel>Total Cost:</DetailItemLabel>
          <DetailItemValue>
            {formatter.format(palletStats?.total_price || 0)}
          </DetailItemValue>
        </DetailItem>
        <DetailItem>
          <DetailItemLabel>AVG Cost:</DetailItemLabel>
          <DetailItemValue>
            {formatter.format(palletStats?.avg_cost_per_unit || 0)}
          </DetailItemValue>
        </DetailItem>
      </DetailItemsContainer>
      <DetailItemsContainer>
        <DetailItem style={{ borderBottom: `thin solid ${Colors.gray}` }}>
          <DetailItemLabel>Percent of Retail:</DetailItemLabel>
          <DetailItemValue>
            {palletStats?.avg_percent_of_retail.toLocaleString()}%
          </DetailItemValue>
        </DetailItem>
        <DetailItem />
      </DetailItemsContainer>
    </DetailsPageStyle>
  );
};

export default PalletInfoDetails;
