import React, {useEffect, useState} from 'react';
import {useSnackbar} from 'notistack';
import styled from 'styled-components';
import Styles, {MuiButton} from '../Styles';
import Log from 'components/Logger';
import {
    PAGE_HEIGHT,
    PAGE_WIDTH,
    SizeProps
} from '../utilities/DataTypes';
import {
    Tabs,
    Tab,
    Typography,
    Box,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { 
    getIndividualProductCategories,
    addIndividualProductCategory,
    updateIndividualProductCategory,
    deleteIndividualProductCategory,
    getPalletCategories,
    addPalletCategory,
    updatePalletCategory,
    deletePalletCategory,
    getPalletLotCategories,
    addPalletLotCategory,
    updatePalletLotCategory,
    deletePalletLotCategory,
  }  from '../components/Categories/CategoriesAPICalls'

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const FormRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin-bottom: 10px;
  font-family: Open Sans, sans-serif;
`;
const MainTableContainer = styled.div<SizeProps>`
  display: flex;
  flex-direction: column;
  flex: 20 1 0;
  min-height: 0;
  max-height: ${(props) => props.pageHeight}px;
  overflow: scroll;
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: 0 0 5px #0003;
`;
const TableContainer = styled.div`
    overflow:hidden;
    overflow-y: scroll;
    height:20%;
`;

const Categories = () => {    
    type CategoryType = 'Individual Product' | 'Pallet' | 'Pallet Lot' | '';
    type ActionType = 'Add' | 'Edit' | '';
    const [indivProductCategories, setIndivProductCategories] = useState([]);
    const [palletCategories, setPalletCategories] = useState([]);
    const [palletLotCategories, setPalletLotCategories] = useState([]);
    const [tab, setTab] = useState(0);
    const [showForm, setShowForm] = useState(false);
    const [selectCategory, setSelectCategory] = useState({
        id: 0,
        sku: '',
        category: '',
        "retail_percentage": 0,
    });
    const [actionType, setActionType]:[ActionType, any] = useState('');
    const [categoryType, setCategoryType]:[CategoryType, any]= useState('');
    const [refresh, setRefresh] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const Fields = [
        'SKU',
        'Category',
        'Retail Percentage',
        'Actions'
    ]

    // currently used to initialize the state for IndividualProductCategories, PalletCategories, and PalletLotCategories
    useEffect(()=>{
        getIndividualProductCategories().then((IndividualProductCategories: any) => {
            setIndivProductCategories(IndividualProductCategories);
        });
        getPalletCategories().then((PalletCategories:any) => {
            setPalletCategories(PalletCategories);
        })
        getPalletLotCategories().then((PalletLotCategories: any) => {
            setPalletLotCategories(PalletLotCategories);
        })
    }, []);

    // print variables that set tabs and action types
    useEffect(()=>{
        Log(0, actionType);
        Log(0, categoryType);
        Log(0, selectCategory);
    }, 
    [actionType, categoryType, selectCategory]);

    // refresh state if needed
    useEffect(()=>{
        getIndividualProductCategories().then((IndividualProductCategories: any) => {
            setIndivProductCategories(IndividualProductCategories);
        });
        getPalletCategories().then((PalletCategories:any) => {
            setPalletCategories(PalletCategories);
        });
        getPalletLotCategories().then((PalletLotCategories: any) => {
            setPalletLotCategories(PalletLotCategories);
        });
    },
    [refresh])

   

    const generateTable = (categories:any, CategoryType:CategoryType) => {
    return (
    <Styles.CategoriesPage>
        <MuiButton
        variant="outlined"
        color="secondary"
        onClick={()=>{
        setActionType('Add');
        setCategoryType(CategoryType);
        setSelectCategory({id:0, sku:'', category:'', "retail_percentage": 0})
        setShowForm(true);
        }}
        >
        Add Category
        </MuiButton>
        <Styles.FlexDiv
                    style={{
                    display: 'flex',
                    marginTop: '20px',
                    minHeight: '15vh',
                    maxHeight: '80vh',
                    marginBottom: '20px',
                    paddingBottom: '80px',
                    }}
                >
                
                    <MainTableContainer
                    pageWidth={PAGE_WIDTH}
                    pageHeight={PAGE_HEIGHT}
                    style={{
                      overflowX:'scroll',
                      padding: '30px',
                      paddingTop:'10px',

                    }}
                    >
                    <table
                        style={{
                            borderCollapse: 'collapse',
                            tableLayout: 'auto',
                            width: '60vw',
                        }}
                    >
                    <thead>
                        <tr>
                        {Fields.map((field: string) => {
                            return(
                                <Styles.StyledTH
                                    key={Fields.indexOf(field)}
                                    style={{
                                        textAlign: 'match-parent'
                                    }}
                                >
                                    {field}
                                </Styles.StyledTH>
                            )
                        })}
                        </tr>
                    </thead>
                    <tbody>
                        {listResults(categories, CategoryType)}
                    </tbody>
                </table>
                    </MainTableContainer>
                </Styles.FlexDiv>
            </Styles.CategoriesPage>
        )
    }

    const listResults = (categories: any, CategoryType: CategoryType) => {
        return categories.map( (category:any) => 
            <tr
            key={'TR-'+category.id}
            style={{
                borderBottom: 'thin solid black',
            }}
            >
                {Fields.map((field:any) => {
                    switch (field) {
                        case 'SKU':
                            return(
                                <Styles.StyledTD key={Fields.indexOf(field)}>
                                    {category.sku}
                                </Styles.StyledTD>
                            )
                        case 'Category':
                            return(
                                <Styles.StyledTD key={Fields.indexOf(field)}>
                                    {category.category}
                                </Styles.StyledTD>
                            )
                        case 'Retail Percentage':
                            return(
                                <Styles.StyledTD key={Fields.indexOf(field)}>
                                    {category['retail_percentage']}
                                </Styles.StyledTD>
                            )
                        default:
                            return <></>
                    }
                })}
                <ActionsContainer>
                    <MuiButton
                    variant="contained"
                    onClick = {()=> {
                        setActionType('Edit');
                        setCategoryType(CategoryType);
                        setSelectCategory({...category});
                        setShowForm(true);
                    }}
                    >
                        Edit
                    </MuiButton>
                    <MuiButton
                        variant="contained"
                        color="error"
                        onClick = {async ()=>{
                            if(CategoryType.toString() === 'Individual Product'){
                                await deleteIndividualProductCategory(category.id).then((success:boolean) => {
                                    if (success) {
                                        enqueueSnackbar('Succesfully deleted category.', {variant: 'success'});
                                    }
                                    else {
                                        enqueueSnackbar('Error deleting category', {variant: 'error'});
                                    }
                                });
                                setRefresh(!refresh);
                            }
                            if(CategoryType === 'Pallet'){
                                await deletePalletCategory(category.id).then((success:boolean) => {
                                    if (success) {
                                        enqueueSnackbar('Succesfully deleted category.', {variant: 'success'});
                                    }
                                    else {
                                        enqueueSnackbar('Error deleting category', {variant: 'error'});
                                    }
                                });
                                setRefresh(!refresh);
                            }
                            if(CategoryType === 'Pallet Lot'){
                                await deletePalletLotCategory(category.id).then((success:boolean) => {
                                    if (success) {
                                        enqueueSnackbar('Succesfully deleted category.', {variant: 'success'});
                                    }
                                    else {
                                        enqueueSnackbar('Error deleting category', {variant: 'error'});
                                    }
                                });
                                setRefresh(!refresh);
                            }
                        }}
                    >
                        Delete
                    </MuiButton>
                </ActionsContainer>
            </tr>
        )
    }

    const IndividualProductTab = () => {
        return generateTable(indivProductCategories, 'Individual Product');
    }

    const PalletTab = () => {
        return generateTable(palletCategories, 'Pallet');
    }
    const PalletLotTab = () => {
        return generateTable(palletLotCategories, 'Pallet Lot');
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
      };
      
      interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
      }
      
      function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }

    const CategoryForm = () => {
        let tempCategory = {
            id: selectCategory.id ?? 0,
            sku: selectCategory.sku ?? '',
            category: selectCategory.category ?? '',
            "retail_percentage": selectCategory["retail_percentage"]
              ? parseFloat(selectCategory["retail_percentage"] as unknown as string)
              : undefined,
        };

        let title = `${actionType.toString()} ${categoryType.toString()} Category`;
        return (
            <Dialog
            open={showForm}
            maxWidth={'sm'}
            fullWidth
            >
                <DialogTitle> {title}</DialogTitle>
                <DialogContent>
                    <FormRow>
                        <label>SKU</label>
                        <TextField 
                        defaultValue = {selectCategory.sku}
                        onChange={(e: any)=>
                        tempCategory.sku = e.target.value}
                        />
                    </FormRow>
                    <FormRow>
                        <label>Category</label>
                        <TextField 
                        defaultValue = {tempCategory.category}
                        onChange={(e:any)=> tempCategory.category = e.target.value}
                        />
                    </FormRow>
                    <FormRow>
                        <label>Retail Percentage</label>
                        <TextField
                        defaultValue = {tempCategory.retail_percentage}
                        onChange={(e:any)=>tempCategory["retail_percentage"] = e.target.value}
                        />
                    </FormRow>
                </DialogContent>
                <DialogActions>
                    <MuiButton
                        variant="contained"
                        color="info"
                        onClick={()=>setShowForm(false)}
                    >
                        Cancel
                    </MuiButton>
                    <MuiButton
                        variant="contained"
                        onClick={()=>{
                            if (!tempCategory['retail_percentage']){
                                delete tempCategory['retail_percentage']
                            } else {
                                tempCategory['retail_percentage'] = parseFloat(tempCategory["retail_percentage"] as unknown as string);
                            }

                            if (categoryType.toString() === 'Individual Product'){
                                if (actionType.toString() === 'Add'){
                                    addIndividualProductCategory({sku: tempCategory.sku, category: tempCategory.category, "retail_percentage": tempCategory['retail_percentage']})
                                    .then((success) => {
                                        if (success){
                                        enqueueSnackbar(
                                            'Succesfully added individual product category.',
                                            {variant : 'success'}
                                        )
                                        setRefresh(!refresh)
                                    }
                                        else{
                                            enqueueSnackbar('Duplicate individual product SKU not allowed.',
                                            {variant: 'error'});
                                        }
                                    })
                                    .catch((error)=>{
                                        Log(2, error);
                                        enqueueSnackbar('Error adding individual product category.',
                                        {variant: 'error'});
                                    });
                                }
                                if (actionType.toString() === 'Edit'){
                                    updateIndividualProductCategory({id: tempCategory.id, sku:tempCategory.sku, category:tempCategory.category, "retail_percentage": tempCategory['retail_percentage']})
                                    .then((success)=> {
                                        if(success)
                                        enqueueSnackbar('Succesfully updated individual product category.', {variant: 'success'});
                                        else
                                        enqueueSnackbar('Duplicate individual SKU not allowed.',
                                            {variant: 'error'});

                                        setRefresh(!refresh)
                                    })
                                    .catch((error)=>{
                                        Log(2, error);
                                    });
                                }
                            }
                            if (categoryType.toString() === 'Pallet'){
                                if (actionType.toString() === 'Add'){
                                    addPalletCategory({sku: tempCategory.sku, category: tempCategory.category, "retail_percentage": tempCategory['retail_percentage']})
                                    .then((success) => {
                                        if (success){
                                        enqueueSnackbar(
                                            'Succesfully added pallet category.',
                                            {variant : 'success'}
                                        )
                                        setRefresh(!refresh)
                                    }
                                        else{
                                            enqueueSnackbar('Duplicate pallet SKU not allowed.',
                                            {variant: 'error'});
                                        }
                                    })
                                    .catch((error)=>{
                                        Log(2, error);
                                        enqueueSnackbar('Error adding pallet category.',
                                        {variant: 'error'});
                                    });
                                }
                                if (actionType.toString() === 'Edit'){
                                    updatePalletCategory({id: tempCategory.id, sku:tempCategory.sku, category:tempCategory.category, "retail_percentage": tempCategory['retail_percentage']})
                                    .then((success)=> {
                                        if(success)
                                        enqueueSnackbar('Succesfully updated pallet category.', {variant: 'success'});
                                        else
                                        enqueueSnackbar('Duplicate pallet SKU not allowed.',
                                            {variant: 'error'});
                                        setRefresh(!refresh)
                                    })
                                    .catch((error)=>{
                                        Log(2, error);
                                    });
                                }
                            }
                            if (categoryType.toString() === 'Pallet Lot'){
                                if (actionType.toString() === 'Add'){
                                    addPalletLotCategory({sku: tempCategory.sku, category: tempCategory.category, "retail_percentage": tempCategory['retail_percentage']})
                                    .then((success) => {
                                        if (success){
                                        enqueueSnackbar(
                                            'Succesfully added pallet lot category.',
                                            {variant : 'success'}
                                        )
                                        setRefresh(!refresh)
                                    }
                                        else{
                                            enqueueSnackbar('Duplicate pallet lot SKU not allowed.',
                                            {variant: 'error'});
                                        }
                                    })
                                    .catch((error)=>{
                                        Log(2, error);
                                        enqueueSnackbar('Error adding pallet lot category.',
                                        {variant: 'error'});
                                    });
                                }
                                if (actionType.toString() === 'Edit'){
                                    updatePalletLotCategory({id: tempCategory.id, sku:tempCategory.sku, category:tempCategory.category, "retail_percentage": tempCategory['retail_percentage']})
                                    .then((success)=> {
                                        if(success)
                                        enqueueSnackbar('Succesfully updated pallet lot category.', {variant: 'success'});
                                        else
                                        enqueueSnackbar('Duplicate pallet lot SKU not allowed.',
                                            {variant: 'error'});
                                        setRefresh(!refresh)
                                    })
                                    .catch((error)=>{
                                        Log(2, error);
                                    });
                                }
                            }
                            setShowForm(false);
                        }}
                    >
                        Save
                    </MuiButton>
                </DialogActions>
            </Dialog>
        )
    }

    return (
    <Styles.Page>
        {showForm && <CategoryForm /> }
        <Box sx={{width: '100%'}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={tab} onChange={handleTabChange}>
                    <Tab label="Individual Product Categories"/>
                    <Tab label="Pallet Categories"/>
                    <Tab label="Pallet Lot Categories"/>
                </Tabs>
            </Box>
            <TabPanel value={tab} index={0}>
                <IndividualProductTab/>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <PalletTab/>
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <PalletLotTab/>
            </TabPanel>
        </Box>
    </Styles.Page>
    )
};

export default Categories;
