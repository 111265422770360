import React, { useContext, useEffect, useState } from 'react';
import Styles, { Colors } from 'Styles';
import {
  PAGE_HEIGHT,
  PAGE_WIDTH,
  PALLET_INDIVIDUAL_PRODUCT_FIELDS,
  SEVEN_DAYS_AGO,
  UserContext,
  getIndividualCategoriesMap,
} from '../utilities/DataTypes';
import { API_URL } from '../env';
import Log from './Logger';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinusSquare,
  faPrint,
  faSave,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { formatter, formattedDate } from '../utilities/Helpers';
import PalletIndividualProductPdf from './PalletIndividualProductPdf';
import PrintModal from './PrintModal';
import { pdf } from '@react-pdf/renderer';
import ExportToShopify from './ExportToShopify';
import { Box, CircularProgress, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

// Styles

// Smart components

type PalletIndividualProduct = {
  pallet_individual_product_id: number;
  pallet_individual_product_sku: string;
  pallet_individual_product_title: string;
  pallet_individual_product_retail_price: number;
  pallet_individual_product_retail_percentage: number;
  pallet_individual_product_cost: number;
  pallet_individual_product_quantity: number;
  pallet_individual_product_synced_to_shopify: boolean;
};

const deleteProduct = (palletIndividualProductId: number): Promise<boolean> => {
  return fetch(API_URL + 'deletePalletIndividualProduct', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      pallet_individual_product_id: palletIndividualProductId,
    }),
  })
    .then((response) => {
      return response.ok;
    })
    .catch((err) => {
      Log(2, err);
      throw err;
    });
};

// Dumb components

interface IndividualProductsTableProps {
  results: any[];
  loading: boolean;
  setRefresh: any;
  retailPercentMap: Map<string | undefined, number | undefined>;
}

const IndividualProductsTable = (props: IndividualProductsTableProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [results, setResults] = useState<any[]>([]);
  const [currentInfo, setCurrentInfo]: [any, any] = useState({});
  const [currentSku, setCurrentSku]: [string, any] = useState('');
  const [showPrintPreview, setShowPrintPreview] = useState<boolean>(false);
  const [pdfBlob, setPdfBlob] = useState<Blob>(new Blob());

  const onModalCancel = () => {
    setShowPrintPreview(false);
    setCurrentSku('');
    setCurrentInfo({});
    setPdfBlob(new Blob());
  };

  const handleSaveClick = (palletProductInfo: any) => {
    updateIndividualProduct(palletProductInfo)
      .then((success) => {
        if (success) {
          enqueueSnackbar(
            `Updated product ${palletProductInfo[
              'pallet_individual_product_title'
            ].substring(0, 25)}`,
            { variant: 'success' }
          );
          props.setRefresh();
        } else {
          enqueueSnackbar(
            `Failed to update product ${palletProductInfo[
              'pallet_individual_product_title'
            ].substring(0, 25)}`,
            { variant: 'error' }
          );
        }
      })
      .catch((_) => {
        enqueueSnackbar(
          `Failed to update product ${palletProductInfo[
            'pallet_individual_product_title'
          ].substring(0, 25)}`,
          { variant: 'error' }
        );
      });
  };

  const getRetailPercent = (sku: string) => {
    const skuPrefix = sku.substring(0, sku.lastIndexOf('-'));
    if (props.retailPercentMap.has(skuPrefix)) {
      return props.retailPercentMap.get(skuPrefix);
    } else return null;
  };

  const updateIndividualProduct = (
    palletProductInfo: any
  ): Promise<boolean> => {
    return fetch(API_URL + 'updatePalletIndividualProduct', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({
        fields: {
          pallet_individual_product_id:
            palletProductInfo.pallet_individual_product_id,
          pallet_individual_product_sku:
            palletProductInfo.pallet_individual_product_sku,
          pallet_individual_product_title:
            palletProductInfo.pallet_individual_product_title,
          pallet_individual_product_retail_price:
            palletProductInfo.pallet_individual_product_retail_price,
          pallet_individual_product_retail_percentage:
            palletProductInfo.pallet_individual_product_retail_percentage,
          pallet_individual_product_cost:
            palletProductInfo.pallet_individual_product_cost,
          pallet_individual_product_quantity: parseInt(
            palletProductInfo.pallet_individual_product_quantity
          ),
        },
      }),
    })
      .then((response) => {
        return response.status.toString().substring(0, 1) === '2';
      })
      .catch((err) => {
        Log(2, err);
        return false;
      });
  };

  useEffect(() => {
    setResults(props.results);
  }, [props.results]); // FIXME

  if (results !== undefined && results.length > 0) {
    return (
      <>
        <table
          style={{
            borderCollapse: 'collapse',
            tableLayout: 'fixed',
            boxSizing: 'border-box',
            width: '100%',
          }}
        >
          <thead>
            <tr>
              {Object.keys(PALLET_INDIVIDUAL_PRODUCT_FIELDS).map(
                (field: any, index: number) => {
                  return (
                    <Styles.StyledTH
                      key={index}
                      style={{
                        width: field === 'TITLE' ? '10vw' : '5vw',
                      }}
                    >
                      {field}
                    </Styles.StyledTH>
                  );
                }
              )}
              <Styles.StyledTH
                style={{
                  width: '5vw',
                }}
              >
                ACTIONS
              </Styles.StyledTH>
            </tr>
          </thead>
          <tbody>
            {results.map((result: any, top_index: number) => (
              <tr
                key={'TR-' + result['pallet_individual_product_id']}
                style={{ borderBottom: 'thin solid black' }}
              >
                {Object.values(PALLET_INDIVIDUAL_PRODUCT_FIELDS).map(
                  (field: any, index: number) => {
                    switch (field) {
                      case PALLET_INDIVIDUAL_PRODUCT_FIELDS['RETAIL PRICE']:
                      case PALLET_INDIVIDUAL_PRODUCT_FIELDS['COST']:
                        return (
                          <Styles.StyledTD key={index}>
                            {result[field]
                              ? formatter.format(result[field])
                              : 'NONE'}
                          </Styles.StyledTD>
                        );
                      case PALLET_INDIVIDUAL_PRODUCT_FIELDS['% OF RETAIL']:
                        return (
                          <Styles.StyledTD key={index}>
                            {result[field]
                              ? result[field].toFixed(2).toLocaleString() + '%'
                              : 'NONE'}
                          </Styles.StyledTD>
                        );
                      case PALLET_INDIVIDUAL_PRODUCT_FIELDS['SYNCED']:
                        return (
                          <Styles.StyledTD key={index}>
                            {result[field] && (
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                  width: '30px',
                                  height: '30px',
                                  marginBottom: 0,
                                }}
                                color={Colors.green}
                              />
                            )}
                          </Styles.StyledTD>
                        );
                      case PALLET_INDIVIDUAL_PRODUCT_FIELDS['QUANTITY']:
                        return (
                          <Styles.StyledTD key={index}>
                            <TextField
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 1,
                                },
                              }}
                              value={result[field] ?? 1}
                              onChange={(e) => {
                                let temp_results = [...results];
                                result.pallet_individual_product_quantity =
                                  e.target.value;
                                temp_results[top_index] = result;
                                setResults(temp_results);
                              }}
                            />
                          </Styles.StyledTD>
                        );
                      default:
                        return (
                          <Styles.StyledTD key={index}>
                            {result[field] || 'NONE'}
                          </Styles.StyledTD>
                        );
                    }
                  }
                )}
                <Styles.StyledTD>
                  <Styles.TableButton
                    style={{
                      padding: '10px 15px 2px 0px',
                      backgroundColor: '#FFF0',
                      margin: 0,
                    }}
                    onClick={() => {
                      handleSaveClick(result);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faSave}
                      style={{ width: '24px', height: '24px', marginBottom: 0 }}
                      color={Colors.green}
                    />
                    <p style={{ fontSize: 12, marginTop: 0 }}> Save </p>
                  </Styles.TableButton>
                  <Styles.TableButton
                    style={{
                      padding: '10px 15px 2px 0px',
                      backgroundColor: '#FFF0',
                      margin: 0,
                    }}
                    onClick={() => {
                      if (
                        window.confirm(
                          'Are you sure you want to delete this product? Once deleted it cannot be recovered.'
                        )
                      ) {
                        deleteProduct(result['pallet_individual_product_id'])
                          .then((success) => {
                            if (success) {
                              enqueueSnackbar(
                                `Removed product ${result[
                                  'pallet_individual_product_title'
                                ].substring(0, 25)}`,
                                { variant: 'success' }
                              );
                              setResults(
                                (oldResults: PalletIndividualProduct[]) =>
                                  oldResults.filter(
                                    (r: PalletIndividualProduct) =>
                                      r.pallet_individual_product_id !==
                                      result['pallet_individual_product_id']
                                  )
                              );
                              props.setRefresh();
                            } else {
                              enqueueSnackbar(
                                `Failed to remove product ${result[
                                  'pallet_individual_product_title'
                                ].substring(0, 25)}`,
                                { variant: 'error' }
                              );
                            }
                          })
                          .catch((_) => {
                            enqueueSnackbar(
                              `Failed to remove product ${result[
                                'pallet_individual_product_title'
                              ].substring(0, 25)}`,
                              { variant: 'error' }
                            );
                          });
                      }
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faMinusSquare}
                      style={{ width: '24px', height: '24px', marginBottom: 0 }}
                      color={Colors.red}
                    />
                    <p style={{ fontSize: 12, marginTop: 0 }}>Remove</p>
                  </Styles.TableButton>
                  <Styles.TableButton
                    style={{
                      padding: '10px 15px 2px 0px',
                      backgroundColor: '#FFF0',
                      margin: 0,
                    }}
                    onClick={async () => {
                      const newSku = result.pallet_individual_product_sku;
                      const newInfo = result;

                      setCurrentSku(newSku);
                      setCurrentInfo(newInfo);

                      // Wait for state updates to complete
                      await new Promise((resolve) => setTimeout(resolve, 0));

                      pdf(
                        <PalletIndividualProductPdf
                          productInfo={result}
                          barcode={result.pallet_individual_product_sku}
                          retailPercent={getRetailPercent(newSku)}
                        />
                      )
                        .toBlob()
                        .then((result) => {
                          setPdfBlob(result);
                          setShowPrintPreview(true);
                        });
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPrint}
                      style={{ width: '24px', height: '24px', marginBottom: 0 }}
                      color={Colors.greenBlue}
                    />
                    <p style={{ fontSize: 12, marginTop: 0 }}>Print</p>
                  </Styles.TableButton>
                </Styles.StyledTD>
              </tr>
            ))}
          </tbody>
        </table>
        <PrintModal
          dimensions={[432, 648]}
          previewComponent={
            <PalletIndividualProductPdf
              productInfo={currentInfo}
              barcode={currentSku}
              retailPercent={getRetailPercent(currentSku)}
            />
          }
          onCancelClick={() => onModalCancel()}
          open={showPrintPreview}
          pdfBlob={pdfBlob}
          pdfType={'Individual Product'}
          setShowPrintPreview={(value: boolean) => setShowPrintPreview(value)}
        />
      </>
    );
  } else {
    return (
      <p>
        {props.loading ? (
          <CircularProgress sx={{ mx: 'auto' }} />
        ) : (
          'No Results'
        )}
      </p>
    );
  }
};

interface PalletIndividualProductsTableProps {
  reloadProducts: boolean;
  setReloadProducts: any;
}

const PalletIndividualProductsTable = (
  props: PalletIndividualProductsTableProps
) => {
  const [products, setProducts] = useState<PalletIndividualProduct[]>([]);
  const [loadingResults, setLoadingResults] = useState(false);
  const [fromDate, setFromDate]: [string, any] = useState(
    SEVEN_DAYS_AGO.toISOString().slice(0, 10) // 7 days ago Fixme
  );
  const [toDate, setToDate]: [string, any] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const { enqueueSnackbar } = useSnackbar();
  const userContext = useContext(UserContext);
  const [retailPercentMap, setRetailPercentMap] = useState(
    new Map<string | undefined, number | undefined>()
  );
  useEffect(() => {
    const IndividualProductCategories = getIndividualCategoriesMap();
    setRetailPercentMap(
      IndividualProductCategories.retailPercentMap ?? retailPercentMap
    );
  }, []);

  const getRetailPercent = (sku: string) => {
    const skuPrefix = sku.substring(0, sku.lastIndexOf('-'));
    if (retailPercentMap.has(skuPrefix)) {
      return retailPercentMap.get(skuPrefix);
    } else return null;
  };

  useEffect(() => {
    const getProducts = async (): Promise<boolean> => {
      return await fetch(API_URL + 'getPalletIndividualProducts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          from_date: fromDate,
          to_date: toDate,
        }),
      })
        .then((response) => response.json())
        .then((response: { data: PalletIndividualProduct[] }) => {
          setProducts(response.data);
          setLoadingResults(false);
          return true;
        })
        .catch((err) => {
          enqueueSnackbar('Failed to load individual products.', {
            variant: 'error',
          });
          setLoadingResults(false);
          Log(2, err);
          return false;
        });
    };
    setLoadingResults(true);
    getProducts();
    props.setReloadProducts(false);
  }, [props.reloadProducts, fromDate, toDate]); // FIXME

  return (
    <>
      <Styles.FlexDiv
        style={{
          flex: '5 1 auto',
          justifyContent: 'center',
          alignSelf: 'flex-start',
          maxHeight: '130px',
        }}
      >
        <Styles.FlexDiv style={{ flexDirection: 'column' }}>
          <Styles.Text style={{ marginBottom: 0 }}>From</Styles.Text>
          <DatePicker
            value={fromDate}
            renderInput={(params) => (
              <TextField {...params} sx={{ width: 175, marginRight: '10px' }} />
            )}
            onChange={(newValue) => {
              setFromDate(formattedDate(newValue));
            }}
          />
        </Styles.FlexDiv>
        <Styles.FlexDiv style={{ flexDirection: 'column' }}>
          <Styles.Text style={{ marginBottom: 0 }}>To</Styles.Text>
          <DatePicker
            value={toDate}
            renderInput={(params) => (
              <TextField {...params} sx={{ width: 175, marginRight: '10px' }} />
            )}
            onChange={(newValue) => {
              setToDate(formattedDate(newValue));
            }}
          />
        </Styles.FlexDiv>
      </Styles.FlexDiv>
      <Box sx={{ ml: 'auto' }}>
        <div style={{ display: 'inline', marginRight: 15 }}>
          {userContext.isAdmin && (
            <ExportToShopify
              items={products.map((product) => ({
                productId: product.pallet_individual_product_id,
                productName: product.pallet_individual_product_title,
                productSku: product.pallet_individual_product_sku,
                productPrice: product.pallet_individual_product_retail_price,
                RetailPercent: getRetailPercent(
                  product.pallet_individual_product_sku
                ),
                productPieces: product.pallet_individual_product_quantity,
                isPallet: false,
              }))}
              forceSync={true}
              ProductType={'ip'}
            />
          )}
        </div>
        <div style={{ display: 'inline' }}>
          <ExportToShopify
            items={products.map((product) => ({
              productId: product.pallet_individual_product_id,
              productName: product.pallet_individual_product_title,
              productSku: product.pallet_individual_product_sku,
              productPrice: product.pallet_individual_product_retail_price,
              RetailPercent: getRetailPercent(
                product.pallet_individual_product_sku
              ),
              productPieces: product.pallet_individual_product_quantity,
              isPallet: false,
            }))}
            forceSync={false}
            ProductType={'ip'}
          />
        </div>
      </Box>
      <Styles.Page
        style={{
          flex: '1',
          marginTop: 20,
          marginBottom: 10,
          minWidth: PAGE_WIDTH * 0.9,
          maxWidth: PAGE_WIDTH * 0.9,
          minHeight: 220,
          maxHeight: PAGE_HEIGHT * 0.5,
          overflow: 'scroll',
          alignItems: 'flex-start',
        }}
      >
        <div style={{ display: 'inline', flex: 1, minWidth: 1200 }}>
          <IndividualProductsTable
            results={products}
            loading={loadingResults}
            setRefresh={() => props.setReloadProducts(!props.reloadProducts)}
            retailPercentMap={retailPercentMap}
          />
        </div>
      </Styles.Page>
    </>
  );
};

export default PalletIndividualProductsTable;
