import React, { useState } from 'react';
import Styles from 'Styles';
import { PAGE_HEIGHT, PAGE_WIDTH } from '../utilities/DataTypes';
import PalletSearchTable from '../components/PalletSearchTable';
import PalletIndividualProductsTable from '../components/IndividualProductsTable';

const IndividualProducts = () => {
  const [reloadProducts, setReloadProducts] = useState(false);

  return (
    <Styles.Page
      style={{
        flex: 1,
        minWidth: PAGE_WIDTH,
        maxWidth: PAGE_WIDTH,
        minHeight: PAGE_HEIGHT,
        maxHeight: PAGE_HEIGHT,
        boxSizing: 'border-box',
        overflowY: 'auto',
      }}
    >
      <Styles.Page
        style={{ flex: '1 1', maxWidth: PAGE_WIDTH, minHeight: 600 }}
      >
        <Styles.PageHeader style={{ alignSelf: 'flex-start' }}>
          Individual Products
        </Styles.PageHeader>
        <PalletSearchTable
          palletSku={''}
          setReloadProducts={() => setReloadProducts(true)}
          addType="individual"
        />
        <PalletIndividualProductsTable
          reloadProducts={reloadProducts}
          setReloadProducts={(value: boolean) => setReloadProducts(value)}
        />
      </Styles.Page>
    </Styles.Page>
  );
};

export default IndividualProducts;
