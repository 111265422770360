import React, { useState } from 'react';
import { MuiButton } from 'Styles';
import { ROLES } from '../utilities/DataTypes';
import styled from 'styled-components';
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

const MessageLabel = styled.label`
  color: #f00;
  display: flex;
  flex-wrap: wrap;
`;
const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  width: 100%;
  margin-bottom: 10px;
  font-family: Open Sans, sans-serif;
`;

interface UserPopupProps {
  username: string;
  setUsername: any;
  password: string;
  setPassword: any;
  userType: string;
  setIsAdmin: any;
  submitUser: any;
  setViewForm: any;
  waitOnUser: boolean;
  isUpdate: boolean;
  showForm: boolean;
}

const getKeyByValue = (
  dict: { [k: string]: string },
  searchedValue: string
): string => {
  for (const [key, value] of Object.entries(dict)) {
    if (searchedValue === value) {
      return `${key.replace(/_/g, ' ')}`;
    }
  }
  return 'RGT';
};

const UserPopup = (props: UserPopupProps) => {
  const [message, setMessage] = useState('');
  const [userType, setUserType] = useState(
    getKeyByValue(ROLES, props.userType) ?? 'RGT'
  );
  const [userTypeChanged, setUserTypeChanged] = useState(false);

  return (
    <Dialog open={props.showForm} maxWidth={'sm'} fullWidth>
      <DialogTitle>{props.isUpdate ? 'Edit' : 'Add'} User</DialogTitle>
      <DialogContent>
        <FormRow>
          Username
          <TextField
            id="outlined-name"
            value={props.username}
            disabled = {props.isUpdate}
            autoFocus={true}
            autoCapitalize="none"
            autoComplete="username"
            onChange={(event) => {
              props.setUsername(event.target.value);
            }}
          />
        </FormRow>
        <FormRow>
          Password
          <TextField
            autoCapitalize="none"
            autoComplete="current-password"
            type="password"
            value={props.password}
            onChange={(e) => {
              props.setPassword(e.target.value);
              const testHasDigit = /.*\d.*/;
              const testHasSymbol = /.*\W.*/;
              const testHasLowercase = /.*[a-z].*/;
              const testHasUppercase = /.*[A-Z].*/;
              if (e.target.value.length < 8) {
                setMessage('Password must be at least 8 characters');
              } else if (!testHasLowercase.test(e.target.value)) {
                setMessage('Password must have at least one lowercase letter');
              } else if (!testHasUppercase.test(e.target.value)) {
                setMessage('Password must have at least one uppercase letter');
              } else if (!testHasDigit.test(e.target.value)) {
                setMessage('Password must have at least one number');
              } else if (!testHasSymbol.test(e.target.value)) {
                setMessage(
                  'Password must have at least one special character (e.g. - ! # *)'
                );
              } else {
                setMessage('');
              }
            }}
          />
        </FormRow>
        <MessageLabel>{message}</MessageLabel>
        <FormRow
          style={{
            marginBottom: '80px',
          }}
        >
          Available Roles
          <Autocomplete
            id="roles"
            disablePortal={false}
            options={Object.entries(ROLES).map(
              ([key, value]) => `${key.replace(/_/g, ' ')}`
            )}
            renderInput={(params) => <TextField {...params} />}
            defaultValue={userType}
            onChange={(event, newValue) => {
              let role = ROLES[newValue.replace(/ /s, '_')] ?? '';
              setUserType(role);
              setUserTypeChanged(true);
            }}
            disableClearable
          />
        </FormRow>
      </DialogContent>
      <DialogActions
        sx={{
          m: 2,
        }}
      >
        <MuiButton
          variant="contained"
          onClick={() => {
            props.setViewForm(false);
            props.setPassword('');
            props.setUsername('');
            props.setIsAdmin(false);
          }}
          disabled={props.waitOnUser}
        >
          Cancel
        </MuiButton>
        <MuiButton
          variant="contained"
          // style={{ flex: '1' }}
          disabled={
            !(
              props.username.length > 0 &&
              message.length <= 0 &&
              (props.password.length > 0 || props.isUpdate)
            ) || props.waitOnUser
          }
          type="submit"
          onClick={() =>
            props.submitUser(
              props.username,
              props.password,
              props.isUpdate
                ? userTypeChanged
                  ? userType
                  : props.userType
                : userType,
              props.isUpdate
            )
          }
        >
          Submit
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
};

export default UserPopup;
