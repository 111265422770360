import { API_URL } from '../env';
import Log from '../components/Logger';
import React from 'react';
import { FeedbackType, PalletProduct, PalletStats, Printer } from './DataTypes';

/* APICalls:
 * Used to make same API call from multiple locations. (Re-used calls)
 */

/**
 * Returns a list of Loads as <option> tags for a dropdown. TODO: Return as raw JSON.
 * @param props expects props to include auth. TODO: Replace with auth param.
 */
export const getLoads = (props: any): Promise<any> => {
  return fetch(API_URL + 'getLoads', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: props.auth,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      Log(0, 'getLoads response: ' + response);
      let options = response.Loads.map((load: any) => (
        <option key={load} value={load} />
      ));
      return {
        options: options,
        items: response.Loads,
      };
    })
    .catch((err) => {
      Log(2, err);
    });
};

/**
 *
 * @param loadInfo Information about the load {load_name, load_source, load_status, load_start_date}
 * @param auth User auth string
 */
export const addLoad = (loadInfo: any, auth: string): Promise<boolean> => {
  return fetch(API_URL + 'addLoad', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: auth,
    },
    body: JSON.stringify({
      fields: loadInfo,
    }),
  })
    .then(() => {
      return true;
    })
    .catch((err) => {
      Log(2, err);
      return false;
    });
};

export const updateLoad = (
  auth: string,
  load: any
): Promise<any> => {
  return fetch(API_URL + 'updateLoad', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: auth,
    },
    body: JSON.stringify({
      load_id: load['load_id'],
      fields: {
        load_status: load['load_status'],
        load_start_date: load['load_start_date'],
        load_complete_date: load['load_complete_date'],
        load_source: load['load_source'],
        load_sort_fuzzy_match: load['load_sort_fuzzy_match'],
        load_unmanifested: load['load_unmanifested'],
        load_cost: load['load_cost'],
      },
    }),
  });
};

export const deleteLoad = (loadId: number, auth: string): Promise<any> => {
  return fetch(API_URL + 'deleteLoad', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: auth,
    },
    body: JSON.stringify({
      load_id: loadId,
    }),
  });
};

export const addPallet = async (palletInfo: any, auth: string): Promise<boolean> => {
  return await fetch(API_URL + 'addPallet', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: auth,
    },
    body: JSON.stringify({
      fields: palletInfo,
    }),
  })
    .then(() => {
      return true;
    })
    .catch((err) => {
      Log(2, err);
      return false;
    });
};
export const updatePallet = async (
  palletSku: string,
  palletInfo: any,
  auth: string
): Promise<boolean> => {
  return await fetch(API_URL + 'updatePallet', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: auth,
    },
    body: JSON.stringify({
      pallet_sku: palletSku,
      fields: palletInfo,
    }),
  })
    .then(() => {
      return true;
    })
    .catch((err) => {
      Log(2, err);
      return false;
    });
};
export const deletePallet = async (palletSku: string, auth: string): Promise<any> => {
  return await fetch(API_URL + 'deletePallet', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: auth,
    },
    body: JSON.stringify({
      pallet_sku: palletSku,
    }),
  });
};

/**
 * Submit RGT Feedback to PSQL DB for reporting.
 * @param type Item feedback type.
 * @param count Number of items for feedback.
 * @param accountName User who submitted feedback.
 * @param auth API auth.
 * @param record Record to submit.
 * @param enqueueSnackbar enqueueSnackbar hook must be a param since it can only be created by a React component.
 * @param missing Whether the item was missing or not.
 */

export const submitRGTFeedback = (
  type: FeedbackType,
  count: number,
  accountName: string,
  auth: string,
  record: any,
  enqueueSnackbar: any,
  missing?: boolean
): Promise<boolean> => {
  let msgStart: string;
  switch (type) {
    case 'Accept':
      msgStart = 'You have accepted';
      break;
    case 'Reject-D':
      msgStart = 'You have rejected (defect)';
      break;
    case 'Reject-V':
      msgStart = 'You have rejected (value)';
      break;
    case 'Reject-C':
      msgStart = 'You have rejected (condition)';
      break;
    case 'Reject-W':
      msgStart = 'You have rejected (weight)';
      break;
    case 'Reject-F':
      msgStart = 'You have rejected (fraud)';
      break;
    default:
      msgStart = 'Invalid feedback:';
      break;
  }

  let fieldData = {
    username: accountName,
    status: type,
    count: count,
    item_asin: record.ASIN,
    item_upc: record.UPC,
    item_price: record.RETAIL_PRICE.replace('$', ''),
    item_load: record.LOAD_NAME,
    item_source: record.SOURCE,
    item_name: record.TITLE,
    item_destination: record.DESTINATION,
    missing: missing || false,
    reject_reason: record.reject_reason,
  };
  Log(
    0,
    'Submitting feedback.\n' + JSON.stringify(fieldData) + '\n' + type + ': ' + count
  );

  return fetch(API_URL + 'submitRGTFeedback', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: auth,
    },
    body: JSON.stringify({
      fields: fieldData,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      Log(0, 'submitRGTFeedback response: ' + JSON.stringify(response));
      enqueueSnackbar(
        msgStart +
          ' ' +
          count +
          ' of ' +
          (record.ASIN.length > 0 ? record.ASIN : record.UPC) +
          (record.ASIN.length + record.UPC.length <= 0 ? '' : ', ') +
          record.TITLE,
        {
          variant: msgStart.includes('Invalid feedback:') ? 'error' : 'success',
        }
      );
      return true;
    })
    .catch((err) => {
      Log(2, err);
      enqueueSnackbar('Feedback error. Feedback may not be submitted.');
      return false;
    });
};


export const getDestinations = (auth: string): Promise<Response> => {
  return fetch(API_URL + 'getDestinations', {
    method: 'GET',
    headers: {
      Authorization: auth,
    },
  })
}

export const submitVentasFeedback = (
  type: FeedbackType,
  count: number,
  accountName: string,
  auth: string,
  record: {
    ASIN: string | null;
    UPC: string | null;
    RETAIL_PRICE: string | null;
    LOAD_NAME: string | null;
    SOURCE: string | null;
    TITLE: string | null;
    DESTINATION: string | null;
  },
  enqueueSnackbar: any,
  missing?: boolean
): Promise<boolean> => {
  let msgStart: string;
  switch (type) {
    case 'Accept':
      msgStart = 'You have accepted';
      break;
    case 'Reject-D':
      msgStart = 'You have rejected (defect)';
      break;
    case 'Reject-V':
      msgStart = 'You have rejected (value)';
      break;
    case 'Reject-C':
      msgStart = 'You have rejected (condition)';
      break;
    case 'Reject-W':
      msgStart = 'You have rejected (weight)';
      break;
    default:
      msgStart = 'Invalid feedback:';
      break;
  }

  let fieldData = {
    username: accountName,
    status: type,
    count: count,
    item_asin: record.ASIN,
    item_upc: record.UPC,
    item_price: record.RETAIL_PRICE?.replace('$', '') || null,
    item_load: record.LOAD_NAME,
    item_source: record.SOURCE,
    item_name: record.TITLE,
    item_destination: record.DESTINATION,
    missing: missing || false,
  };

  Log(
    0,
    'Submitting feedback.\n' + fieldData.toString() + '\n' + type + ': ' + count
  );

  return fetch(API_URL + 'addVentasFeedback', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: auth,
    },
    body: JSON.stringify({
      fields: fieldData,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      Log(0, 'submitVentasFeedback response: ' + response);
      enqueueSnackbar(
        msgStart +
          ' ' +
          count +
          ' of ' +
          (missing === false
            ? record.ASIN !== null && record.ASIN.length > 0
              ? record.ASIN
              : record.UPC
            : '') +
          (missing === false
            ? record.ASIN !== null &&
              record.UPC !== null &&
              record.ASIN.length + record.UPC.length > 0
              ? ', '
              : ''
            : '') +
          record.TITLE,
        {
          variant: msgStart.includes('Invalid feedback:') ? 'error' : 'success',
        }
      );
      return true;
    })
    .catch((err) => {
      Log(2, err);
      enqueueSnackbar('Feedback error. Feedback may not be submitted.');
      return false;
    });
};

export const getPalletStats = async (palletSku: string): Promise<PalletStats> => {
  return await fetch(API_URL + 'getPalletProductStats', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      pallet_sku: palletSku,
    }),
  })
    .then((response) => response.json())
    .then((response: PalletStats) => {
      return response;
    })
    .catch((err) => {
      Log(2, err);
      throw err;
    });
};

export const getProducts = async (palletSku: string): Promise<PalletProduct[]> => {
  return await fetch(API_URL + 'getPalletProducts', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      pallet_sku: palletSku,
    }),
  })
    .then((response) => response.json())
    .then((response: { data: PalletProduct[] }) => {
      return response.data;
    })
    .catch((err) => {
      Log(2, err);
      throw err;
    });
};

export const getPalletProductsShopify = async (palletSkus: string): Promise<any[]> => {
  //console.log('reached getPalletProductsShopify api call', palletSkus);
  return await fetch(API_URL + 'getPalletProductsShopify', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      palletSkus: palletSkus
    }),
  })
 .then((response) => response.json())
  .then((response) => {
    //console.log("getPalletProductsShopify api call: ", response.data)
    return response.data;
  })
  .catch((err)=> {
    Log(2, err);
    throw err;
  });
};

export const sendToPrintNode = (
  username: string,
  printerType: string,
  content: string
): Promise<number> => {
  return fetch(API_URL + 'sendPrintJob', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      content: content,
      username: username,
      printer_type: printerType,
    }),
  })
    .then((r) => r.json())
    .then((response: number) => {
      return response;
    })
    .catch((err) => {
      Log(2, err);
      throw err;
    });
};

export const listPrinters = (): Promise<Printer[]> => {
  return fetch(API_URL + 'listPrinters', {
    method: 'GET',
  })
    .then((r) => r.json())
    .then((response: Printer[]) => {
      return response;
    })
    .catch((err) => {
      Log(2, err);
      throw err;
    });
};

export const sendFile = (auth: string, requestBody: any) : Promise<Response> => {
  return fetch(API_URL + 'sendFile', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: auth,
    },
    body: JSON.stringify(requestBody),
  })
}
export const upload = (auth: string,) : Promise<Response> => {
  return fetch(API_URL + 'upload', {
    method: 'GET',
    headers: {
      Authorization: auth,
      'Access-Control-Allow-Origin': '*',
    },
  })
}

export const addPrinter = (
  username: string,
  printerType: string,
  printerId: number
): Promise<Response> => {
  return fetch(API_URL + 'addUserPrinter', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: username,
      printer_type: printerType,
      printer_id: printerId,
    }),
  });
};

export const updatePrinter = (
  username: string,
  printerType: string,
  printerId: number
): Promise<Response> => {
  return fetch(API_URL + 'updateUserPrinter', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: username,
      printer_type: printerType,
      printer_id: printerId,
    }),
  });
};

export const deletePrinter = (
  username: string,
  printerType: string
): Promise<Response> => {
  return fetch(API_URL + 'deleteUserPrinter', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: username,
      printer_type: printerType,
    }),
  });
};

export const getPrinters = (
  username: string,
  printerType?: string
): Promise<{ [k: string]: number }> => {
  return fetch(API_URL + 'getUserPrinters', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: username,
      printerType: printerType,
    }),
  })
    .then((r) => r.json())
    .then((response) => {
      return response;
    });
};

export const getSkuCategoryCounter = (skuCategory: string): Promise<number> => {
  return fetch(API_URL + 'getSkuCategoryCounter', {
    method: 'POST',
    body: JSON.stringify({ sku_category: skuCategory }),
  })
    .then((r) => r.json())
    .then((response: number) => {
      return response;
    });
};

export const updateSkuCategoryCounter = (
  skuCategory: string,
  skuNumber?: number
): Promise<number> => {
  return fetch(API_URL + 'updateSkuCategoryCounter', {
    method: 'POST',
    body: JSON.stringify({ sku_category: skuCategory, sku_number: skuNumber }),
  })
    .then((r) => r.json())
    .then((response: number) => {
      return response;
    });
};

export const exportToShopify = async (items: any[], forceSync: boolean): Promise<any> => {
  return await fetch(API_URL + 'exportToShopify', {
    method: 'POST',
    body: JSON.stringify({ items: items, forceSync: forceSync }),
  })
    .then((response) => {
      if (response.ok) {
        return response
      } else {
        return response.text().then((text) => { throw new Error(text) }) 
      }
    })
    .catch((error) => {
      Log(2, `Error exporting to Shopify: ${error}`);
      throw error;
    });
};

export const addIndividualProduct = (fields: any): Promise<boolean> => {
  return fetch(API_URL + 'addPalletIndividualProduct', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      fields: fields,
    }),
  })
    .then((response) => {
      return response.status.toString().substring(0, 1) === '2';
    })
    .catch((err) => {
      Log(2, `Error adding individual product ${err}`);
      throw err;
    });
};
