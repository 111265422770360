import React, { useContext, useEffect, useRef, useState } from 'react';
import Styles, { Colors, MuiButton } from '../Styles';
import { API_URL, DB_URL, ES_INDEX } from '../env';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Record from 'components/Record';
import FeedbackButtons from 'components/RGTFeedback';
import Log from 'components/Logger';
import {
  DISPLAY_FIELDS,
  NUMBER_FIELDS,
  PAGE_HEIGHT,
  PAGE_WIDTH,
  ROLES,
  SizeProps,
  UserContext,
} from '../utilities/DataTypes';
import RGTMissingItemForm from '../components/RGTMissingItemForm';
import { colorByPercent, formattedDate } from '../utilities/Helpers';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import VentasFeedbackButtons from '../components/VentasFeedbackButtons';
import { submitVentasFeedback } from '../utilities/APICalls';
import { TextField, CircularProgress, Tooltip } from '@mui/material';
import RejectReason from 'components/RejectReason';

const StyledSelect = styled.select`
  display: block;
  font-size: 16px;
  line-height: 1.3;
  margin-left: 5px;
  padding: 10px 0 10px 10px;
  border-radius: 4px;
`;
const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
const PageNavContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  min-height: 0;
  justify-content: space-between;
  align-items: flex-start;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
const SearchBarForm = styled.form`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
  align-self: flex-start;
  justify-content: flex-start;
  align-items: center;
`;
const MainTableContainer = styled.div<SizeProps>`
  display: flex;
  flex-direction: column;
  flex: 20 1 0;
  min-height: 0;
  max-height: ${(props) => props.pageHeight}px;
  overflow: scroll;
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: 0 0 5px #0003;
`;
const StyledDiv = styled.div`
  position: absolute;
  z-index: 100;
`;

interface SearchProps {
  setSelectedLoad: any;
  showLoadSelect: boolean;
  setShowLoadSelect: any;
  setPallet: any;
  pallet: any;
}

/**
 * Search an AWS ElasticSearch instance for records, and list the first 1000 results.
 */
const Search = (props: SearchProps) => {
  const [query, setQuery] = useState('');
  const [searchText, setSearchText] = useState('');
  const [lastBarcode, setLastBarcode] = useState('');
  const [queryCount, setQueryCount] = useState(25);
  const [queryTotal, setQueryTotal] = useState(0);
  const [queryNext, setQueryNext] = useState(0);
  const [searchAfter, setSearchAfter] = useState('');
  const [results, setResults]: [any, any] = useState([]);
  const [history, setHistory]: [any, any] = useState([]);
  const [sortBy, setSortBy]: [any, any] = useState([
    { upload_date: { order: 'desc' } },
    {},
    '_score'
  ]);
  const [refresh, setRefresh] = useState(false);
  const [viewRecord, setViewRecord] = useState(false);
  const [recordId, setRecordId] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [didInitSearch, setDidInitSearch] = useState(false);
  const [isFuzzy, setIsFuzzy] = useState(false);
  const [isUnmanifested, setIsUnmanifested] = useState(false);
  // const [missingItemStatus, setMissingItemStatus] = useState("default");
  const [isRejectingRGTItem, setIsRejectingRGTItem] = useState(false);
  const [viewMissingItemForm, setViewMissingItemForm] = useState(false);
  const [destinations, setDestinations]: [any, any] = useState({});
  const [isEmptyLoad, setIsEmptyLoad] = useState(false);
  const [lastScanReportId, setLastScanReportId] = useState<number>(0);
  const [lastScanReportResults, setLastScanReportResults] = useState<any>([]);
  const [lastUpdatedScanReportId, setLastUpdatedScanReportId] =
    useState<number>(0);
  const isLoaded = useRef(true);
  const searchBox = useRef(document.createElement('input'));
  const timeOfLastReport = useRef(new Date());
  const userContext = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [showRejectFeedback, setShowRejectFeedback] = useState(false);
  const [rejectFeedback, setRejectFeedback] = useState('');
  //scanner stats
  const [pieceCounts, setPieceCounts]: [
    { daily: number; hourly: number },
    any
  ] = useState({ daily: 0, hourly: 0 });
  const [positivityRate, setPositivityRate] = useState(''); 
  const [totalScansByLoad, setTotalScansByLoad] = useState(0);
  const [validScans, setValidScans] = useState(0);
  const [totalScansByUserName, setTotalScansByUsername] = useState(0);
  const [scanStart, setScanStart] = useState('');
  const [activeHours, setActiveHours] = useState(0);

  // On mount/unmount
  useEffect(() => {
    fetch(API_URL + 'getDestinations', {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((response: { destinations: any[] }) => {
        let dests: any = {};
        response.destinations.forEach((destination: any) => {
          dests[destination.destination_name] = destination.destination_color;
        });
        setDestinations(dests);
      });
    if (userContext.userRole === ROLES.NOVOTEX) {
      const keyDownHandler = (e: KeyboardEvent) => {
        searchBox.current.focus();
        if (e.repeat && e.key === 'Enter') {
          e.preventDefault();
        }
      };

      window.addEventListener('keydown', keyDownHandler);

      return () => {
        window.removeEventListener('keydown', keyDownHandler);
      };
    }
  }, [userContext.userRole]);

  // On load changed
  useEffect(() => {
    if (userContext.userRole === ROLES.NOVOTEX) {
      if (userContext.selectedLoad.length > 0) {
        getScannerStats();
        fetch(API_URL + 'getLoad', {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: userContext.auth,
          },
          body: JSON.stringify({
            load_name: userContext.selectedLoad,
          }),
        })
          .then((response) => response.json())
          .then(
            (response: {
              load: {
                load_sort_fuzzy_match: boolean;
                load_unmanifested: boolean;
              };
            }) => {
              setIsFuzzy(response.load.load_sort_fuzzy_match);
              setIsUnmanifested(response.load.load_unmanifested);
            }
          );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.selectedLoad, userContext.userRole, userContext.auth]);

  useEffect(() => {
    //Log(0, 'Using this weird useEffect()');
    if (
      !didInitSearch &&
      (userContext.userRole !== ROLES.NOVOTEX ||
        userContext.selectedLoad.length > 0)
    ) {
      if (userContext.auth.length > 0) {
        initSearch('');
        setDidInitSearch(true);
      }
    }
    if (refresh && queryTotal > 0) {
      search(query);
      setRefresh(false);
    } else if (refresh) {
      setRefresh(false);
    }

    return () => {
      isLoaded.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    didInitSearch,
    query,
    queryTotal,
    refresh,
    userContext.auth.length,
    userContext.selectedLoad.length,
    userContext.userRole,
  ]);

  // Load initial results for non-sorters
  useEffect(() => {
    if (userContext.auth.length > 0) {
      if (userContext.userRole !== ROLES.NOVOTEX) {
        initSearch('');
      }
      setDidInitSearch(true);
      refreshHistory();
    }

    //TODO: Find a way to remove this safely:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userContext.selectedLoad,
    userContext.auth,
    userContext.accountName,
    userContext.userRole,
  ]);

  // Update scanner results
  useEffect( 
    function updateScannerStats(){
      // positivityRate = valid scans / active hours worked today
      // valid scans: scans with a destination and not a missing item
      var posRate = totalScansByLoad > validScans ? validScans / totalScansByLoad : 0;
      console.log('pos rate is ', posRate);
      setPositivityRate( posRate.toLocaleString( undefined, {style: 'percent', minimumFractionDigits:2}) );

      // dailyPieceCount is how many scans a scanner has made today
      var dailyPieceCount = totalScansByUserName;

      // hourlyPieceCount will calculate from total hours worked (first scan to now) if active hours is negative or 0
      var hourlyPieceCount;
      if ( activeHours <= 0){
        var startTime = Date.parse(scanStart);
        var currentTime = Date.parse(String(new Date()));
        //convert ms to hours from the first scan today to now
        var hoursWorked = (currentTime - startTime) / 3600000;
        if (hoursWorked <= 0){
          hoursWorked = 1;
        }

        hourlyPieceCount = (totalScansByUserName / hoursWorked).toFixed(2);
      }
      // if there are active hours, hourlyPieceCount = (totalScansByUserName/activeHours)
      else{
        hourlyPieceCount = ( totalScansByUserName / activeHours ).toFixed(2);
      }

      //debugging printing
      //console.log('scanStart in useEffect ', scanStart);
      /*console.log('////// scanner stats //////');
      console.log('totalScansByLoad ', totalScansByLoad);
      console.log('totalScansByUsername ', totalScansByUserName);
      console.log('validScans ', validScans);
      console.log('dailyPieceCount is ', dailyPieceCount);
      console.log('hourlyPieceCount is ', hourlyPieceCount);
      console.log('activeHours is ', activeHours);
      console.log('pallet is', userContext.pallet);
      console.log('////// end of scanner stats //////');*/

      setPieceCounts({ daily: dailyPieceCount, hourly: hourlyPieceCount});
    }, 
    [
    totalScansByLoad,
    validScans,
    totalScansByUserName,
    scanStart,
    activeHours
    ]
  );

  const refreshHistory = () => {
    if (userContext.userRole === ROLES.RGT) {
      return fetch(API_URL + 'getRGTFeedbackHistory', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: userContext.auth,
        },
        body: JSON.stringify({
          username: userContext.accountName,
          limit: 25,
        }),
      })
        .then((response) => response.json())
        .then((response: { data: any }) => {
          setHistory(response.data);
        });
    }
  };

  /* Search functions */

  const initSearch = (localQuery: string) => {
    setResults([]);
    setQueryTotal(0);
    setQueryNext(0);
    setViewRecord(false);
    setLoadingSearch(true);
    setCurrentPage(1);
    // setMissingItemStatus("default");
    if (userContext.userRole === 'novotex-sorter') {
      setSortBy(
        '_score',
        {},
        { upload_date: { order: 'desc' } }
      );
      handleFunctionalBarcodes(localQuery).then((foundBarcode) => {
        if (!foundBarcode) {
          setQuery(localQuery);
          search(localQuery).then((_) => {
            setLoadingSearch(false);
          });
        }
      });
    } else {
      setSortValues();
      setQuery(localQuery);
      search(localQuery).then((_) => {
        setLoadingSearch(false);
      });
    }
  };

  const search = (localQuery: string): Promise<number> => {
    setResults([]);
    if (queryCount + queryNext >= 10000) {
      return searchQuery(localQuery, searchAfter).catch((e) => {
        Log(2, `Search error: ${e}`);
        enqueueSnackbar(
          'Failed to load search results. Please check your internet connection and try again.',
          { variant: 'error' }
        );
        return 0;
      });
    } else {
      return searchQuery(localQuery).catch((e) => {
        Log(2, `Search error: ${e}`);
        enqueueSnackbar(
          'Failed to load search results. Please check your internet connection and try again.',
          { variant: 'error' }
        );
        return 0;
      });
    }
  };

  const searchConfig = (localQuery: string, searchAfter?: any): any => {
    let body: { [k: string]: any } = {
      size: queryCount,
    };
    if (searchAfter) {
      body.search_after = searchAfter;
    } else {
      body.from = queryNext;
    }

    let queryAnyCase = '';
    for (let i = 0; i < localQuery.length; i++) {
      queryAnyCase += `[${localQuery[i].toLowerCase()}${localQuery[
        i
      ].toUpperCase()}]`;
    }

    let queryShould = [];
    if (userContext.userRole === 'novotex-sorter' && localQuery.length > 0) {
      if (isFuzzy && localQuery.length >= 8) {
        if (localQuery[0] === '0') {
          localQuery = localQuery.substring(1);
        }
        queryShould = [
          {
            query_string: {
              query: `${localQuery.substring(0, 8)}*`,
              fields: ['ASIN', 'UPC', 'LPN'],
            },
          },
          {
            regexp: {
              'OTHER_BARCODES.keyword': {
                value: `.*:[ ]${queryAnyCase}[ ]\\|.*`,
                flags: 'ALL',
              },
            },
          },
        ];
      } else {
        queryShould = [
          {
            multi_match: {
              query: localQuery,
              fields: ['ASIN', 'UPC', 'LPN'],
            },
          },
          {
            regexp: {
              'OTHER_BARCODES.keyword': {
                value: `.*:[ ]${queryAnyCase}[ ]\\|.*`,
                flags: 'ALL',
              },
            },
          },
        ];
      }
      body.query = {
        bool: {
          filter: {
            bool: {
              must: [
                {
                  match: {
                    'LOAD_NUMBER.keyword': userContext.selectedLoad,
                  },
                },
              ],
              should: queryShould,
              minimum_should_match: 1,
            },
          },
        },
      };
      if (isEmptyLoad || isUnmanifested) {
        delete body.query.bool.filter.bool.must;
      }
      if (isUnmanifested) {
        body.sort = [
          {
            _script: {
              type: 'number',
              script: `!doc['LOAD_NUMBER.keyword'].empty && doc['LOAD_NUMBER.keyword'].value == '${userContext.selectedLoad}' ? 1 : 0`,
              order: 'desc',
            },
          },
        ];
      }
    } else if (userContext.userRole === 'novotex-sorter') {
      body.query = {
        bool: {
          filter: {
            term: { 'LOAD_NUMBER.keyword': userContext.selectedLoad },
          },
        },
      };
    } else {
      if (localQuery.length > 0) {
        const RGT_MATCH_FIELDS = [
          'TITLE',
          'UPC',
          'ASIN',
          'LPN',
          'OTHER_BARCODES',
          'ALGOPIX_UPC',
          'ALGOPIX_EAN',
        ];

        let queryShould = [];
        for (let field in RGT_MATCH_FIELDS) {
          queryShould.push({
            match_phrase: {
              [RGT_MATCH_FIELDS[field]]: {
                query: `${localQuery}`,
              },
            },
          });
        }

        body.query = {
          bool: {
            should: queryShould,
            minimum_should_match: 1,
          },
        };
      } else {
        body.query = {
          match_all: {},
        };
      }

      body.sort = sortBy;
    }

    return body;
  };

  const searchQuery = (
    localQuery: string,
    searchAfter?: any
  ): Promise<number> => {
    const searchBody = searchConfig(localQuery, searchAfter);
    //console.log('SearchBody: ', JSON.stringify(searchBody));
    return fetch(DB_URL + ES_INDEX + '/_search', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: userContext.auth,
      },
      body: JSON.stringify(searchBody),
    })
      .then((response) => response.json())
      .then((response: { hits?: { total?: any; hits?: any } }) => {
        if (isLoaded) {
          if (response.hits !== undefined && response.hits.hits.length > 0) {
            reportSorterSearch(localQuery, response.hits.hits);
            if (
              response.hits.total !== undefined &&
              response.hits.total.value !== undefined &&
              userContext.userRole !== ROLES.NOVOTEX_SORTER
            ) {
              setQueryTotal(response.hits.total.value);
            }
            setResults(response.hits.hits);
            setSearchAfter(
              response.hits.hits[response.hits.hits.length - 1].sort
            );
            return response.hits.hits.length;
          } else {
            setLastScanReportId(0);
          }
        }
      });
  };

  const setSortValues = (field?: string) => {
    let sort: Array<any> = [
      '_score',
      {},
      { upload_date: { order: 'desc' } },
    ];
    if (field !== undefined && field !== 'upload_date') {
        let asc = 'asc';
        if (
          Object.keys(sortBy[1]).length > 0 &&
          Object.keys(sortBy[1])[0] === field + '.keyword'
        ) {
          asc = sortBy[1][field + '.keyword'] === 'asc' ? 'desc' : 'asc';
        }
        sort[1] = { [field + '.keyword']: asc };
        if (NUMBER_FIELDS.includes(field)) {
          sort[0] = {
            _script: {
              type: 'number',
              script: `
                          try {
                              if (!doc['${field}.keyword'].empty) {
                                  Float.parseFloat(doc['${field}.keyword'].value.replace('$','').replace('%','').replace(',',''))
                              } else {
                                  0.0f
                              }
                          } catch (NumberFormatException ex) {
                              0.0f
                          }
                      `,
              order: asc,
            },
          };
        }
        setSortBy(sort);
        setRefresh(true);
  } else {
      setSortBy(sort);
    }
  };

  /* DB Modifications */

  const deleteRecord = (id: string) => {
    let confirmed = window.confirm(
      'Are you sure you want to delete this record? Once it is deleted, it cannot be recovered.'
    );
    if (confirmed) {
      fetch(DB_URL + ES_INDEX + '/_doc/' + id + '?refresh=true', {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: userContext.auth,
        },
      }).then(() => {
        if (isLoaded) {
          setRefresh(true);
        }
      });
    }
  };

  const reportSorterSearch = (
    localQuery: string,
    results: Array<any>,
    missing?: boolean
  ) => {
    if (missing) {
      // setMissingItemStatus("loading");
    }
    if (
      userContext.userRole !== 'novotex-sorter' ||
      (localQuery.length <= 0 && !missing) ||
      new Date().getTime() - timeOfLastReport.current.getTime() < 100
    ) {
      return; // We don't yet do anything if we aren't a sorter, or if we don't have a real query.
    }

    timeOfLastReport.current = new Date();
    let destination: string | null = null;
    let retailValue: number | null = null;
    let costPerPiece: number | null = null;
    let destinationReassigned: boolean = false;

    if (results.length > 0) {
      results.forEach((result: any) => {
        if (
          result._source !== undefined &&
          result._source.DESTINATION.length > 0 &&
          (result._source.ASIN.toLowerCase() === localQuery.toLowerCase() ||
            result._source.UPC.toLowerCase() === localQuery.toLowerCase() ||
            result._source.LPN.toLowerCase() === localQuery.toLowerCase() ||
            isFuzzy ||
            (result._source.hasOwnProperty('OTHER_BARCODES') &&
              result._source.OTHER_BARCODES.length > 0 &&
              result._source.OTHER_BARCODES.toLowerCase().includes(
                localQuery.toLowerCase().substring(0, 8)
              )))
        ) {
          destination = result._source.DESTINATION;
          if (result._source.hasOwnProperty('RETAIL_PRICE')) {
            retailValue = parseFloat(
              result._source.RETAIL_PRICE.replace(/[$ ,]/g, '')
            );
          }
          if (result._source.hasOwnProperty('COST_PER_PIECE')) {
            costPerPiece = parseFloat(
              result._source.COST_PER_PIECE.replace(/[$ ,]/g, '')
            );
          }
        }
      });
    }

    if (destination === null) {
      enqueueSnackbar('No destination for item ' + localQuery, {
        variant: 'warning',
      });
    }

    if (localQuery === lastBarcode) {
      if (
        lastUpdatedScanReportId > 0 &&
        lastUpdatedScanReportId === lastScanReportId
      ) {
        destinationReassigned = true;
      }
    }
    // console.log({
    //   username: userContext.accountName,
    //   query: localQuery,
    //   load: userContext.selectedLoad,
    //   missing_item: missing || false,
    //   destination: destination,
    //   retail_value: retailValue,
    //   cost_per_piece: costPerPiece,
    //   destination_reassigned: destinationReassigned,
    // });

    fetch(API_URL + 'submitScanReport', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: userContext.auth,
      },
      body: JSON.stringify({
        fields: {
          username: userContext.accountName,
          query: localQuery,
          load: userContext.selectedLoad,
          pallet: userContext.pallet, 
          missing_item: missing || false,
          destination: destination,
          retail_value: retailValue,
          cost_per_piece: costPerPiece,
          destination_reassigned: destinationReassigned,
        },
      }),
    })
      .then((response) => response.json())
      .then((response: { scan_report_id: number }) => {
        if (results.length > 0 && !missing) {
          if (
            lastScanReportId !== lastUpdatedScanReportId ||
            lastScanReportId === 0 ||
            localQuery !== lastBarcode
          ) {
            setLastScanReportResults(results);
            setLastScanReportId(response.scan_report_id);
            setLastUpdatedScanReportId(0);
          }
        } else {
          setLastScanReportId(0);
        }
        if (isLoaded) {
          // setMissingItemStatus("done");
        }
        // update local scanner stats
        getActiveHours();
        setTotalScansByLoad(totalScansByLoad + 1);
        setTotalScansByUsername(totalScansByUserName + 1);

        var isScanValid = destination && !missing;
        if (isScanValid)
          setValidScans(validScans + 1);
      })
      .catch((error) => {
        Log(2, error);
        enqueueSnackbar(
          'Failed to submit scan report. Please check your internet and try again.',
          { variant: 'error' }
        );
      });
  };

  const getScannerStats = async () => {
    await fetch(API_URL+'getScannerStats', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: userContext.auth,
      },
      body: JSON.stringify({
        pallet: userContext.pallet,
        load: userContext.selectedLoad,
        username: userContext.accountName,
      })
    }).then((response:any) => response.json())
    .then((response) => {
      //console.log(response.scanner_stats);
      setScanStart(response?.scanner_stats?.SCAN_START);
      setTotalScansByLoad(response?.scanner_stats?.TOTAL_SCANS_BY_LOAD);
      setTotalScansByUsername(response?.scanner_stats?.TOTAL_SCANS_BY_USERNAME);
      setValidScans(response?.scanner_stats?.VALID_SCANS);
    });
    getActiveHours();
  };

  const getActiveHours = async () => {
    await fetch(API_URL+'getActiveHours', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: userContext.auth,
      },
      body: JSON.stringify({
        username: userContext.accountName,
        date: formattedDate(),
      })
    }).then((response:any) => response.json())
    .then((response)=>{
      console.log('active minutes worked is ', response.minutes);
      setActiveHours( ((response?.minutes) / 60));
    })
  };

  const updateScanReport = (destination: string, scanReportId: number) => {
    fetch(API_URL + 'updateScanReport', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: userContext.auth,
      },
      body: JSON.stringify({
        fields: {
          destination: destination,
          destination_reassigned: true,
        },
        scan_report_id: scanReportId,
        record_id: results[0]._id,
      }),
    })
      .then((response) => response.json())
      .then((_) => {
        setLastUpdatedScanReportId(lastScanReportId);
        enqueueSnackbar('Scan report updated', { variant: 'success' });
      })
      .catch((error) => {
        Log(2, error);
        enqueueSnackbar(
          'Failed to update scan report. Please check your internet and try again.',
          { variant: 'error' }
        );
      });
  };

  const handleFunctionalBarcodes = (localQuery: string): Promise<boolean> => {
    let gotBarcode = false;

    return fetch(API_URL + 'getDestinations', {
      method: 'GET',
      headers: {
        Authorization: userContext.auth,
      },
    })
      .then((response) => response.json())
      .then((response: { destinations: any[] }) => {
        let dest = '';
        response.destinations.forEach((destination: any) => {
          if (localQuery === destination.destination_barcode) {
            dest = destination.destination_name;
            let fakeResult = [
              {
                _source: {
                  ASIN: lastBarcode,
                  DESTINATION: dest,
                  TITLE: lastBarcode,
                  LOAD_NUMBER: userContext.selectedLoad,
                },
              },
            ];
            if (lastScanReportId > 0) {
              if (lastUpdatedScanReportId === lastScanReportId) {
                reportSorterSearch(
                  lastBarcode,
                  lastScanReportResults.map((result: any) => {
                    result._source.DESTINATION = dest;
                    return result;
                  }),
                  false
                );
              } else {
                updateScanReport(dest, lastScanReportId);
              }
              fakeResult[0]._source.TITLE = results[0]._source.TITLE;
            } else {
              reportSorterSearch(lastBarcode, fakeResult, true);
            }
            setResults(fakeResult);
            setSearchText(lastBarcode);
            searchBox.current.select();
            gotBarcode = true;
          }
        });

        if (gotBarcode) return true;
        let setValue = localQuery.replace(dest, '');
        setSearchText(setValue);
        if (setValue.length > 3 && setValue.substring(0, 3) !== 'MI-') {
          setLastBarcode(setValue);
        }
        return false;
      })
      .catch((error) => {
        Log(2, error.message);
        return false;
      });

    // Object.keys(FUNCTIONAL_BARCODES).forEach((key: string) => {
    //   let dest = "";
    //   if (localQuery.includes(FUNCTIONAL_BARCODES[key])) {
    //     switch (FUNCTIONAL_BARCODES[key]) {
    //       case FUNCTIONAL_BARCODES.MISSING_BF_BLUE:
    //         dest = "BLACK FRIDAYS (BLUE)";
    //         break;
    //       case FUNCTIONAL_BARCODES.MISSING_BF_GREEN:
    //         dest = "BLACK FRIDAYS (GREEN)";
    //         break;
    //       case FUNCTIONAL_BARCODES.MISSING_BF_YELLOW:
    //         dest = "BLACK FRIDAYS (YELLOW)";
    //         break;
    //       case FUNCTIONAL_BARCODES.MISSING_RGT:
    //         if (!localQuery.includes(FUNCTIONAL_BARCODES.MISSING_RGT_FBA)) {
    //           dest = "RGT";
    //         } else {
    //           dest = "RGT-FBA";
    //         }
    //         break;
    //       case FUNCTIONAL_BARCODES.MISSING_RGT_FBA:
    //         dest = "RGT-FBA";
    //         break;
    //       case FUNCTIONAL_BARCODES.MISSING_VENTAS:
    //         dest = "VENTAS";
    //         break;
    //       case FUNCTIONAL_BARCODES.MISSING_CLOTHING:
    //         dest = "CLOTHING";
    //         break;
    //     }
    //     if (
    //       dest === "RGT" ||
    //       FUNCTIONAL_BARCODES[key] !== FUNCTIONAL_BARCODES.MISSING_RGT
    //     ) {
    //       let fakeResult = [
    //         {
    //           _source: {
    //             ASIN: lastBarcode,
    //             DESTINATION: dest,
    //             TITLE: lastBarcode,
    //             LOAD_NUMBER: userContext.selectedLoad,
    //           },
    //         },
    //       ];
    //       reportSorterSearch(lastBarcode, fakeResult, true);
    //       setResults(fakeResult);
    //     }
    //     if (dest.length <= 0) {
    //       enqueueSnackbar("Failed to find destination for item " + lastBarcode, {
    //         variant: "error",
    //       });
    //     }
    //     setSearchText(lastBarcode);
    //     gotBarcode = true;
    //   }
    // });
  };

  /* Display results (render functions/helpers) */

  const setPage = (page: number) => {
    if (page === 1) {
      setQueryNext(0);
      setCurrentPage(1);
      setRefresh(true);
    } else if (page >= 2) {
      if ((page - 1) * queryCount < queryTotal - 1) {
        setQueryNext((currentQueryNext) =>
          Math.max(currentQueryNext + queryCount * (page - currentPage), 0)
        );
        setCurrentPage(page);
        setRefresh(true);
      }
    }
  };

  const onResultsPerPage = (e: any) => {
    setQueryCount(parseInt(e.target.value));
    setRefresh(true);
  };

  const getHeaderText = (text: string) => {
    if (text === 'LOAD_NUMBER') {
      text = 'LOAD_NAME';
    }
    let t = text.replace('_', ' ');
    if (text === 'upload_date'){
      t = 'DATE';
    }
    if (
      Object.keys(sortBy[1]).length > 0 &&
      Object.keys(sortBy[1])[0]
        .replace('_', ' ')
        .includes(text.replace('_', ' '))
    ) {
      if (text === 'LOAD_NUMBER' && userContext.isMobile) {
        t = 'LOAD';
      }
      if (sortBy[1][Object.keys(sortBy[1])[0]] === 'asc') {
        return t + '\u25b2';
      } else {
        return t + '\u25bc';
      }
    } else {
      if (text === 'LOAD_NUMBER' && userContext.isMobile) {
        t = 'LOAD';
      }
      return t;
    }
  };

  const listHistory = () => {
    let list: Array<any> = [];
    if (history !== undefined && history.length > 0) {
      list = history;
    }
    let items: any;
    const HISTORY_FIELDS = ['ITEM NAME', 'STATUS', 'COUNT', 'UPDATE TIME'];
    items = list.map((result: any) => (
      <tr
        key={'TR-' + result.update_time}
        style={{ borderBottom: 'thin solid black' }}
      >
        {HISTORY_FIELDS.map((field: any) => {
          switch (field) {
            case 'ITEM NAME':
              return (
                <Styles.StyledTD
                  key={HISTORY_FIELDS.indexOf(field)}
                  style={{
                    fontWeight: 'bolder',
                    fontSize: 20,
                  }}
                >
                  {(userContext.isMobile
                    ? result.item_name
                    : result.item_name) || 'NONE'}
                </Styles.StyledTD>
              );

            case 'UPDATE TIME':
              var time = new Date(result.update_time + 'UTC');
              var newTime = time.toLocaleString();
              return (
                <Styles.StyledTD key={HISTORY_FIELDS.indexOf(field)}>
                  {newTime || 'NONE'}
                </Styles.StyledTD>
              );
            case 'STATUS':
              return (
                <Styles.StyledTD key={HISTORY_FIELDS.indexOf(field)}>
                  {result.status || 'NONE'}
                </Styles.StyledTD>
              );
            default:
              return (
                <Styles.StyledTD
                  key={DISPLAY_FIELDS[userContext.userRole].indexOf(field)}
                >
                  {result.count || 'NONE'}
                </Styles.StyledTD>
              );
          }
        })}
        <Styles.StyledTD
          style={{
            display: userContext.userRole === 'novotex-sorter' ? 'none' : '',
          }}
        />
      </tr>
    ));
    if (history !== undefined && history.length > 0) {
      return (
        <table
          style={{
            borderCollapse: 'collapse',
            tableLayout: userContext.isMobile ? 'fixed' : 'auto',
            width: userContext.isMobile ? window.innerWidth : '90vw',
          }}
        >
          <thead>
            <tr>
              {HISTORY_FIELDS.map((field: any) => {
                return (
                  <Styles.StyledTH
                    key={HISTORY_FIELDS.indexOf(field)}
                    style={{
                      width: userContext.isMobile ? '35%' : '',
                      textAlign: 'match-parent',
                    }}
                  >
                    {field}
                  </Styles.StyledTH>
                );
              })}
            </tr>
          </thead>
          <tbody>{items}</tbody>
        </table>
      );
    } else {
      return (
        <p>
          {queryTotal <= 0 && !loadingSearch ? (
            'No Results'
          ) : (
            <CircularProgress />
          )}
        </p>
      );
    }
  };

  function sortDates(a:any,b:any){
    var dateA = new Date(a._source.upload_date);
    var dateB = new Date(b._source.upload_date);
    // if (sortBy[0]['upload_date'] === 'asc')
      return dateA > dateB ? -1 : 1;
    // else
    //   return dateA > dateB ? 1 : -1;
  }

  const listResults = () => {
    let list: Array<any> = [];
    if (results !== undefined && results.length > 0) {
      if (userContext.userRole !== 'NOVOTEX')
        list = results.sort(sortDates);
      else 
        list = results;
    }
    let items: any;

    items = list.map((result: any) => (
      <tr key={'TR-' + result._id} style={{ borderBottom: 'thin solid black' }}>
        {DISPLAY_FIELDS[userContext.userRole].map((field: any) => {
          switch (field) {
            case 'DESTINATION':
              if (userContext.userRole === 'novotex-sorter') {
                return (
                  <Styles.StyledTD
                    key={DISPLAY_FIELDS[userContext.userRole].indexOf(field)}
                    style={{
                      fontWeight: 'bolder',
                      fontSize: userContext.isMobile ? 30 : 50,
                      backgroundColor:
                        destinations[result._source.DESTINATION] || '#f00',
                      textAlign: 'center',
                    }}
                  >
                    {(userContext.isMobile
                      ? result._source.DESTINATION.substring(0, 7)
                      : result._source.DESTINATION) || 'NONE'}
                  </Styles.StyledTD>
                );
              } else {
                return (
                  <Styles.StyledTD
                    key={DISPLAY_FIELDS[userContext.userRole].indexOf(field)}
                    style={{
                      fontWeight: 'bolder',
                      fontSize: 20,
                      backgroundColor:
                        destinations[result._source.DESTINATION] || '#f00',
                    }}
                  >
                    {(userContext.isMobile
                      ? result._source.DESTINATION.substring(0, 7)
                      : result._source.DESTINATION) || 'NONE'}
                  </Styles.StyledTD>
                );
              }
            case 'PALLET': 
              return (
                <Styles.StyledTD
                  key={DISPLAY_FIELDS[userContext.userRole].indexOf(field)}
                >
                  {result._source[field] || userContext.pallet || 'NONE'}
                </Styles.StyledTD>
              );
            case 'UPC':
              return (
                <Styles.StyledTD
                  key={DISPLAY_FIELDS[userContext.userRole].indexOf(field)}
                >
                  {result._source[field] || 'NONE'}
                </Styles.StyledTD>
              );
            case 'TITLE':
              if (
                (userContext.userRole === ROLES.RGT ||
                  userContext.userRole === ROLES.RGT_REPORTING) &&
                result._source.AMAZON_URL
              ) {
                return (
                  <Styles.StyledTD
                    key={DISPLAY_FIELDS[userContext.userRole].indexOf(field)}
                  >
                    <a
                      href={result._source.AMAZON_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {result._source[field] || 'NONE'}
                    </a>
                  </Styles.StyledTD>
                );
              } else {
                return (
                  <Styles.StyledTD
                    key={DISPLAY_FIELDS[userContext.userRole].indexOf(field)}
                  >
                    {result._source[field] || 'NONE'}
                  </Styles.StyledTD>
                );
              }
            case 'upload_date':
              return (
                <Styles.StyledTD
                  key={DISPLAY_FIELDS[userContext.userRole].indexOf(field)}
                >
                  {result._source[field].split('T')[0] || 'NONE'}
                </Styles.StyledTD>
              );
            case 'AMAZON_PRICE':
              if (
                (userContext.userRole === ROLES.RGT ||
                  userContext.userRole === ROLES.RGT_REPORTING) &&
                result._source.ALGOPIX_AMAZON_PRICE
              ) {
                return (
                  <Styles.StyledTD
                    key={DISPLAY_FIELDS[userContext.userRole].indexOf(field)}
                  >
                    {result._source.ALGOPIX_AMAZON_PRICE || 'NONE'}
                  </Styles.StyledTD>
                );
              } else {
                return (
                  <Styles.StyledTD
                    key={DISPLAY_FIELDS[userContext.userRole].indexOf(field)}
                  >
                    {result._source[field] || 'NONE'}
                  </Styles.StyledTD>
                );
              }
            default:
              return (
                <Styles.StyledTD
                  key={DISPLAY_FIELDS[userContext.userRole].indexOf(field)}
                  style={{
                    display:
                      userContext.isMobile &&
                      !(userContext.userRole === 'novotex-sorter')
                        ? 'none'
                        : '',
                  }}
                >
                  {result._source[field] || 'NONE'}
                </Styles.StyledTD>
              );
          }
        })}
        <Styles.StyledTD
          style={{
            display: userContext.userRole === 'novotex-sorter' ? 'none' : '',
          }}
        >
          <ActionsContainer>
            <Styles.TableButton
              style={{
                padding: '10px 15px 2px 0px',
                backgroundColor: '#FFF0',
                margin: 0,
              }}
              onClick={() => {
                setViewRecord(true);
                setRecordId(result._id);
              }}
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                style={{ width: '24px', height: '24px', marginBottom: 0 }}
                color={Colors.gray}
              />
              <p style={{ fontSize: 12, marginTop: 0 }}>Details</p>
            </Styles.TableButton>

            {userContext.userRole === 'RGT' && !userContext.isMobile && (
              <FeedbackButtons
                setShowRejectFeedback={setShowRejectFeedback}
                record={result._source}
                update={refreshHistory}
                rejectFeedback = {rejectFeedback}
              />
            )}

            {userContext.userRole === ROLES.VENTAS && !userContext.isMobile && (
              <VentasFeedbackButtons
                record={result._source}
                update={refreshHistory}
              />
            )}

            <Styles.TableButton
              onClick={() => deleteRecord(result._id)}
              style={{
                display: userContext.isAdmin ? '' : 'none',
                padding: '10px 15px 2px 0px',
                backgroundColor: '#FFF0',
                margin: 0,
              }}
            >
              <FontAwesomeIcon
                icon={faTrashAlt}
                style={{ width: '24px', height: '24px', marginBottom: 0 }}
                color={Colors.red}
              />
              <p style={{ fontSize: 12, marginTop: 0 }}>Delete</p>
            </Styles.TableButton>
          </ActionsContainer>
        </Styles.StyledTD>
      </tr>
    ));

    if (results !== undefined && results.length > 0) {
      return (
        <table
          style={{
            borderCollapse: 'collapse',
            tableLayout: userContext.isMobile ? 'fixed' : 'auto',
            width: userContext.isMobile ? window.innerWidth : '90vw',
          }}
        >
          <thead>
            <tr>
              {DISPLAY_FIELDS[userContext.userRole].map((field: any) => {
                if (field === 'DESTINATION' || field === 'UPC') {
                  return (
                    <Styles.StyledTH
                      key={DISPLAY_FIELDS[userContext.userRole].indexOf(field)}
                      style={{
                        width: userContext.isMobile ? '35%' : '',
                        textAlign:
                          field === 'DESTINATION' &&
                          userContext.userRole === 'novotex-sorter'
                            ? 'center'
                            : 'match-parent',
                      }}
                      onClick={() => {
                        setSortValues(field);
                      }}
                    >
                      {getHeaderText(field)}
                    </Styles.StyledTH>
                  );
                } else {
                  return (
                    <Styles.StyledTH
                      key={DISPLAY_FIELDS[userContext.userRole].indexOf(field)}
                      style={{
                        display:
                          userContext.isMobile &&
                          !(userContext.userRole === 'novotex-sorter')
                            ? 'none'
                            : '',
                        width: field === 'TITLE' ? '35%' : '',
                      }}
                      onClick={() => {
                        setSortValues(field);
                      }}
                    >
                      {getHeaderText(field)}
                    </Styles.StyledTH>
                  );
                }
              })}
              <Styles.StyledTH
                style={{
                  fontSize: userContext.isMobile ? 16 : 20,
                  display:
                    userContext.userRole === 'novotex-sorter' ? 'none' : '',
                  width: userContext.isMobile ? '30%' : '',
                }}
              >
                ACTIONS
              </Styles.StyledTH>
            </tr>
          </thead>
          <tbody>{items}</tbody>
        </table>
      );
    } else {
      return (
        <p>
          {queryTotal <= 0 && !loadingSearch ? (
            'No Results'
          ) : (
            <CircularProgress />
          )}
        </p>
      );
    }
  };

  const getPages = () => {
    let pagesToShow: number[] = [];
    if (currentPage > 2) {
      pagesToShow.push(1);
    }
    if (currentPage > 1) {
      pagesToShow.push(currentPage - 1);
    }
    pagesToShow.push(currentPage);
    if (queryCount * currentPage < queryTotal) {
      pagesToShow.push(currentPage + 1);
    }
    if (queryCount * (currentPage + 1) < queryTotal) {
      pagesToShow.push(currentPage + 2);
    }
    if (currentPage < 3 && queryCount * (currentPage + 2) < queryTotal) {
      pagesToShow.push(currentPage + 3);
    }
    if (currentPage < 2 && queryCount * (currentPage + 3) < queryTotal) {
      pagesToShow.push(currentPage + 4);
    }
    let pages: any;
    pages = pagesToShow.map((item) => (
      <div key={pagesToShow.indexOf(item)}>
        <MuiButton
          variant="contained"
          style={{
            color: '#fff',
            backgroundColor: currentPage === item ? '#0d54ae' : Colors.button,
            margin: '5px',
          }}
          onClick={() => setPage(item)}
        >
          {item}
        </MuiButton>
        {currentPage > 2 && item === 1 && '...'}
      </div>
    ));
    return (
      <PageNavContainer
        style={{
          minWidth: userContext.isMobile
            ? PAGE_WIDTH
            : userContext.userRole === 'RGT'
            ? 0
            : 'calc(90vw + 60px)',
          maxWidth: userContext.isMobile ? PAGE_WIDTH : 'calc(90vw + 60px)',
        }}
      >
        <Styles.FlexDiv>
          <div>
            <MuiButton
              variant="contained"
              disabled={currentPage <= 1}
              style={{
                margin: '5px',
                minWidth: userContext.isMobile
                  ? (window.innerWidth - 50) / 3
                  : '',
              }}
              onClick={() => setPage(currentPage - 1)}
            >
              Previous
            </MuiButton>
          </div>
          {!userContext.isMobile && pages}
          <div>
            <MuiButton
              variant="contained"
              style={{
                margin: '5px',
                minWidth: userContext.isMobile
                  ? (window.innerWidth - 50) / 3
                  : '',
              }}
              onClick={() => setPage(currentPage + 1)}
            >
              Next
            </MuiButton>
          </div>
        </Styles.FlexDiv>
        <Styles.FlexDiv
          style={{
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Styles.Text
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            {userContext.isMobile ? 'Show' : 'Results per page'}
          </Styles.Text>
          <StyledSelect defaultValue={25} onChange={(e) => onResultsPerPage(e)}>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </StyledSelect>
        </Styles.FlexDiv>
      </PageNavContainer>
    );
  };

  return (
    <Styles.Page
      style={{
        minWidth: PAGE_WIDTH,
        maxWidth: PAGE_WIDTH,
        minHeight: PAGE_HEIGHT,
        maxHeight: PAGE_HEIGHT,
        marginTop: 0,
      }}
    >
      <Styles.Page
        style={{
          display:
            (viewRecord || viewMissingItemForm) && userContext.isMobile
              ? 'none'
              : 'flex',
          minWidth: PAGE_WIDTH,
          maxWidth: PAGE_WIDTH,
          maxHeight: PAGE_HEIGHT,
          marginTop: userContext.isMobile ? 0 : '',
        }}
      >
        {userContext.userRole === 'novotex-sorter' && (
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
              minWidth: userContext.isMobile ? PAGE_WIDTH : 'calc(90vw + 60px)',
            }}
          >
            {!userContext.isMobile && (
              <Styles.Text style={{ fontSize: 32 }}>
                Load: {userContext.selectedLoad || 'NONE'} | 
                Pallet: {userContext.pallet || 'NONE'}
              </Styles.Text>
            )}
            <Styles.FlexDiv
              style={{
                flexDirection: userContext.isMobile ? 'column' : 'row',
                alignItems: userContext.isMobile ? 'flex-start' : 'center',
              }}
            >
              <Container
                style={{
                  flexDirection: userContext.isMobile ? 'row' : 'column',
                }}
              >
                <Styles.Text style={{ fontSize: 20, margin: '0 10px' }}>
                  Positivity Rate:
                </Styles.Text>
                <Styles.Text
                  style={{
                    fontSize: 24,
                    margin: 0,
                    color: colorByPercent(
                      parseInt(positivityRate.replace('%', ''))
                    ),
                  }}
                >
                  {positivityRate || '--'}
                </Styles.Text>
              </Container>
              <Container
                style={{
                  flexDirection: userContext.isMobile ? 'row' : 'column',
                }}
              >
                <Styles.Text style={{ fontSize: 20, margin: '0 10px' }}>
                  Pieces Today:
                </Styles.Text>
                <Styles.Text
                  style={{
                    fontSize: 24,
                    margin: 0,
                  }}
                >
                  {pieceCounts.daily}
                </Styles.Text>
              </Container>
              <Container
                style={{
                  flexDirection: userContext.isMobile ? 'row' : 'column',
                }}
              >
                <Styles.Text style={{ fontSize: 20, margin: '0 10px' }}>
                  Pieces Per Hour:
                </Styles.Text>
                <Styles.Text
                  style={{
                    fontSize: 24,
                    margin: 0,
                  }}
                >
                  {pieceCounts.hourly}
                </Styles.Text>
              </Container>
            </Styles.FlexDiv>
          </div>
        )}

        <SearchBarForm
          onSubmit={(e) => {
            e.preventDefault();
            if (userContext.userRole === ROLES.NOVOTEX) {
              searchBox.current.focus();
              searchBox.current.select();
            }
          }}
          style={{
            marginBottom:
              userContext.isMobile || userContext.userRole === 'RGT'
                ? 0
                : '20px',
            marginTop: userContext.isMobile ? 0 : '',
            marginLeft: userContext.isMobile ? '' : 'calc(5vw - 30px)',
          }}
        >
          <TextField
            inputRef={searchBox}
            type="text"
            style={{
              marginRight: userContext.isMobile ? 0 : '10px',
              marginBottom: userContext.isMobile ? 0 : '',
              maxWidth: userContext.isMobile ? PAGE_WIDTH - 30 : '40vw',
              minWidth: userContext.isMobile ? PAGE_WIDTH - 30 : '40vw',
            }}
            placeholder="Search all manifests"
            autoCapitalize="none"
            autoFocus={false}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <MuiButton
            variant="contained"
            style={{
              marginRight: '10px',
              minWidth: userContext.isMobile
                ? (window.innerWidth - 50) / 3
                : '',
              minHeight:
                userContext.userRole === 'novotex-sorter' ? '64px' : '48px',
            }}
            className="button"
            type="submit"
            onClick={() => {
              initSearch(searchText);
            }}
          >
            Search
          </MuiButton>
          <MuiButton
            variant="contained"
            style={{
              marginRight: '10px',
              minWidth: userContext.isMobile
                ? (window.innerWidth - 50) / 3
                : '',
              minHeight:
                userContext.userRole === 'novotex-sorter' ? '64px' : '48px',
            }}
            className="button"
            type="submit"
            onClick={() => {
              setQuery('');
              setSearchText('');
              setQueryTotal(0);
              setResults([]);
              setCurrentPage(1);
            }}
          >
            Clear
          </MuiButton>
          {userContext.userRole === 'novotex-sorter' && (
            <MuiButton
              variant="contained"
              className="button"
              type="submit"
              style={{
                minWidth: userContext.isMobile
                  ? (window.innerWidth - 50) / 3
                  : '',
                minHeight:
                  userContext.userRole === 'novotex-sorter' ? '64px' : '48px',
              }}
              onClick={() => props.setShowLoadSelect(true)}
            >
              Change Load/Pallet
            </MuiButton>
          )}
        </SearchBarForm>
        {/*{showMissingItem && userContext.userRole === "novotex-sorter" &&*/}
        {/*  <Styles.StyledButton style={{ whiteSpace: "nowrap", flexDirection: "row", minHeight: "64px" }}*/}
        {/*                       disabled={missingItemStatus === "done"}*/}
        {/*                       onClick={() => reportSorterSearch(query, results, true)}>{missingItemStatus === "loading" ? (*/}
        {/*      <div style={{*/}
        {/*          display: "flex",*/}
        {/*          flexDirection: "row",*/}
        {/*          justifyContent: "center",*/}
        {/*          alignItems: "center",*/}
        {/*          flexWrap: "nowrap",*/}
        {/*          whiteSpace: "nowrap"*/}
        {/*      }}><FontAwesomeIcon style={{ display: "flex", margin: 0, padding: 0, marginRight: "5px" }}*/}
        {/*                          icon={faSpinner} className="fa-spin" /> <p*/}
        {/*          style={{ display: "flex", margin: 0, padding: 0 }}>Submitting</p>*/}
        {/*      </div>) : "Report Missing Item"}</Styles.StyledButton>}*/}
        <MainTableContainer
          pageWidth={PAGE_WIDTH}
          pageHeight={PAGE_HEIGHT}
          style={{
            maxWidth: userContext.isMobile ? PAGE_WIDTH : '90vw',
            minWidth: userContext.isMobile ? PAGE_WIDTH : '90vw',
            overflowX: userContext.isMobile ? 'hidden' : 'scroll',
            padding: userContext.isMobile ? 0 : '30px',
            marginBottom: userContext.userRole === 'RGT' ? '2px' : '10px',
          }}
        >
          {listResults()}
        </MainTableContainer>

        <Styles.FlexDiv
          style={{
            flexDirection: 'column',
            flex: '1',
            minWidth: userContext.isMobile ? PAGE_WIDTH : 'calc(90vw + 60px)',
            minHeight: 0,
          }}
        >
          <Styles.FlexDiv
            style={{
              display:
                userContext.userRole === 'RGT' && !userContext.isMobile
                  ? 'flex'
                  : 'flex',
              maxWidth: 'calc(90vw + 60px)',
              flexDirection: userContext.userRole === 'RGT' ? 'row' : 'column',
            }}
          >
            <Styles.FlexDiv
              style={{
                display:
                  userContext.userRole === 'novotex-sorter' ? 'none' : 'flex',
                flex: '1',
                minWidth: userContext.isMobile
                  ? PAGE_WIDTH
                  : userContext.userRole === 'RGT'
                  ? 'calc(47vw + 30px)'
                  : 'calc(90vw + 60px)',
                minHeight: 0,
              }}
            >
              {loadingSearch ? <CircularProgress /> : getPages()}
            </Styles.FlexDiv>

            <Styles.FlexDiv
              style={{
                display:
                  userContext.userRole === ROLES.VENTAS ? 'flex' : 'none',
                flex: '1',
                minHeight: 0,
                minWidth: userContext.isMobile
                  ? PAGE_WIDTH
                  : userContext.userRole === ROLES.VENTAS
                  ? 0
                  : 'calc(90vw + 60px)',
                maxWidth: userContext.isMobile
                  ? PAGE_WIDTH
                  : 'calc(90vw + 60px)',
                justifyContent: 'flex-end',
                marginTop: 4,
              }}
            >
              <Tooltip
                title={
                  <span>
                    Product was not found or does not
                    <br />
                    match the item above and{' '}
                    <b>
                      does
                      <br />
                      not
                    </b>{' '}
                    meet our listing requirements.
                  </span>
                }
                arrow
              >
                <MuiButton
                  variant="contained"
                  color="error"
                  onClick={(_) => {
                    submitVentasFeedback(
                      'Reject-V',
                      1,
                      userContext.accountName,
                      userContext.auth,
                      {
                        ASIN: query,
                        UPC: query,
                        RETAIL_PRICE: null,
                        LOAD_NAME: 'Ventas Unlisted',
                        SOURCE: null,
                        TITLE: query,
                        DESTINATION: null,
                      },
                      enqueueSnackbar,
                      true
                    );
                    setResults([
                      {
                        _source: {
                          ASIN: query,
                          UPC: query,
                          RETAIL_PRICE: '',
                          LOAD_NUMBER: 'Ventas Unlisted',
                          SOURCE: '',
                          TITLE: query,
                          DESTINATION: 'VENTAS',
                        },
                      },
                      ...results,
                    ]);
                  }}
                >
                  Reject Unlisted Item
                </MuiButton>
              </Tooltip>
            </Styles.FlexDiv>

            <Styles.FlexDiv
              style={{
                display: userContext.userRole.toUpperCase().includes('RGT')
                  ? 'flex'
                  : 'none',
                flex: '1',
                minHeight: 0,
                minWidth: userContext.isMobile
                  ? PAGE_WIDTH
                  : userContext.userRole === 'RGT'
                  ? 0
                  : 'calc(90vw + 60px)',
                maxWidth: userContext.isMobile
                  ? PAGE_WIDTH
                  : 'calc(90vw + 60px)',
                justifyContent:
                  userContext.userRole === 'RGT' ? 'flex-end' : '',
                marginTop: userContext.userRole === 'RGT' ? '4px' : '',
              }}
            >
              <Tooltip
                title={
                  <span>
                    Product was not found or does not
                    <br />
                    match the item above but{' '}
                    <b>
                      does
                      <br />
                      meet
                    </b>{' '}
                    our listing requirements.
                  </span>
                }
                arrow
              >
                <MuiButton
                  variant="contained"
                  color="success"
                  style={{
                    marginRight: '20px',
                    marginLeft: '5px',
                  }}
                  onClick={(_) => {
                    setIsRejectingRGTItem(false);
                    setViewMissingItemForm(true);
                  }}
                >
                  Accept Unlisted Item
                </MuiButton>
              </Tooltip>
              <Tooltip
                title={
                  <span>
                    Product was not found or does not
                    <br />
                    match the item above and{' '}
                    <b>
                      does
                      <br />
                      not
                    </b>{' '}
                    meet our listing requirements.
                  </span>
                }
                arrow
              >
                <MuiButton
                  variant="contained"
                  color="error"
                  onClick={(_) => {
                    setIsRejectingRGTItem(true);
                    setViewMissingItemForm(true);
                  }}
                >
                  Reject Unlisted Item
                </MuiButton>
              </Tooltip>
            </Styles.FlexDiv>
          </Styles.FlexDiv>
          <Styles.FlexDiv
            style={{
              display:
                userContext.userRole.toUpperCase() === 'RGT' ? 'flex' : 'none',
              flex: '1',
              flexDirection: 'column',
              minHeight: '25vh',
              maxHeight: '25vh',
              minWidth: userContext.isMobile ? PAGE_WIDTH : 'calc(90vw + 60px)',
              maxWidth: userContext.isMobile ? PAGE_WIDTH : 'calc(90vw + 60px)',
            }}
          >
            <MainTableContainer
              pageWidth={PAGE_WIDTH}
              pageHeight={PAGE_HEIGHT}
              style={{
                maxWidth: userContext.isMobile ? PAGE_WIDTH : '90vw',
                minWidth: userContext.isMobile ? PAGE_WIDTH : '90vw',
                overflowX: userContext.isMobile ? 'hidden' : 'scroll',
                padding: userContext.isMobile ? 0 : '30px',
              }}
            >
              {listHistory()}
            </MainTableContainer>
          </Styles.FlexDiv>
        </Styles.FlexDiv>
        {showRejectFeedback && (
          <StyledDiv
            style={{
              marginTop: PAGE_HEIGHT * 0.1,
            }}
          >
            <RejectReason
              showRejectFeedback = {showRejectFeedback}
              setShowRejectFeedback = {setShowRejectFeedback}
              setRejectFeedback = {setRejectFeedback}
            />
          </StyledDiv>
        )}
        {viewMissingItemForm && (
          <StyledDiv
            style={{
              marginTop: PAGE_HEIGHT * 0.1,
            }}
          >
            <RGTMissingItemForm
              isRejecting={isRejectingRGTItem}
              setShowMissingItemForm={(e: boolean) => setViewMissingItemForm(e)}
              showMissingItemForm={viewMissingItemForm}
            />
          </StyledDiv>
        )}
        <div
          onClick={() => setViewMissingItemForm(false)}
          style={{
            display: viewMissingItemForm && !userContext.isMobile ? '' : 'none',
            position: 'absolute',
            left: 0,
            top: window.innerHeight - PAGE_HEIGHT,
            width: PAGE_WIDTH,
            height: PAGE_HEIGHT,
            backgroundColor: '#000C',
            zIndex: 50,
          }}
        />

        <StyledDiv
          style={{
            display: viewRecord && !userContext.isMobile ? '' : 'none',
          }}
        >
          <Record
            setViewRecord={(e: any) => setViewRecord(e)}
            viewRecord={viewRecord}
            id={recordId}
            refresh={() => setRefresh(true)}
          />
        </StyledDiv>

        <div
          onClick={() => setViewRecord(false)}
          style={{
            display: viewRecord && !userContext.isMobile ? '' : 'none',
            top: window.innerHeight - PAGE_HEIGHT,
            position: 'absolute',
            left: 0,
            width: PAGE_WIDTH,
            height: PAGE_HEIGHT,
            backgroundColor: '#000c',
            zIndex: 50,
          }}
        />
      </Styles.Page>

      {viewMissingItemForm && userContext.isMobile && (
        <StyledDiv
          style={{
            maxWidth: PAGE_WIDTH,
            maxHeight: PAGE_HEIGHT,
          }}
        >
          <RGTMissingItemForm
            isRejecting={isRejectingRGTItem}
            setShowMissingItemForm={(e: boolean) => setViewMissingItemForm(e)}
            showMissingItemForm={viewMissingItemForm}
          />
        </StyledDiv>
      )}

      <StyledDiv
        style={{
          display: viewRecord && userContext.isMobile ? '' : 'none',
          position: 'initial',
        }}
      >
        <Record
          setViewRecord={(e: any) => setViewRecord(e)}
          viewRecord={viewRecord}
          id={recordId}
          refresh={() => setRefresh(true)}
        />
      </StyledDiv>
    </Styles.Page>
  );
};

export default Search;
