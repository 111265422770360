import Styles, { Colors } from '../Styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faFileAlt,
  faTable,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import React, { useContext, useEffect, useState } from 'react';
import {
  LoadInfoRow,
  LOADS_DISPLAY_FIELDS,
  PAGE_WIDTH,
  UserContext,
} from '../utilities/DataTypes';
import { API_URL } from '../env';
import Log from './Logger';
import { Link } from 'react-router-dom';
import {
  colorByPercent,
  useIsMounted,
} from '../utilities/Helpers';
import styled from 'styled-components';
import {
  Checkbox,
  CircularProgress,
} from '@mui/material';
import LoadForm from './LoadForm';
import { deleteLoad } from 'utilities/APICalls';

const StyledDiv = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
const StyledTable = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  width: ${LOADS_DISPLAY_FIELDS.length * 200}px;
`;

const getLoadInfo = (
  startDate: string,
  endDate: string,
  status: string,
  auth: string
): Promise<Array<LoadInfoRow>> => {
  return fetch(API_URL + 'getLoadReportsList', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: auth,
    },
    body: JSON.stringify({
      start_date: startDate,
      end_date: endDate,
      status: status,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      Log(0, `getLoadInfo response: ${JSON.stringify(response.table_data[0])}`);
      if (response.table_data.length > 0) {
        return response.table_data;
      } else {
        return [{ id: 0 }];
      }
    })
    .catch((err) => {
      Log(0, err);
      return [{ id: -1 }];
    });
};

const prepareFormInfo = (row: LoadInfoRow, loadId: number) => {
  return {
    load_id: loadId,
    load_name: row.LOAD_NAME,
    load_source: row.STORE_NAME,
    load_status: row.STATUS,
    load_start_date: row.DATE_STARTED,
    load_complete_date: row.DATE_FINISHED,
    load_sort_fuzzy_match: row.MATCH_FIRST_8,
    load_unmanifested: row.UNMANIFESTED,
    load_cost: row.LOAD_COST
  }
}

interface LoadsTableProps {
  startDate: string;
  endDate: string;
  status: string;
  setSelectedLoad: any;
  setLoadRows: any;
  deleteRows: any;
  setDeleteRows: any;
  setLoadsHasSelection: any;
}

const LoadsTable = (props: LoadsTableProps) => {
  const [tableData, setTableData]: [Array<LoadInfoRow>, any] = useState([]);
  const [selectedId, setSelectedId]: [number, any] = useState(-1);
  const [refreshLoop, setRefreshLoop] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const isMounted = useIsMounted();
  const userContext = useContext(UserContext);
  const [deleteLast, setDeleteLast] = useState({isSet: false, idx: -99});
  const [shiftHeld, setShiftHeld] = useState(false);
  const [selectedRows, setSelectedRows]: [Array<number>, any] = useState([]);
  const [selected, setSelected] = useState(-1);
  const [lastSelected, setLastSelected] = useState(-1);

  function downHandler({key}:any) {
    if (key === 'Shift'){
      setShiftHeld(true);
    }
  }
  function upHandler({key}:any) {
    if (key === 'Shift'){
      setShiftHeld(false);
    }
  }
  useEffect(()=> {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  //deletes loads by id in selectedRows state array, triggered by props.setDeleteRows set true in Loads.tsx
  const deleteRange = async() => {
    if (selectedRows.length === 0){
      return;
    }
    const deleteRows:any = getTableInfoByIdx();
    let rownames = '';
    deleteRows.forEach((row:any) => {
      rownames += `${row.name}\n`;
    })
    const confirm = window.confirm(`Are you sure you want to permanently delete these loads?\nLoads to delete:\n${rownames}`);
      if (confirm){
        const promiseArray = deleteRows.map( async (row:any)=>{
          return await deleteLoad(row.id, userContext.auth);
        }); 
        await Promise.all(promiseArray);
        window.location.reload();
    }
  }
  
  const getTableInfoByIdx = () => {
    let tableIds:any = [];
    selectedRows.forEach((row:any)=>{
      tableIds.push({id: tableData[row].id, name: tableData[row].LOAD_NAME});
    })
    tableIds.sort((a:any,b:any)=> b.id-a.id);
    return tableIds;
  }

  //when checkbox is clicked, keeps track of selected, lastselected, and shift selects
  const checkBoxHandler = (idx:any) => {
    setSelected(idx); // curr select
    if (shiftHeld){ //shift select and click on checkbox
      setDeleteLast({isSet: true, idx: idx}); //save last object to delete
    }
    if(!selectedRows.includes(idx)){ // selected row not in selected rows
    setSelectedRows([...selectedRows, idx]); //add row to selected rows
    setLastSelected(selected); //set previous select to curr select
    }
    if(selectedRows.includes(idx)){ // unselect row
      let arr = [...selectedRows].filter(item => item !== idx).sort(); //remove from selected rows
      setSelectedRows([...arr]); //save new selected rows to state array
    }
  }

  //activated on shift select, adds rows from prev select to shift selected row
  const setSelectedRange = () => {
    let indices:any = [...selectedRows];
    let start = Math.min(lastSelected, deleteLast.idx);
    let end = Math.max(lastSelected, deleteLast.idx);
    for (var i = start; i <= end; i++){
      indices.push(i);
    }
    const selectedindices = new Set<number>(indices); //remove duplicates
    indices = Array.from(selectedindices).sort();
    setLastSelected(deleteLast.idx);
    setSelectedRows([...indices]);
  }
  
  //parent Loads.tsx has 'Delete Loads' button, abled/disabled based on LoadsHasSelection
  useEffect(()=>{
    if (selectedRows.length!==0){
      props.setLoadsHasSelection(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  //if shiftSelect and prev select are valid, add prev select to shift select to selected rows
  useEffect(()=>{
    if (deleteLast.idx >= 0 && lastSelected >= 0){
      setSelectedRange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteLast, lastSelected]);

  //if parent DeleteLoads button pressed, props.deleteRows is true and triggers deleteRows
  useEffect(() => {
    if (props.deleteRows){
      deleteRange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.deleteRows]);


  //if filters in parent (dates or status) changed, get new table data for loads
  useEffect(() => {
    setTableData([]);
    getLoadInfo(
      props.startDate,
      props.endDate,
      props.status,
      userContext.auth
    ).then((response: Array<LoadInfoRow>) => {
      if (isMounted()) {
        setTableData(response);
        props.setLoadRows(response);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.startDate,
    props.endDate,
    props.status,
    userContext.auth,
    isMounted,
  ]);

  useEffect(() => {
    if (isMounted() && tableData.length > 0 && refreshLoop) {
      setRefreshLoop(false);
      getLoadInfo(
        props.startDate,
        props.endDate,
        props.status,
        userContext.auth
      ).then((response: Array<LoadInfoRow>) => {
        if (isMounted()) {
          setTableData(response);
          props.setLoadRows(response);
        }
      });
    }
  }, [isMounted, props, userContext.auth, tableData, refreshLoop]);

  useEffect(() => {
    if (!refreshLoop && isMounted()) {
      setTimeout(() => setRefreshLoop(true), 60000);
    }
  }, [refreshLoop, isMounted]);

  let itemRows: Array<any>; // formatted as table rows

  if (
    tableData.length > 0 &&
    tableData[0].hasOwnProperty('id') &&
    tableData[0].id > 0
  ) {
    itemRows = tableData.map((result: any) => (
      <tr
        key={tableData.indexOf(result)}
      >
        {LOADS_DISPLAY_FIELDS.map((field: string) => 
        {
          if (field === 'ACTIONS') {
            return (
              <Styles.StyledTD
                key={LOADS_DISPLAY_FIELDS.indexOf(field)}
                style={{
                  paddingRight: 0,
                  marginRight: 0,
                  minWidth: 0,
                  maxWidth: 0,
                }}
              >
                {showForm && selectedId === result.id && (
                  <LoadForm
                    setShowForm={(visible: boolean) => setShowForm(visible)}
                    showForm={showForm}
                    loadInfo = {prepareFormInfo(result, result.id)}
                    isEditing = {true}
                    refreshTable = {() => setRefreshLoop(true)}
                    loadsTable = {tableData}
                  />
                )}
                <StyledDiv>
                  {/* Reports */}
                  <Link to={'/loads?load_name=' + result.LOAD_NAME}>
                    <Styles.TableButton
                      style={{
                        padding: '10px 15px 2px 0px',
                        backgroundColor: '#FFF0',
                        margin: 0,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faFileAlt}
                        style={{
                          width: '24px',
                          height: '24px',
                          marginBottom: 0,
                        }}
                        color={Colors.button}
                      />
                      <Styles.Text style={{ fontSize: 12, marginTop: 0 }}>
                        Reports
                      </Styles.Text>
                    </Styles.TableButton>
                  </Link>

                  {/* View */}
                  <Link
                    to={
                      '/loads?load_name=' +
                      result.LOAD_NAME +
                      '&view_load_full=true'
                    }
                  >
                    <Styles.TableButton
                      // onClick={() => {props.setSelectedLoad(result.LOAD_NAME)}}
                      style={{
                        padding: '10px 15px 2px 0px',
                        backgroundColor: '#FFF0',
                        margin: 0,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTable}
                        style={{
                          width: '24px',
                          height: '24px',
                          marginBottom: 0,
                        }}
                        color={Colors.text}
                      />
                      <Styles.Text style={{ fontSize: 12, marginTop: 0 }}>
                        View
                      </Styles.Text>
                    </Styles.TableButton>
                  </Link>

                  {/* Edit */}
                  <Styles.TableButton
                    onClick={() => {
                      setSelectedId(result.id);
                      setShowForm(true);
                    }}
                    style={{
                      padding: '10px 15px 2px 0px',
                      backgroundColor: '#FFF0',
                      margin: 0,
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faEdit}
                      style={{
                        width: '24px',
                        height: '24px',
                        marginLeft: '4px',
                        marginBottom: 0,
                      }}
                      color={Colors.gray}
                    />
                    <Styles.Text style={{ fontSize: 12, marginTop: 0 }}>
                      Edit
                    </Styles.Text>
                  </Styles.TableButton>

                  {/* Delete */}
                  <Styles.TableButton
                    onClick={() => {
                      if (
                        window.confirm(
                          'Are you sure you want to delete this load? Once deleted, it cannot be recovered.'
                        )
                      ) {
                        deleteLoad(result.id, userContext.auth).then((_) => {
                          if (isMounted()) {
                            setTableData([]);
                            getLoadInfo(
                              props.startDate,
                              props.endDate,
                              props.status,
                              userContext.auth
                            ).then((response: Array<LoadInfoRow>) => {
                              setTableData(response);
                            });
                          }
                        });
                      }
                    }}
                    style={{
                      padding: '10px 15px 2px 0px',
                      backgroundColor: '#FFF0',
                      margin: 0,
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      style={{ width: '24px', height: '24px', marginBottom: 0 }}
                      color={Colors.red}
                    />
                    <Styles.Text style={{ fontSize: 12, marginTop: 0 }}>
                      Delete
                    </Styles.Text>
                  </Styles.TableButton>
                </StyledDiv>
              </Styles.StyledTD>
            );
          } else {
            switch (field) {
              case 'CHECKED':
                return (
                  <Styles.StyledTD key={LOADS_DISPLAY_FIELDS.indexOf(field)}>
                    <Checkbox checked={selectedRows.includes(tableData.indexOf(result))} onClick={()=>checkBoxHandler(tableData.indexOf(result))}/>
                  </Styles.StyledTD>
                );
              case 'MATCH_FIRST_8':
              case 'UNMANIFESTED':
                return (
                  <Styles.StyledTD key={LOADS_DISPLAY_FIELDS.indexOf(field)}>
                    <Checkbox checked={result[field] ?? ''} disabled />
                  </Styles.StyledTD>
                );
              case 'LOAD_COST':
                return (
                  <Styles.StyledTD key={LOADS_DISPLAY_FIELDS.indexOf(field)}>
                    {result[field] ? result[field] + '%' : 'NONE'}
                  </Styles.StyledTD>
                );
              default:
                return (
                  <Styles.StyledTD
                    key={LOADS_DISPLAY_FIELDS.indexOf(field)}
                    style={{
                      color:
                        field === 'POSITIVITY_RATE'
                          ? colorByPercent(
                              parseInt((result[field] || '0%').replace('%', ''))
                            )
                          : Colors.text,
                    }}

                  >
                    {result[field] || 'NONE'}
                  </Styles.StyledTD>
                );
            }
          }
        })}
      </tr>
    ));
    let items: Array<any> = [];
    for (let i = 0; i < tableData.length; i++) {
      items.push(itemRows[i]);
      items.push(
        <tr key={i + 'RATES'} style={{ borderBottom: 'thin solid black' }}>
          <Styles.StyledTD />
          <Styles.StyledTD
            style={{ paddingLeft: 0 }}
            colSpan={tableData.length - 1}
          >
            <table>
              <tbody>
                <tr>
                  {tableData[i].INDIVIDUAL_RATES.map(
                    (rate: Object, index: number) => {
                      return (
                        <Styles.StyledTD
                          style={{ paddingRight: 15 }}
                          key={index}
                        >
                          {Object.keys(rate)[0]}
                          <br />
                          <span
                            style={{
                              color: colorByPercent(
                                parseInt(
                                  Object.values(rate)[0].replace('%', '')
                                )
                              ),
                            }}
                          >
                            {Object.values(rate)[0]}
                          </span>
                        </Styles.StyledTD>
                      );
                    }
                  )}
                </tr>
              </tbody>
            </table>
          </Styles.StyledTD>
        </tr>
      );
    }

    return (
      <StyledTable>
        <thead>
          <tr>
            {LOADS_DISPLAY_FIELDS.map((field: string) => {
              return (
                <Styles.StyledTH
                  style={{
                    width: Math.max(
                      200,
                      (PAGE_WIDTH * 0.89) / LOADS_DISPLAY_FIELDS.length
                    ),
                    boxSizing: 'border-box',
                  }}
                  key={LOADS_DISPLAY_FIELDS.indexOf(field)}
                >
                  {field.replace(/_/g, ' ')}
                </Styles.StyledTH>
              );
            })}
          </tr>
        </thead>
        <tbody>{items}</tbody>
      </StyledTable>
    );
  } else if (tableData.length > 0 && tableData[0].id < 0) {
    return (
      <Styles.Text style={{ color: 'red' }}>Error loading data.</Styles.Text>
    );
  } else if (tableData.length > 0 && tableData[0].id === 0) {
    return <Styles.Text>No results.</Styles.Text>;
  } else {
    return (
      <Styles.FlexDiv
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '20px',
        }}
      >
        <CircularProgress sx={{ mx: 'auto' }} />
      </Styles.FlexDiv>
    );
  }
};

export default LoadsTable;
