const DEBUG = true;

//TODO: Maybe send to SEQ later?
const Log = (level: number = 0, message: any) => {
  if (DEBUG) {
    switch (level) {
      case 0:
        console.log(message); // Used for general useful debug information.
        break;
      case 1:
        console.warn(message); // Used for important debug information.
        break;
      case 2:
        console.error(message); // Used for when everything goes wrong, and you need to know it.
        break;
    }
  } else if (level === 2) {
    console.log('%c ERROR: ', message, 'color: red; font-weight: bold;');
  }
};

export default Log;
