import React, { useContext, useEffect, useState } from 'react';
import { DB_URL, ES_INDEX } from '../env';
import Log from './Logger';
import {
  ALL_FIELDS,
  NUMBER_FIELDS,
  PAGE_HEIGHT,
  PAGE_WIDTH,
  SizeProps,
  UserContext,
} from '../utilities/DataTypes';
import Styles, { Colors, MuiButton } from '../Styles';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CircularProgress, TextField } from '@mui/material';

const StyledSelect = styled.select`
  display: block;
  font-size: 16px;
  line-height: 1.3;
  margin-left: 5px;
  padding: 10px 0 10px 10px;
  border-radius: 4px;
`;

const TableContainer = styled.div<SizeProps>`
  min-width: ${(props) => props.pageWidth * 0.98}px;
  max-width: ${(props) => props.pageWidth * 0.98}px;
  min-height: ${(props) => props.pageHeight * 0.7}px;
  max-height: ${(props) => props.pageHeight * 0.7}px;
  overflow: scroll;
`;
const SearchContainer = styled.div<SizeProps>`
  display: flex;
  flex: 1;
  margin-bottom: 20px;
  min-width: ${(props) => props.pageWidth * 0.98}px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const SearchForm = styled.form`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const StyledTable = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
`;

const getResultCount = (
  query: string,
  auth: string,
  load: string
): Promise<any> => {
  return fetch(DB_URL + ES_INDEX + '/_count', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
    },
    body: JSON.stringify({
      query: {
        bool: {
          must: [
            {
              query_string: {
                query:
                  query.length > 0
                    ? `"${query.replace(/[-+=&|!(){}[\]^"~*?:\\/><]/, '')}"`
                    : '*',
              },
            },
          ],
          filter: {
            term: { 'LOAD_NUMBER.keyword': load },
          },
        },
      },
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    });
};

const getSortValues = (field: string, sortBy: Array<any>): Array<any> => {
  let sort: Array<any> = [
    {},
    {},
    { upload_date: { order: 'desc' } },
    { 'id.keyword': 'asc' },
  ];
  if (field.length > 0) {
    let order = 'asc';
    if (
      Object.keys(sortBy[1]).length > 0 &&
      Object.keys(sortBy[1])[0] === field + '.keyword' &&
      sortBy[1][field + '.keyword'] === 'asc'
    ) {
      order = 'desc';
    }
    sort[1] = { [field + '.keyword']: order };
    if (NUMBER_FIELDS.includes(field)) {
      sort[0] = {
        _script: {
          type: 'number',
          script: `
                        try {
                            Float.parseFloat(doc['${field}.keyword'].value.replace('$','').replace('%','').replace(',',''))
                        } catch (NumberFormatException ex) {
                            0.0f
                        }
                    `,
          order: order,
        },
      };
    }
  }
  return sort;
};

const getHeaderText = (text: string, sortBy: any) => {
  if (text === 'LOAD_NUMBER') {
    text = 'LOAD_NAME';
  }
  let t = text.replace(/_/g, ' ');
  if (
    Object.keys(sortBy[1]).length > 0 &&
    Object.keys(sortBy[1])[0].replace(/_/g, ' ').replace('.keyword', '') ===
      text.replace(/_/g, ' ')
  ) {
    if (sortBy[1][Object.keys(sortBy[1])[0]] === 'asc') {
      return t + '\u25b2';
    } else {
      return t + '\u25bc';
    }
  } else {
    return t;
  }
};

const getLoadItems = (
  query: string,
  queryNext: number,
  queryCount: number,
  sortBy: any,
  auth: any,
  load: string
): Promise<any> => {
  let body: { [k: string]: any } = {
    from: queryNext,
    size: queryCount,
    sort: sortBy,
  };
  body.query = {
    bool: {
      must: [
        {
          query_string: {
            query:
              query.length > 0
                ? `"${query.replace(/[-+=&|!(){}[\]^"~*?:\\/><]/, '')}"`
                : '*',
          },
        },
      ],
      filter: {
        term: { 'LOAD_NUMBER.keyword': load },
      },
    },
  };

  return fetch(DB_URL + ES_INDEX + '/_search', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.hits !== undefined && response.hits.hits.length > 0) {
        return response.hits.hits;
        // setSearchAfter(response.hits.hits[response.hits.hits.length - 1].sort);
      } else {
        return null;
      }
    })
    .catch((error) => {
      Log(2, `getLoadItemsError: ${error}`);
    });
};

const getLoadItemsAfter = (
  searchAfter: any,
  query: string,
  queryNext: number,
  queryCount: number,
  sortBy: any,
  auth: any,
  load: string
): Promise<any> => {
  let body: { [k: string]: any } = {
    search_after: searchAfter,
    size: queryCount,
    sort: sortBy,
  };
  body.query = {
    bool: {
      must: [
        {
          query_string: {
            query: `*${query}*`,
          },
        },
      ],
      filter: {
        term: { 'LOAD_NUMBER.keyword': load },
      },
    },
  };

  return fetch(DB_URL + ES_INDEX + '/_search', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.hits.hits.length > 0) {
        return response.hits.hits;
        // setSearchAfter(response.hits.hits[response.hits.hits.length - 1].sort);
      } else {
        return null;
      }
    })
    .catch((error) => {
      Log(2, `getLoadItemsAfterError: ${error}`);
    });
};

const getPages = (
  currentPage: number,
  queryCount: number,
  queryTotal: number
) => {
  let pagesToShow: number[] = [];
  if (currentPage > 2) {
    pagesToShow.push(1);
  }
  if (currentPage > 1) {
    pagesToShow.push(currentPage - 1);
  }
  pagesToShow.push(currentPage);
  if (currentPage * queryCount + queryCount <= queryTotal) {
    pagesToShow.push(currentPage + 1);
  }
  if (currentPage * queryCount + queryCount * 2 <= queryTotal) {
    pagesToShow.push(currentPage + 2);
  }
  if (currentPage < 3) {
    pagesToShow.push(currentPage + 3);
  }
  if (currentPage < 2) {
    pagesToShow.push(currentPage + 4);
  }
  return pagesToShow;
};

const getTableWidth = () => {
  let total = 0;
  ALL_FIELDS.forEach((field) => {
    total += getFieldWidth(field);
  });
  return total;
};

const getFieldWidth = (field: string) => {
  switch (field) {
    case 'TITLE':
    case 'notes':
    case 'MANIFEST_URL':
    case 'OTHER_BARCODES':
      return 1000;
    case 'AMAZON_URL':
      return 600;
    case 'RGT_PERCENTAGE':
    case 'RETAIL_PRICE':
    case 'QUANTITY':
    case 'RGT_PRICE':
    case 'RGT_RATE':
      return 200;
    case 'ALGOPIX_WALMART_PRICE':
    case 'ALGOPIX_AMAZON_PRICE':
    case 'ALGOPIX_EBAY_PRICE':
      return 250;
    default:
      return 300;
  }
};

interface LoadViewFullProps {
  load: string;
}

const LoadViewFull = (props: LoadViewFullProps) => {
  const [query, setQuery] = useState('');
  const [searchText, setSearchText] = useState('');
  const [queryCount, setQueryCount] = useState(100);
  const [queryTotal, setQueryTotal] = useState(-1);
  const [queryNext, setQueryNext] = useState(0);
  const [searchAfter, setSearchAfter]: [any, any] = useState();
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy]: [any, any] = useState(getSortValues('', []));
  const userContext = useContext(UserContext);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [tableData, setTableData]: [any, any] = useState([]);
  const [pagesToShow, setPagesToShow]: [Array<any>, any] = useState([]);

  useEffect(() => {
    if (
      !loadingSearch &&
      (query.length > 0 || queryTotal < 0) &&
      userContext.auth.length > 0
    ) {
      setLoadingSearch(true);
      setTableData([]);
      setQueryTotal(-1);
      setQueryNext(0);
      setCurrentPage(1);
      getResultCount(query, userContext.auth, props.load).then((response) => {
        setQueryTotal(response.count);
        // noinspection DuplicatedCode
        if (queryCount + queryNext >= 10000) {
          getLoadItemsAfter(
            searchAfter,
            query,
            queryNext,
            queryCount,
            sortBy,
            userContext.auth,
            props.load
          ).then((response) => {
            if (response !== null) {
              setTableData(response);
              setSearchAfter(response[response.length - 1].sort);
              setLoadingSearch(false);
              setQuery('');
            }
          });
        } else if (response.count > 0) {
          getLoadItems(
            query,
            queryNext,
            queryCount,
            sortBy,
            userContext.auth,
            props.load
          ).then((response) => {
            if (response !== null) {
              setTableData(response);
              setSearchAfter(response[response.length - 1].sort);
              setLoadingSearch(false);
              setQuery('');
            }
          });
        } else {
          setLoadingSearch(false);
        }
      });
    } else {
      setLoadingSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.load,
    query,
    queryCount,
    queryNext,
    queryTotal,
    userContext.auth,
    userContext.userRole,
    sortBy,
    searchAfter,
  ]);

  useEffect(() => {
    // noinspection DuplicatedCode
    if (!loadingSearch && userContext.auth.length > 0) {
      setLoadingSearch(true);
      if (queryCount + queryNext >= 10000) {
        getLoadItemsAfter(
          searchAfter,
          query,
          queryNext,
          queryCount,
          sortBy,
          userContext.auth,
          props.load
        ).then((response) => {
          if (response !== null) {
            setTableData(response);
            setSearchAfter(response[response.length - 1].sort);
            setLoadingSearch(false);
            setQuery('');
          }
        });
      } else {
        getLoadItems(
          query,
          queryNext,
          queryCount,
          sortBy,
          userContext.auth,
          props.load
        ).then((response) => {
          if (response !== null) {
            setTableData(response);
            setSearchAfter(response[response.length - 1].sort);
            setLoadingSearch(false);
            setQuery('');
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sortBy,
    queryCount,
    props.load,
    userContext.auth,
    userContext.userRole,
    queryNext,
  ]);

  useEffect(() => {
    if (queryTotal > 0) {
      setPagesToShow(getPages(currentPage, queryCount, queryTotal));
      if (
        currentPage * queryCount + queryCount > queryTotal &&
        currentPage > 1 &&
        Math.floor(queryTotal / queryCount) > 0
      ) {
        setCurrentPage(Math.floor(queryTotal / queryCount));
      }
    }
  }, [currentPage, queryCount, queryTotal]);

  useEffect(() => {
    if (page !== currentPage) {
      if (page === 1) {
        setQueryNext(0);
        setCurrentPage(1);
      } else if (page >= 2) {
        if (page * queryCount < queryTotal) {
          setQueryNext((currentQueryNext) =>
            Math.max(currentQueryNext + queryCount * (page - currentPage), 0)
          );
          setCurrentPage(page);
        }
      }
    }
  }, [page, queryCount, queryTotal, currentPage]);

  return (
    <Styles.Page
      style={{
        minWidth: PAGE_WIDTH,
        minHeight: PAGE_HEIGHT * 0.9,
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      {/*  Search Bar  */}
      <SearchContainer pageWidth={PAGE_WIDTH} pageHeight={PAGE_HEIGHT}>
        <SearchForm onSubmit={(e: any) => e.preventDefault()}>
          <TextField
            type="text"
            style={{
              marginRight: '10px',
              maxWidth: '40vw',
              minWidth: '40vw',
              height: 'auto',
            }}
            placeholder="Search this load"
            autoCapitalize="none"
            autoFocus={false}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <MuiButton
            variant="contained"
            style={{ marginRight: '10px', minHeight: '48px' }}
            className="button"
            type="submit"
            onClick={() => {
              setQuery(searchText);
              setQueryTotal(-1);
            }}
          >
            Search
          </MuiButton>
          <MuiButton
            variant="contained"
            style={{ marginRight: '10px', minHeight: '48px' }}
            className="button"
            type="submit"
            onClick={() => {
              setQuery('');
              setSearchText('');
              setQueryTotal(-1);
              setTableData([]);
              setPage(1);
            }}
          >
            Clear
          </MuiButton>
        </SearchForm>
        <Link style={{ textDecoration: 'none' }} to={'/loads'}>
          <MuiButton variant="contained">Back</MuiButton>
        </Link>
      </SearchContainer>

      {/*  Table  */}
      <TableContainer pageWidth={PAGE_WIDTH} pageHeight={PAGE_HEIGHT}>
        <StyledTable
          style={{
            width: getTableWidth(),
          }}
        >
          <thead>
            <tr>
              {ALL_FIELDS.map((field: any) => {
                return (
                  <Styles.StyledTH
                    key={ALL_FIELDS.indexOf(field)}
                    style={{ width: getFieldWidth(field) }}
                    onClick={() => {
                      setSortBy(getSortValues(field, sortBy));
                    }}
                  >
                    {getHeaderText(field, sortBy)}
                  </Styles.StyledTH>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableData.map((result: any) => (
              <tr key={result._id} style={{ borderBottom: 'thin solid black' }}>
                {ALL_FIELDS.map((field: any) => (
                  <Styles.StyledTD
                    key={ALL_FIELDS.indexOf(field)}
                    style={{ width: getFieldWidth(field) }}
                  >
                    {(result._source[field] || 'NONE').includes('http') ? (
                      <a
                        href={result._source[field]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {result._source[field]}
                      </a>
                    ) : (
                      result._source[field] || 'NONE'
                    )}
                  </Styles.StyledTD>
                ))}
              </tr>
            ))}
          </tbody>
        </StyledTable>
        {(loadingSearch || tableData.length <= 0 || queryTotal <= 0) && (
          <div
            style={{
              minHeight: PAGE_HEIGHT * 0.7,
              minWidth: PAGE_WIDTH * 0.98,
            }}
          >
            <p>
              {queryTotal <= 0 && !loadingSearch ? (
                'No Results'
              ) : (
                <CircularProgress sx={{ mx: 'auto' }} />
              )}
            </p>
          </div>
        )}
      </TableContainer>

      {/*  Pagination  */}
      <Styles.FlexDiv
        style={{
          flex: '1',
          minHeight: 0,
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          minWidth: PAGE_WIDTH * 0.98,
          maxWidth: PAGE_WIDTH * 0.98,
        }}
      >
        <Styles.FlexDiv>
          <div>
            <MuiButton
              variant="contained"
              disabled={currentPage <= 1}
              style={{ margin: '5px' }}
              onClick={() => setPage(currentPage - 1)}
            >
              Previous
            </MuiButton>
          </div>
          {pagesToShow.map((item: any) => (
            <div key={pagesToShow.indexOf(item)}>
              <MuiButton
                variant="contained"
                style={{
                  backgroundColor:
                    currentPage === item ? '#0d54ae' : Colors.button,
                  margin: '5px',
                }}
                onClick={() => setPage(item)}
              >
                {item}
              </MuiButton>
              {currentPage > 2 && item === 1 && '...'}
            </div>
          ))}
          <div>
            <MuiButton
              variant="contained"
              disabled={currentPage * queryCount + queryCount > queryTotal}
              style={{ margin: '5px' }}
              onClick={() => setPage(currentPage + 1)}
            >
              Next
            </MuiButton>
          </div>
        </Styles.FlexDiv>
        <Styles.FlexDiv
          style={{
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Styles.Text style={{ whiteSpace: 'nowrap' }}>
            Results per page
          </Styles.Text>
          <StyledSelect
            defaultValue={100}
            onChange={(e) => setQueryCount(parseInt(e.target.value))}
          >
            <option value={100}>100</option>
            <option value={250}>250</option>
            <option value={500}>500</option>
            <option value={1000}>1000</option>
          </StyledSelect>
        </Styles.FlexDiv>
      </Styles.FlexDiv>
    </Styles.Page>
  );
};

export default LoadViewFull;
