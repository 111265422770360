import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@mui/material';
import Log from 'components/Logger';
import UserPopup from 'components/UserPopup';
import { BUILD_ENV, DB_URL } from 'env';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Styles, { MuiButton } from '../Styles';
import {
  PAGE_HEIGHT,
  PAGE_WIDTH,
  SizeProps,
  UserContext,
} from '../utilities/DataTypes';

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const UserContainer = styled.div`
  flex: 1;
  align-items: center;
  align-self: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;
const StyledUl = styled.div<SizeProps>`
  display: flex;
  flex: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  max-height: ${(props) => props.pageHeight * 0.8}px;
  overflow-y: scroll;
`;

const StyledUserPopup = styled.div<SizeProps>`
  position: absolute;
  z-index: 100;
  margin-top: ${(props) => props.pageHeight * 0.1}px;
`;

const getUsers = (auth: any, setUserList: any) => {
  fetch(DB_URL + '_security/user/', {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      let users: Array<any> = [];
      for (const user in response) {
        if (
          user !== 'admin' &&
          response[user].metadata?.test === (BUILD_ENV !== 'PROD')
        ) {
          // Only show users from this environment, and don't show the superadmin.
          users.push({ name: user, role: response[user].roles[0] });
        }
      }
      const sortAlphaNum = (a: any, b: any) =>
        a.name.localeCompare(b.name, undefined, { numeric: true });
      users = users.sort(sortAlphaNum);
      console.log(users);
      setUserList(users);
    })
    .catch((error) => {
      Log(2, 'getUsers error: ' + error);
    });
};

const Users = () => {
  const [userList, setUserList]: [any, any] = useState([]);
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [formUsername, setFormUsername] = useState('');
  const [formPassword, setFormPassword] = useState('');
  const [formUserType, setFormUserType] = useState('');
  const [waitOnUser, setWaitOnUser] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const userContext = useContext(UserContext);

  useEffect(() => {
    getUsers(userContext.auth, setUserList);
  }, [userContext.auth]);

  const deleteUser = (username: string) => {
    if (
      window.confirm(
        'Are you sure you want to delete the user ' + username + '?'
      )
    ) {
      setWaitOnUser(true);
      fetch(DB_URL + '_security/user/' + username, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: userContext.auth,
        },
      })
        .then((response) => response.json())
        .then(() => {
          setWaitOnUser(false);
          getUsers(userContext.auth, setUserList);
        })
        .catch((error) => {
          Log(2, 'deleteUser error: ' + error);
        });
    }
  };

  const createUser = (username: string, password: string, userType: string) => {
    let roles = [userType];
    setWaitOnUser(true);
    fetch(DB_URL + '_security/user/' + username, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: userContext.auth,
      },
      body: JSON.stringify({
        password: password,
        roles: roles,
        metadata: {
          admin: userType.toLowerCase().includes('admin'),
          reporting: userType.toLowerCase().includes('reporting'),
          test: BUILD_ENV !== 'PROD',
        },
      }),
    })
      .then((response) => response.json())
      .then(() => {
        setIsCreatingUser(false);
        setWaitOnUser(false);
        getUsers(userContext.auth, setUserList);
      })
      .catch((error) => {
        Log(2, 'createUser error: ' + error);
      });
  };

  const updateUser = (
    username: string,
    password?: string,
    userType?: string
  ) => {
    let requestBody: { [k: string]: any } = {};
    if (password !== undefined) {
      requestBody.password = password;
    }
    if (userType !== undefined) {
      requestBody.roles = [userType];
    }
    requestBody.metadata = {
      admin: userType?.toLowerCase().includes('admin'),
      reporting: userType?.toLowerCase().includes('reporting'),
      test: BUILD_ENV !== 'PROD',
    };

    setWaitOnUser(true);

    fetch(DB_URL + '_security/user/' + username, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: userContext.auth,
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then(() => {
        setIsCreatingUser(false);
        setWaitOnUser(false);
        getUsers(userContext.auth, setUserList);
      })
      .catch((error) => {
        Log(2, 'updateUser error: ' + error);
      });
  };

  const submitUser = (
    username: string,
    password: string,
    userType: string,
    isUpdate: boolean
  ) => {
    if (isUpdate) {
      if (password.length > 0 && userType.length > 0) {
        updateUser(username, password, userType);
        return;
      }
      if (userType.length > 0) {
        updateUser(username, undefined, userType);
        return;
      }
    } else {
      createUser(username, password, userType);
    }
  };

  const displayUsers = () => {
    if (userList.length > 0) {
      let userMap = userList.map((item: any) => (
        <Styles.StyledLI
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
            margin: 0,
            borderBottom: 'thin solid #222',
            maxWidth: userContext.isMobile ? '70vw' : '30vw',
            minWidth: userContext.isMobile ? '70vw' : '30vw',
            whiteSpace: 'pre-line',
            wordBreak: 'break-all',
            wordWrap: 'break-word',
          }}
          key={userList.indexOf(item)}
        >
          <Styles.Text>
            {item.name +
              ' - ' +
              item.role
                .replace('-', ' ')
                .replace(/(^\w)|(\s\w)/g, (match: string) =>
                  match.toUpperCase()
                )}
          </Styles.Text>
          <MuiButton
            variant="contained"
            style={{
              flexWrap: 'nowrap',
              whiteSpace: 'nowrap',
              marginLeft: 'auto',
            }}
            disabled={waitOnUser}
            onClick={() => {
              setIsCreatingUser(true);
              setFormUsername(item.name);
              setFormUserType(item.role);
              setIsUpdate(true);
            }}
          >
            Edit
          </MuiButton>
          <MuiButton
            variant="contained"
            color="error"
            style={{
              flexWrap: 'nowrap',
              whiteSpace: 'nowrap',
              marginLeft: '5px',
            }}
            disabled={item.name === userContext.accountName || waitOnUser}
            onClick={() => deleteUser(item.name)}
          >
            Delete
          </MuiButton>
        </Styles.StyledLI>
      ));

      return (
        <StyledUl pageWidth={PAGE_WIDTH} pageHeight={PAGE_HEIGHT}>
          {userMap}
        </StyledUl>
      );
    } else {
      return (
        <StyledDiv>
          <CircularProgress sx={{ mx: 'auto' }} />
        </StyledDiv>
      );
    }
  };

  return (
    <Styles.Page>
      <Styles.PageHeader>Users</Styles.PageHeader>
      {isCreatingUser && (
        <StyledUserPopup pageWidth={PAGE_WIDTH} pageHeight={PAGE_HEIGHT}>
          <UserPopup
            username={formUsername}
            password={formPassword}
            userType={formUserType}
            setUsername={(e: string) => setFormUsername(e)}
            setPassword={(e: string) => setFormPassword(e)}
            setIsAdmin={(e: string) => setFormUserType(e)}
            setViewForm={(e: boolean) => setIsCreatingUser(e)}
            submitUser={(
              username: string,
              password: string,
              userType: string,
              isUpdate: boolean
            ) => submitUser(username, password, userType, isUpdate)}
            waitOnUser={waitOnUser}
            isUpdate={isUpdate}
            showForm={isCreatingUser}
          />
        </StyledUserPopup>
      )}
      <UserContainer
        style={{
          display: userContext.isMobile && isCreatingUser ? 'none' : 'flex',
        }}
      >
        {displayUsers()}
        <MuiButton
          variant="contained"
          style={{ display: isCreatingUser ? 'none' : '', marginTop: '5px' }}
          disabled={waitOnUser}
          onClick={() => {
            setIsCreatingUser(true);
            setIsUpdate(false);
          }}
        >
          <FontAwesomeIcon icon={faPlus} style={{ marginRight: '10px' }} />
          Add User
        </MuiButton>
        <Styles.Text style={{ display: waitOnUser ? 'flex' : 'none' }}>
          Please wait...
        </Styles.Text>
      </UserContainer>
    </Styles.Page>
  );
};

export default Users;
