import React, { useEffect, useState } from 'react';
import Styles, { Colors, MuiButton } from '../Styles';
import styled from 'styled-components';
import Autocomplete from '@mui/material/Autocomplete';
import {
  CircularProgress,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@mui/material';
import {
  addIndividualProduct,
  getSkuCategoryCounter,
  updateSkuCategoryCounter,
} from 'utilities/APICalls';
import { useSnackbar } from 'notistack';
import Log from './Logger';
import { API_URL } from 'env';
import { getIndividualCategoriesMap, IndividualProductDefaultRetailPercent } from 'utilities/DataTypes';

const FormRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin-bottom: 10px;
  font-family: Open Sans, sans-serif;
`;

interface IndividualProductFormProps {
  visible: boolean;
  setVisible: Function;
  defaultProductInfo?: any;
  setReloadProducts: Function;
}

const onSubmit = (
  productCategory: string,
  individualProductInfo: any
): Promise<boolean> => {
  return addIndividualProduct(individualProductInfo)
    .then((success) => {
      if (success) {
        return updateSkuCategoryCounter(productCategory)
          .then((_) => {
            return true;
          })
          .catch((err) => {
            Log(2, err);
            return false;
          });
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });
};

const IndividualProductForm = (props: IndividualProductFormProps) => {
  const [productCategory, setProductCategory] = useState(''); // this is the SKU prefix (format: GMI-XX-####)

  const [individualProductInfo, setIndividualProductInfo] = useState({
    pallet_individual_product_sku: props.defaultProductInfo.pallet_individual_product_sku ?? '',
    pallet_individual_product_title: props.defaultProductInfo.pallet_individual_product_title ?? '',
    pallet_individual_product_retail_price: props.defaultProductInfo.pallet_individual_product_retail_price ?? '',
    pallet_individual_product_retail_percentage: props.defaultProductInfo.pallet_individual_product_retail_percentage ?? null,
    pallet_individual_product_cost: props.defaultProductInfo.pallet_individual_product_cost ?? null,
    pallet_individual_product_quantity: ''
  });

  const [loading, setLoading] = useState(false);
  const [showMistakes, setShowMistakes] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [individualProductCategoriesArray, setIndividualProductCategoriesArray]: [any[], any] = useState([]);
  const [skuMap, setSkuMap] = useState(new Map<string, string>()); // maps (category:key, sku:value) pairs
  const [retailPercentMap, setRetailPercentMap] = useState(new Map<string|undefined,number|undefined>());

  useEffect( () => {
    const IndividualProductCategories = getIndividualCategoriesMap();
    setIndividualProductCategoriesArray(IndividualProductCategories.individualProductCategoriesArray ?? individualProductCategoriesArray);
    setSkuMap(IndividualProductCategories.skuMap ?? skuMap);
    setRetailPercentMap(IndividualProductCategories.retailPercentMap ?? retailPercentMap);
  }, []);

  const generateSku = (productCategory: string): Promise<string> => {
    return getSkuCategoryCounter(productCategory!)
      .then((nextSku) => {
        return skuMap.get(productCategory) + '-' + nextSku;
      })
      .catch((error) => {
        throw error;
      });
  };

  return (
    <Dialog open={props.visible} maxWidth={'sm'} fullWidth>
      <DialogTitle>Add Individual Product</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress sx={{ mx: 'auto' }} />
        ) : (
          <div>
            <FormRow>
              <label>Product Category</label>
              <Autocomplete
                id="product-category"
                options={individualProductCategoriesArray}
                renderInput={(params) => <TextField {...params} />}
                onChange={(event, newInputValue: string) => {
                  setProductCategory(newInputValue);
                  setIndividualProductInfo({
                    ...individualProductInfo,
                    pallet_individual_product_sku: skuMap.get(newInputValue) + '-XXXX', // set SKU to SKU prefix plus placeholder number value
                    pallet_individual_product_retail_percentage: retailPercentMap.get(skuMap.get(newInputValue)) ?? IndividualProductDefaultRetailPercent //set retail percent to category value for percent if it's defined, leave null otherwise
                  });
                }}
                disableClearable
              />
              {showMistakes &&
                (productCategory.length ||
                  0) <= 0 && (
                  <Styles.Text style={{ margin: 0, color: Colors.red }}>
                    Product Category required
                  </Styles.Text>
                )}
            </FormRow>
            <FormRow>
              <label>Product SKU</label>
              <TextField
                disabled
                value={individualProductInfo?.pallet_individual_product_sku}
              />
            </FormRow>
            <FormRow>
              <label>Product Name</label>
              <TextField
                value={individualProductInfo?.pallet_individual_product_title}
                onChange={(e) =>
                  setIndividualProductInfo({
                    ...individualProductInfo,
                    pallet_individual_product_title: e.target.value,
                  })
                }
              />
              {showMistakes &&
                (individualProductInfo.pallet_individual_product_title?.toString()
                  .length || 0) <= 0 && (
                  <Styles.Text style={{ margin: 0, color: Colors.red }}>
                    Product Name required
                  </Styles.Text>
                )}
            </FormRow>
            <FormRow>
              <label>Retail Price</label>
              <TextField
                placeholder="$0.00"
                value={
                  '$' +
                  individualProductInfo.pallet_individual_product_retail_price
                }
                onChange={(e) =>
                  setIndividualProductInfo({
                    ...individualProductInfo,
                    pallet_individual_product_retail_price:
                      e.target.value.replace(/[^\d.]/g, ''),
                  })
                }
              />
              {showMistakes &&
                (individualProductInfo.pallet_individual_product_quantity === '' ? true : parseInt(individualProductInfo.pallet_individual_product_quantity) <= 0)
                  && (
                  <Styles.Text style={{ margin: 0, color: Colors.red }}>
                    Retail Price required
                  </Styles.Text>
                )}
            </FormRow>
            <FormRow>
              <label>Quantity</label>
              <TextField
              placeholder="1"
              value={
                individualProductInfo.pallet_individual_product_quantity
              }
              onChange={(e)=>{
                setIndividualProductInfo({
                  ...individualProductInfo,
                  pallet_individual_product_quantity: e.target.value
                })
              }}
              />
              {showMistakes &&
              (individualProductInfo.pallet_individual_product_quantity?.toString().length || 0 ) <= 0 
                   && (
                  <Styles.Text style={{ margin: 0, color: Colors.red }}>
                    Quantity Required
                  </Styles.Text>
                )}
            </FormRow>
          </div>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          m: 2,
        }}
      >
        <MuiButton
          variant="contained"
          color="info"
          onClick={(_) => props.setVisible(false)}
        >
          Cancel
        </MuiButton>
        <MuiButton
          variant="contained"
          style={{ marginLeft: '20px' }}
          onClick={(_) => {
            generateSku(productCategory).then((value) => {
              let info = {
                ...individualProductInfo,
                pallet_individual_product_sku: value,
              };
              //console.log(info.pallet_individual_product_quantity)
              if (
                (productCategory.length || 0) <= 0 ||
                (info?.pallet_individual_product_sku?.length || 0) <= 0 ||
                (info?.pallet_individual_product_title?.length || 0) <= 0 ||
                (info?.pallet_individual_product_retail_price?.length || 0) <= 0 ||
                (individualProductInfo.pallet_individual_product_quantity === '' ? true : parseInt(individualProductInfo.pallet_individual_product_quantity) <= 0)
              ) {
                setShowMistakes(true);
              } else {
                setLoading(true);
                let new_info = {
                  ...info,
                  pallet_individual_product_quantity: parseInt(info.pallet_individual_product_quantity),
                }
                onSubmit(productCategory, new_info).then((success) => {
                  if (success) {
                    enqueueSnackbar('Added Product', { variant: 'success' });
                    props.setReloadProducts();
                    props.setVisible(false);
                  }
                  else {
                    generateSku(productCategory).then((value) => {
                      let info = {
                        ...individualProductInfo,
                        pallet_individual_product_sku: value,
                        pallet_individual_product_quantity: individualProductInfo.pallet_individual_product_quantity,
                      };
                      if (
                        (productCategory.length || 0) <= 0 ||
                        (info?.pallet_individual_product_sku?.length || 0) <= 0 ||
                        (info?.pallet_individual_product_title?.length || 0) <= 0 ||
                        (info?.pallet_individual_product_retail_price?.length || 0) <= 0 ||
                        (individualProductInfo.pallet_individual_product_quantity === '' ? true : parseInt(individualProductInfo.pallet_individual_product_quantity) <= 0)
                      ) {
                        setShowMistakes(true);
                      } else {
                        setLoading(true);
                        let newer_info = {
                          ...info,
                          pallet_individual_product_quantity: parseInt(info.pallet_individual_product_quantity),
                        }
                        console.log( 'newer info: ' + newer_info)
                        onSubmit(productCategory, newer_info).then((success) => {
                          if (success) {
                            enqueueSnackbar('Added Product', { variant: 'success' });
                            props.setReloadProducts();
                            props.setVisible(false);
                          }
                          else {
                            enqueueSnackbar('Failed to add Product', { variant: 'error' });
                            setLoading(false);
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }}
        >
          Save
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
};

export default IndividualProductForm;
