/* CONSTANTS */

import { createContext } from 'react';
import { getIndividualProductCategories, getPalletCategories, getPalletLotCategories } from 'components/Categories/CategoriesAPICalls';

export let PAGE_HEIGHT = window.innerHeight * 0.95 - 30;
export let PAGE_WIDTH = window.innerWidth;

export const THIRTY_DAYS_AGO = new Date(
  new Date().setDate(new Date().getDate() - 30)
);
export const SEVEN_DAYS_AGO = new Date(
  new Date().setDate(new Date().getDate() - 7)
);

export const IndividualProductDefaultRetailPercent: number = 0.35;

export const PalletDefaultRetailPercent: number = 0.35;

export const PalletLotDefaultRetailPercent: number = 0.35;

export const QrCodeUrl: string = "https://quickchart.io/qr?size=200x200&ecLevel=H&margin=2&text=";

export const UserContext = createContext({
  userRole: '',
  accountName: '',
  loggedIn: false,
  isAdmin: false,
  isReporting: false,
  isMobile: false,
  auth: '',
  selectedLoad: '',
  pallet: ''
});

export const setPageSize = (height: number, width: number) => {
  PAGE_HEIGHT = height;
  PAGE_WIDTH = width;
};

export const ALL_FIELDS: Array<string> = [
  'LOAD_NUMBER',
  'SOURCE',
  'TYPE',
  'BOL',
  'TITLE',
  'UPC',
  'LPN',
  'ASIN',
  'OTHER_BARCODES',
  'RGT_PERCENTAGE',
  'RETAIL_PRICE',
  'QUANTITY',
  'RGT_PRICE',
  'DESTINATION',
  'ALGOPIX_WALMART_PRICE',
  'ALGOPIX_AMAZON_PRICE',
  'ALGOPIX_EBAY_PRICE',
  'ALGOPIX_UPC',
  'ALGOPIX_EAN',
  'RGT_RATE',
  'MANIFEST_URL',
  'AMAZON_URL',
  'COST_PER_PIECE',
  'notes',
];

export const NUMBER_FIELDS: Array<string> = [
  'RGT_PERCENTAGE',
  'RETAIL_PRICE',
  'QUANTITY',
  'RGT_PRICE',
  'ALGOPIX_WALMART_PRICE',
  'ALGOPIX_AMAZON_PRICE',
  'ALGOPIX_EBAY_PRICE',
  'RGT_RATE',
  'COST_PER_PIECE',
];

// List of fields to display in main search table, by role.
export const DISPLAY_FIELDS: { [k: string]: string[] } = {
  RGT: [
    'DESTINATION',
    'ASIN',
    'TITLE',
    'PALLET',
    'upload_date',
    'UPC',
    'LOAD_NUMBER',
    'RETAIL_PRICE',
    'AMAZON_PRICE',
  ],
  ventas: [
    'DESTINATION',
    'ASIN',
    'TITLE',
    'PALLET',
    'UPC',
    'LOAD_NUMBER',
    'RETAIL_PRICE',
    'upload_date',
  ],
  'novotex-sorter': ['DESTINATION', 'TITLE', 'LOAD_NUMBER', 'PALLET'],
  admin: [
    'DESTINATION',
    'UPC',
    'TITLE',
    'PALLET',
    'ASIN',
    'SOURCE',
    'RETAIL_PRICE',
    'RGT_PRICE',
    'upload_date',
  ],
  'rgt-admin': [
    'DESTINATION',
    'UPC',
    'TITLE',
    'PALLET',
    'ASIN',
    'SOURCE',
    'RETAIL_PRICE',
    'RGT_PRICE',
    'upload_date',
  ],
  'novotex-admin': [
    'DESTINATION',
    'UPC',
    'TITLE',
    'PALLET',
    'ASIN',
    'SOURCE',
    'RETAIL_PRICE',
    'RGT_PRICE',
    'upload_date',
  ],
  'rgt-reporting': [
    'DESTINATION',
    'UPC',
    'TITLE',
    'PALLET',
    'ASIN',
    'RETAIL_PRICE',
    'AMAZON_PRICE',
    'RGT_PRICE',
    'upload_date',
  ],
  'novotex-reporting': [
    'DESTINATION',
    'UPC',
    'TITLE',
    'PALLET',
    'ASIN',
    'SOURCE',
    'RETAIL_PRICE',
    'RGT_PRICE',
    'upload_date',
  ],
  'novotex-pallets': [
    'DESTINATION',
    'UPC',
    'TITLE',
    'PALLET',
    'ASIN',
    'SOURCE',
    'RETAIL_PRICE',
    'RGT_PRICE',
    'upload_date',
  ],
  'destination-manager': [
    'DESTINATION',
    'UPC',
    'TITLE',
    'PALLET',
    'ASIN',
    'SOURCE',
    'RETAIL_PRICE',
    'RGT_PRICE',
    'upload_date',
  ],
};

// List of all fields we display in details page.
export const SUPPORTED_RECORD_FIELDS = [
  'DESTINATION',
  'BOL',
  'LOAD_NUMBER',
  'SOURCE',
  'TYPE',
  'TITLE',
  'UPC',
  'LPN',
  'ASIN',
  'OTHER_BARCODES',
  'RGT_PERCENTAGE',
  'RETAIL_PRICE',
  'QUANTITY',
  'RGT_PRICE',
  'ALGOPIX_WALMART_PRICE',
  'ALGOPIX_AMAZON_PRICE',
  'ALGOPIX_EBAY_PRICE',
  'ALGOPIX_UPC',
  'ALGOPIX_EAN',
  'RGT_RATE',
  'MANIFEST_URL',
  'AMAZON_URL',
  'COST_PER_PIECE',
  'notes',
];

// List of fields to display in loads table.
export const LOADS_DISPLAY_FIELDS: Array<string> = [
  'CHECKED',
  'LOAD_NAME',
  'POSITIVITY_RATE',
  'STORE_NAME',
  'STATUS',
  'DATE_STARTED',
  'DATE_FINISHED',
  'MATCH_FIRST_8',
  'UNMANIFESTED',
  'LOAD_COST',
  'ACTIONS',
];

// List of fields to map during import.
export const SUPPORTED_IMPORT_FIELDS: Array<string> = [
  'LOAD_NUMBER',
  'SOURCE',
  'TYPE',
  'BOL',
  'TITLE',
  'UPC',
  'LPN',
  'ASIN',
  'OTHER_BARCODES',
  'RGT_PERCENTAGE',
  'RETAIL_PRICE',
  'QUANTITY',
  'RGT_PRICE',
  'DESTINATION',
  'ALGOPIX_WALMART_PRICE',
  'ALGOPIX_AMAZON_PRICE',
  'ALGOPIX_EBAY_PRICE',
  'ALGOPIX_UPC',
  'ALGOPIX_EAN',
  'RGT_RATE',
  'MANIFEST_URL',
  'AMAZON_URL',
  'COST_PER_PIECE',
  'notes',
];

export const PALLET_SEARCH_FIELDS: string[] = [
  'LOAD_NUMBER',
  'TITLE',
  'RETAIL_PRICE',
  'RGT_PERCENTAGE',
  'COST_PER_PIECE'
];

export const PALLET_PRODUCT_FIELDS: { [k: string]: string } = {
  'LOAD NUMBER': 'pallet_product_source_load_number',
  TITLE: 'pallet_product_title',
  'RETAIL PRICE': 'pallet_product_retail_price',
  '% OF RETAIL': 'pallet_product_retail_percentage',
  'COST PER UNIT': 'pallet_product_cost_per_unit',
  'QUANTITY': 'pallet_product_quantity',
};

export const PALLET_INDIVIDUAL_PRODUCT_FIELDS: { [k: string]: string } = {
  'PRODUCT SKU': 'pallet_individual_product_sku',
  TITLE: 'pallet_individual_product_title',
  'RETAIL PRICE': 'pallet_individual_product_retail_price',
  '% OF RETAIL': 'pallet_individual_product_retail_percentage',
  COST: 'pallet_individual_product_cost',
  'SYNCED': 'pallet_individual_product_synced_to_shopify',
  'QUANTITY': 'pallet_individual_product_quantity',
};

export const PALLET_PRODUCT_PRINT_FIELDS: { [k: string]: string } = {
  TITLE: 'pallet_product_title',
  'RETAIL PRICE': 'pallet_product_retail_price',
};

export const ROLES: { [k: string]: string } = {
  RGT: 'RGT',
  NOVOTEX: 'novotex-sorter',
  VENTAS: 'ventas',
  RGT_REPORTING: 'rgt-reporting',
  NOVOTEX_REPORTING: 'novotex-reporting',
  RGT_ADMIN: 'rgt-admin',
  NOVOTEX_ADMIN: 'novotex-admin',
  NOVOTEX_PALLETS: 'novotex-pallets',
  DESTINATION_MANAGER: 'destination-manager',
  ADMIN: 'admin',
} as const;

export const PrinterTypes = ['Pallet', 'Individual Product'];

/* TYPES */

export interface SizeProps {
  pageWidth: number;
  pageHeight: number;
}

export type LoadInfoRow = {
  id: number;
  LOAD_NAME: string;
  POSITIVITY_RATE: string;
  STORE_NAME: string;
  STATUS: string;
  DATE_STARTED: string;
  DATE_FINISHED: string;
  MATCH_FIRST_8: boolean;
  UNMANIFESTED: boolean;
  INDIVIDUAL_RATES: any;
  LOAD_COST: number;
};

export type PalletStats = {
  avg_cost_per_unit: number;
  total_price: number;
  total_products: number;
  avg_percent_of_retail: number;
  avg_retail_price_per_piece: number;
  total_retail: number;
};

export type PalletProduct = {
  pallet_product_id: number;
  pallet_sku: string;
  pallet_product_title: string;
  pallet_product_retail_price: number;
  pallet_product_retail_percentage: number;
  pallet_product_cost_per_unit: number;
  pallet_product_quantity: number;
};

export type PalletItemRow = {
  palletProductTitle: string;
  palletProductQuantity: number;
  palletProductPrice: number;
};

export type FeedbackType =
  | 'Accept'
  | 'Reject-D'
  | 'Reject-V'
  | 'Reject-C'
  | 'Reject-W'
  | 'Reject-F';

export type Printer = {
  printerName: string;
  computerName: string;
  printerId: number;
};

export type CancelablePromise<T> = {
  promise: Promise<T>;
  cancel: Function;
};

export type ShopifyItem = {
  productName: string;
  productSku: string;
  productPrice: number;
  productPieces: number;
};

export const getIndividualCategoriesMap = () => {
  let skuMap = new Map <string, string>();
  let retailPercentMap = new Map <string, number>();
  let IndividualProductCategoriesArray: any[] = [];
  try {
  getIndividualProductCategories().then((IndividualProductCategories: any) => {
    IndividualProductCategories.forEach( (item:any) => {
      if (!skuMap.has(item.category))
        skuMap.set(item.category, item.sku);
        IndividualProductCategoriesArray.push(item.category);
      if (!retailPercentMap.has(item.sku))
        retailPercentMap.set(item.sku, item.retail_percentage);
    });
  });
  return {skuMap: skuMap, retailPercentMap: retailPercentMap, individualProductCategoriesArray: IndividualProductCategoriesArray};
  }
  catch (error) {
    console.error('Error generating individual category map: ', error);
    return {skuMap: null, retailPercentMap: null, individualProductCategoriesArray: null}
  }
};

export const getPalletMap = async () => {
  let skuMap = new Map <string, string>();
  let PalletCategoryMap = new Map <string, string>();
  let PalletLotCategoryMap = new Map <string, string>();
  let retailPercentMap = new Map <string, number>();
  let PalletCategoriesArray: any[] = [];
  let PalletLotCategoriesArray: any[] = [];
  try {
    await getPalletCategories().then((PalletCategories:any)=>{
      PalletCategories.forEach( (item:any) => {
        if (!skuMap.has(item.sku))
          skuMap.set(item.sku, item.category);
        if (!PalletCategoryMap.has(item.category))
          PalletCategoryMap.set(item.category, item.sku)
          PalletCategoriesArray.push(item.category);
        if (!retailPercentMap.has(item.sku))
          retailPercentMap.set(item.sku, item.retail_percentage);
      })
    });
    await getPalletLotCategories().then((PalletLotCategories:any)=> {
      PalletLotCategories.forEach ( (item:any)=> {
        if (!skuMap.has(item.sku))
          skuMap.set(item.sku, item.category);
        if (!PalletLotCategoryMap.has(item.category))
          PalletLotCategoryMap.set(item.category, item.sku)
          PalletLotCategoriesArray.push(item.category);
        if (!retailPercentMap.has(item.sku))
          retailPercentMap.set(item.sku, item.retail_percentage);
      })
    })
    return {
      skuMap: skuMap,
      PalletCategoryMap: PalletCategoryMap,
      PalletLotCategoryMap: PalletLotCategoryMap,
      retailPercentMap: retailPercentMap, 
      palletCategoriesArray: PalletCategoriesArray, 
      palletLotCategoriesArray: PalletLotCategoriesArray
    };
  }
  catch (error) {
    console.error('Error generating pallet category map: ', error);
    return {
      skuMap: null,
      PalletCategoryMap: null,
      PalletLotCategoryMap: null,
      retailPercentMap: null,
      PalletCategories: null,
      PalletLotCategories: null,
    }
  }
}