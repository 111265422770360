import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Styles, { Colors } from './Styles';
import Search from 'pages/Search';
import Import from 'pages/Import';
import Users from 'pages/Users';
import SelectLoadPopup from 'components/SelectLoadPopup';
import Login from 'components/Login';
import { DB_URL } from 'env';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faChartBar,
  faFileCsv,
  faHome,
  faMapMarkedAlt,
  faPlusCircle,
  faSignInAlt,
  faTags,
  faUsers,
  faWarehouse,
} from '@fortawesome/free-solid-svg-icons';
import Reporting from 'pages/Reporting';
import Log from 'components/Logger';
import Loads from './pages/Loads';
import {
  PAGE_WIDTH,
  ROLES,
  setPageSize,
  UserContext,
} from './utilities/DataTypes';
import Destinations from 'pages/Destinations';
import Pallets from 'pages/Pallets';
import styled from 'styled-components';
import IndividualProducts from './pages/IndividualProducts';
import { createTheme, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Categories from 'pages/Categories';

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.header,
      contrastText: '#fff',
    },
    secondary: {
      main: Colors.greenBlue,
      contrastText: '#fff',
    },
    success: {
      main: Colors.green,
      contrastText: '#fff',
    },
    error: {
      main: Colors.red,
      contrastText: '#fff',
    },
    info: {
      main: Colors.gray,
      contrastText: '#fff',
    },
  },
  components: {
    // MuiAutocomplete: {
    //   styleOverrides: {
    //     root: {
    //       marginRight: '10px'
    //     }
    //     inputRoot: {
    //       padding: '4px',
    //       fieldset: {
    //         borderColor: '#969696',
    //       },
    //     },
    //   },
    // },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          transform: "scale(1.3)",
          padding: "2px 5px 0px 0px !important"
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '12px !important'
        },
        root: {
          padding: '0px !important',
        },
        notchedOutline: {
          borderColor: '#969696',
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          marginRight: '0px !important'
        }
      }
    }
  },
});

interface Props {}

interface State {
  username: string;
  password: string;
  accountName: string;
  auth: string;
  loggedIn: boolean;
  userRole: string;
  loginMessage: string;
  isAdmin: boolean;
  isReporting: boolean;
  recordData: any;
  isMobile: boolean;
  /** Query to pass to search page. */
  showMenu: boolean;
  showLoadSelect: boolean;
  selectedLoad: string;
  pallet: string;
}

const setCookie = (cname: string, cvalue: string) => {
  const d = new Date();
  d.setTime(d.getTime() + 12 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

const getCookie = (cname: string) => {
  let name = cname + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const NoPrint = styled.div`
  @media print {
    display: none;
  }
`;

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      accountName: '',
      auth: '',
      loggedIn: false,
      userRole: '',
      loginMessage: '',
      isAdmin: false,
      isReporting: false,
      recordData: [],
      isMobile: false,
      showMenu: false,
      showLoadSelect: false,
      selectedLoad: '',
      pallet: ''
    };
  }

  componentDidMount() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.setState({ isMobile: true });
    }
    window.addEventListener('resize', () => {
      setPageSize(window.innerHeight * 0.95 - 30, window.innerWidth);
      this.forceUpdate();
    });

    const authCookie = getCookie('auth');
    if (authCookie.length > 0) {
      this.setState({
        username: atob(authCookie).split(':')[0],
        password: atob(authCookie).split(':')[1],
      });
    }
  }

  componentDidUpdate() {
    if (
      !this.state.loggedIn &&
      getCookie('auth').length > 0 &&
      this.state.username.length > 0 &&
      this.state.password.length > 0 &&
      this.state.loginMessage.length <= 0
    )
      this.onLogin();
  }

  onLogin = () => {
    fetch(DB_URL + '_security/_authenticate', {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization:
          'Basic ' + btoa(this.state.username + ':' + this.state.password),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          this.setState({ loginMessage: 'Signing in...' });
          return response.json();
        } else {
          this.setState({ loginMessage: 'Incorrect username/password' });
          return false;
        }
      })
      .then((response) => {
        if (response !== false) {
          if (
            response.roles.includes('admin') ||
            response.roles.includes('rgt-admin') ||
            response.roles.includes('novotex-admin')
          ) {
            this.setState({ isAdmin: true });
          }
          if (
            response.roles.includes('rgt-reporting') ||
            response.roles.includes('novotex-reporting')
          ) {
            this.setState({ isReporting: true });
          }
          if (response.roles.includes('novotex-sorter')) {
            this.setState({ showLoadSelect: true });
          }

          this.setState({
            loggedIn: true,
            loginMessage: '',
            userRole: response.roles[0], // Adjusted from backend_roles to roles
            accountName: response.username, // Adjusted from user_name to username
            auth:
              'Basic ' + btoa(this.state.username + ':' + this.state.password),
          });
          if (getCookie('auth').length <= 0)
            setCookie(
              'auth',
              btoa(this.state.username + ':' + this.state.password)
            );
          Log(0, 'Logged in as:' + response.roles[0]);
        }
      })
      .catch((error) => {
        Log(2, 'onLogin error: ' + error);
      });
  };


  render() {
    return (
      <UserContext.Provider
        value={{
          userRole: this.state.userRole,
          accountName: this.state.accountName,
          loggedIn: this.state.loggedIn,
          isAdmin: this.state.isAdmin,
          isReporting: this.state.isReporting,
          isMobile: this.state.isMobile,
          auth: this.state.auth,
          selectedLoad: this.state.selectedLoad,
          pallet: this.state.pallet
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider autoHideDuration={5000} maxSnack={3}>
              <NoPrint
                style={{
                  display: 'flex',
                  flex: '0',
                  flexDirection: 'column',
                  alignItems: 'stretch',
                  minHeight: window.innerHeight,
                  maxHeight: window.innerHeight,
                  minWidth: window.innerWidth,
                  maxWidth: window.innerWidth,
                }}
              >
                <BrowserRouter basename="/" forceRefresh={false}>
                  <div
                    style={{
                      display: 'flex',
                      flex: '0',
                      maxHeight: '5vh',
                      minHeight: '5vh',
                      padding: '15px',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: Colors.header,
                    }}
                  >
                    {/* Title/Hamburger */}
                    <div
                      onClick={() =>
                        this.setState((state) => ({
                          showMenu: !state.showMenu,
                        }))
                      }
                      style={{
                        display:
                          this.state.isMobile && this.state.loggedIn
                            ? 'flex'
                            : 'none',
                        marginRight: 'auto',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faBars}
                        style={{
                          color: 'white',
                          height: '4vh',
                          width: '4vh',
                          paddingRight: 10,
                        }}
                      />
                    </div>
                    <Styles.Text
                      style={{
                        display: 'flex',
                        flexGrow: 0,
                        marginRight: 10,
                        fontFamily: 'Poppins',
                        whiteSpace: 'nowrap',
                        fontSize: '20px',
                        color: Colors.altText,
                      }}
                    >
                      {this.state.selectedLoad && this.state.isMobile
                        ? this.state.selectedLoad
                        : PAGE_WIDTH > 1050
                        ? 'Frontera Database'
                        : ''}
                    </Styles.Text>

                    {/* Desktop Navigation */}
                    <nav
                      style={{
                        display: this.state.isMobile ? 'none' : 'flex',
                        flexDirection: 'row',
                        flex: 1,
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}
                    >
                      <Styles.NavLink
                        to="/search"
                        style={{
                          borderRight: 'thin solid white',
                          display:
                            this.state.loggedIn &&
                            this.state.userRole !== 'novotex-pallets'
                              ? 'flex'
                              : 'none',
                          fontSize: 16,
                        }}
                      >
                        <FontAwesomeIcon
                          style={{
                            marginRight: '10px',
                            display: PAGE_WIDTH < 1200 ? 'none' : '',
                          }}
                          icon={faHome}
                        />
                        HOME
                      </Styles.NavLink>
                      <Styles.NavLink
                        to="/import"
                        style={{
                          borderRight: 'thin solid white',
                          display: this.state.isAdmin ? 'flex' : 'none',
                        }}
                      >
                        <FontAwesomeIcon
                          style={{
                            marginRight: '10px',
                            display: PAGE_WIDTH < 1200 ? 'none' : '',
                          }}
                          icon={faPlusCircle}
                        />
                        IMPORT
                      </Styles.NavLink>
                      <Styles.NavLink
                        to="/admin"
                        style={{
                          borderRight: 'thin solid white',
                          display: this.state.isAdmin ? 'flex' : 'none',
                        }}
                      >
                        <FontAwesomeIcon
                          style={{
                            marginRight: '10px',
                            display: PAGE_WIDTH < 1200 ? 'none' : '',
                          }}
                          icon={faUsers}
                        />
                        USERS
                      </Styles.NavLink>
                      <Styles.NavLink
                        to="/reporting"
                        style={{
                          borderRight: 'thin solid white',
                          display: this.state.isAdmin ? 'flex' : 'none',
                        }}
                      >
                        <FontAwesomeIcon
                          style={{
                            marginRight: '10px',
                            display: PAGE_WIDTH < 1200 ? 'none' : '',
                          }}
                          icon={faFileCsv}
                        />
                        REPORTS
                      </Styles.NavLink>
                      <Styles.NavLink
                        to="/loads"
                        style={{
                          borderRight: 'thin solid white',
                          display:
                            this.state.isAdmin || this.state.isReporting
                              ? 'flex'
                              : 'none',
                        }}
                      >
                        <FontAwesomeIcon
                          style={{
                            marginRight: '10px',
                            display: PAGE_WIDTH < 1200 ? 'none' : '',
                          }}
                          icon={faChartBar}
                        />
                        LOADS
                      </Styles.NavLink>
                      <Styles.NavLink
                        to="/destinations"
                        style={{
                          borderRight: 'thin solid white',
                          display:
                            this.state.isAdmin ||
                            this.state.userRole === 'destination-manager'
                              ? 'flex'
                              : 'none',
                        }}
                      >
                        <FontAwesomeIcon
                          style={{
                            marginRight: '10px',
                            display: PAGE_WIDTH < 1200 ? 'none' : '',
                          }}
                          icon={faMapMarkedAlt}
                        />
                        DESTINATIONS
                      </Styles.NavLink>
                      <Styles.NavLink
                        to="/pallets"
                        style={{
                          borderRight: 'thin solid white',
                          display:
                            this.state.isAdmin ||
                            this.state.userRole === 'novotex-pallets'
                              ? 'flex'
                              : 'none',
                        }}
                      >
                        <FontAwesomeIcon
                          style={{
                            marginRight: '10px',
                            display: PAGE_WIDTH < 1200 ? 'none' : '',
                          }}
                          icon={faWarehouse}
                        />
                        PALLETS
                      </Styles.NavLink>
                      <Styles.NavLink
                        to="/individualProducts"
                        style={{
                          borderRight: 'thin solid white',
                          display:
                            this.state.isAdmin ||
                            this.state.userRole === 'novotex-pallets'
                              ? 'flex'
                              : 'none',
                        }}
                      >
                        <FontAwesomeIcon
                          style={{
                            marginRight: '10px',
                            display: PAGE_WIDTH < 1200 ? 'none' : '',
                          }}
                          icon={faTags}
                        />
                        {this.state.userRole === 'novotex-pallets'
                          ? 'INDIVIDUAL PRODUCTS'
                          : 'PRODUCTS'}
                      </Styles.NavLink>
                      <Styles.NavLink	
                        to="/categories"	
                        style={{	
                          borderRight: 'thin solid white',	
                          display: this.state.isAdmin ? 'flex' : 'none',	
                        }}	
                      >	
                        CATEGORIES	
                      </Styles.NavLink>
                      <Styles.NavLink
                        to="/login"
                        style={{
                          display: this.state.loggedIn ? 'flex' : 'none',
                          flexDirection: 'column',
                          minWidth: 120,
                        }}
                        onClick={() => {
                          this.setState({
                            loggedIn: false,
                            showLoadSelect: false,
                            isAdmin: false,
                            userRole: '',
                            isReporting: false,
                            auth: '',
                            loginMessage: '',
                            selectedLoad: '',
                            username: '',
                            password: '',
                          });
                          setCookie('auth', '');
                        }}
                      >
                        {this.state.accountName}
                        <div style={{ flexDirection: 'row' }}>
                          <FontAwesomeIcon
                            style={{ marginLeft: '10px', marginRight: '10px' }}
                            icon={faSignInAlt}
                          />
                          SIGN OUT
                        </div>
                      </Styles.NavLink>
                    </nav>
                  </div>

                  {/* Mobile Navigation */}
                  <div
                    style={{
                      display: this.state.showMenu ? '' : 'none',
                      backgroundColor: Colors.header,
                      zIndex: 1000,
                    }}
                  >
                    <div
                      onClick={() => this.setState({ showMenu: false })}
                      style={{
                        backgroundColor: '#000C',
                        position: 'absolute',
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                      }}
                    />
                    <nav
                      style={{
                        position: 'absolute',
                        left: 0,
                        width: '50vw',
                        height: '100vh',
                        backgroundColor: Colors.header,
                      }}
                    >
                      <Styles.NavLink
                        onClick={() => this.setState({ showMenu: false })}
                        style={{
                          justifyContent: 'flex-start',
                          marginLeft: 10,
                          marginBottom: 30,
                        }}
                        to="/search"
                      >
                        <FontAwesomeIcon
                          style={{ marginRight: '10px' }}
                          icon={faHome}
                        />
                        HOME
                      </Styles.NavLink>
                      <Styles.NavLink
                        onClick={() => this.setState({ showMenu: false })}
                        style={{
                          display: this.state.isAdmin ? 'flex' : 'none',
                          justifyContent: 'flex-start',
                          marginLeft: 10,
                          marginBottom: 30,
                        }}
                        to="/import"
                      >
                        <FontAwesomeIcon
                          style={{ marginRight: '10px' }}
                          icon={faPlusCircle}
                        />
                        IMPORT
                      </Styles.NavLink>
                      <Styles.NavLink
                        onClick={() => this.setState({ showMenu: false })}
                        style={{
                          display: this.state.isAdmin ? 'flex' : 'none',
                          justifyContent: 'flex-start',
                          marginLeft: 10,
                          marginBottom: 30,
                        }}
                        to="/admin"
                      >
                        <FontAwesomeIcon
                          style={{ marginRight: '10px' }}
                          icon={faUsers}
                        />
                        USERS
                      </Styles.NavLink>
                      <Styles.NavLink
                        onClick={() => this.setState({ showMenu: false })}
                        style={{
                          display: this.state.isAdmin ? 'flex' : 'none',
                          justifyContent: 'flex-start',
                          marginLeft: 10,
                          marginBottom: 30,
                        }}
                        to="/reporting"
                      >
                        <FontAwesomeIcon
                          style={{ marginRight: '10px' }}
                          icon={faFileCsv}
                        />
                        REPORTS
                      </Styles.NavLink>

                      <Styles.NavLink
                        onClick={() => this.setState({ showMenu: false })}
                        style={{
                          display:
                            this.state.isAdmin ||
                            this.state.userRole === 'destination-manager'
                              ? 'flex'
                              : 'none',
                          justifyContent: 'flex-start',
                          marginLeft: 10,
                          marginBottom: 30,
                          fontSize: this.state.isAdmin ? '18px' : '20px',
                        }}
                        to="/destinations"
                      >
                        <FontAwesomeIcon
                          style={{ marginRight: '10px' }}
                          icon={faMapMarkedAlt}
                        />
                        DESTINATIONS
                      </Styles.NavLink>
                      <Styles.NavLink
                        onClick={() => this.setState({ showMenu: false })}
                        style={{
                          display:
                            this.state.isAdmin ||
                            this.state.userRole === 'novotex-pallets'
                              ? 'flex'
                              : 'none',
                          justifyContent: 'flex-start',
                          marginLeft: 10,
                          marginBottom: 30,
                          fontSize: this.state.isAdmin ? '18px' : '20px',
                        }}
                        to="/pallets"
                      >
                        <FontAwesomeIcon
                          style={{ marginRight: '10px' }}
                          icon={faMapMarkedAlt}
                        />
                        PALLETS
                      </Styles.NavLink>

                      {/*<Styles.NavLink onClick={() => this.setState({ showMenu: false })} style={{ display: (this.state.isAdmin || this.state.isReporting) ? "flex" : "none", justifyContent: "flex-start", marginLeft: 10, marginBottom: 30 }}*/}
                      {/*    to="/loads"*/}
                      {/*>*/}
                      {/*    <FontAwesomeIcon style={{ marginRight: "10px" }} icon={faChartBar} />*/}
                      {/*    LOADS*/}
                      {/*</Styles.NavLink>*/}
                      <Styles.NavLink
                        style={{
                          justifyContent: 'flex-start',
                          marginLeft: 10,
                          marginBottom: 30,
                        }}
                        to="/login"
                        onClick={() => {
                          this.setState({
                            showMenu: false,
                            loggedIn: false,
                            showLoadSelect: false,
                            isAdmin: false,
                            loginMessage: '',
                            selectedLoad: '',
                            username: '',
                            password: '',
                          });
                          setCookie('auth', '');
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ marginRight: '10px' }}
                          icon={faSignInAlt}
                        />
                        SIGN OUT
                      </Styles.NavLink>
                    </nav>
                  </div>

                  {this.state.showLoadSelect && (
                      <SelectLoadPopup
                        setDisplayLoad={(value: boolean) =>
                          this.setState({ showLoadSelect: value })
                        }
                        displayLoad={this.state.showLoadSelect}
                        saveLoad={(value: string) => {
                          this.setState({ selectedLoad: value });
                          Log(0, 'Now sorting load ' + value);
                        }}
                        savePallet={(value: string) => {
                          this.setState({ pallet: value });
                          console.log('saved pallet?', this.state.pallet); 
                        }}
                      />
                  )}

                  <div
                    style={{
                      display: this.state.showLoadSelect ? 'none' : 'flex',
                      flex: '1',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <Route exact={true} path="/">
                      {!this.state.loggedIn && getCookie('auth').length <= 0 ? (
                        <Redirect to="/login" />
                      ) : this.state.userRole === ROLES.NOVOTEX_PALLETS ? (
                        <Redirect to="/pallets" />
                      ) : (
                        <Redirect to="/search" />
                      )}
                    </Route>
                    <Switch>
                      <Route path="/login">
                        {this.state.loggedIn ? <Redirect to={'/search'} /> : ''}
                        <Login
                          username={this.state.username}
                          setUsername={(value: string) =>
                            this.setState({ username: value })
                          }
                          password={this.state.password}
                          setPassword={(value: string) =>
                            this.setState({ password: value })
                          }
                          onLogin={() => this.onLogin()}
                          loginMessage={this.state.loginMessage}
                        />
                      </Route>
                      <Route path="/import">
                        {this.state.loggedIn || getCookie('auth').length > 0 ? (
                          ''
                        ) : (
                          <Redirect to="/login" />
                        )}
                        <Import />
                      </Route>
                      <Route path="/admin">
                        {this.state.loggedIn || getCookie('auth').length > 0 ? (
                          ''
                        ) : (
                          <Redirect to="/login" />
                        )}
                        <Users />
                      </Route>
                      <Route path="/reporting">
                        {this.state.loggedIn || getCookie('auth').length > 0 ? (
                          ''
                        ) : (
                          <Redirect to="/login" />
                        )}
                        <Reporting />
                      </Route>
                      <Route path="/loads">
                        {this.state.loggedIn || getCookie('auth').length > 0 ? (
                          ''
                        ) : (
                          <Redirect to="/login" />
                        )}
                        <Loads />
                      </Route>
                      <Route path="/destinations">
                        {this.state.loggedIn || getCookie('auth').length > 0 ? (
                          ''
                        ) : (
                          <Redirect to="/login" />
                        )}
                        <Destinations />
                      </Route>
                      <Route path="/pallets">
                        {this.state.loggedIn || getCookie('auth').length > 0 ? (
                          ''
                        ) : (
                          <Redirect to="/login" />
                        )}
                        <Pallets />
                      </Route>
                      <Route path="/individualProducts">
                        {this.state.loggedIn || getCookie('auth').length > 0 ? (
                          ''
                        ) : (
                          <Redirect to="/login" />
                        )}
                        <IndividualProducts />
                      </Route>
                      <Route path="/categories">
                        {this.state.loggedIn ? ('') : (<Redirect to="/login"/>)}
                        <Categories />
                      </Route>
                    </Switch>
                    <Route path="/search">
                      {this.state.loggedIn ||
                      getCookie('auth').length > 0 ? null : (
                        <Redirect to="/login" />
                      )}
                      {this.state.userRole === ROLES.NOVOTEX_PALLETS && (
                        <Redirect to="/pallets" />
                      )}
                      <Search
                        setSelectedLoad={(value: string) =>
                          this.setState({ selectedLoad: value })
                        }
                        showLoadSelect={this.state.showLoadSelect}
                        setShowLoadSelect={(value: boolean) =>
                          this.setState({ showLoadSelect: value })
                        }
                        pallet = {this.state.pallet}
                        setPallet={(value: string) =>
                          this.setState({ pallet: this.state.pallet })
                        }
                      />
                    </Route>
                  </div>
                </BrowserRouter>
              </NoPrint>
            </SnackbarProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </UserContext.Provider>
    );
  }
}

export default App;
