import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DestinationForm from 'components/DestinationForm';
import Log from 'components/Logger';
import { API_URL } from 'env';
import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import Styles, { Colors, MuiButton } from '../Styles';
import {
  PAGE_HEIGHT,
  PAGE_WIDTH,
  SizeProps,
  UserContext,
} from '../utilities/DataTypes';
import { Checkbox } from '@mui/material';

const MainTableContainer = styled.div<SizeProps>`
  display: flex;
  flex-direction: column;
  flex: 20 1 0;
  min-height: 0;
  max-height: ${(props) => props.pageHeight}px;
  overflow: scroll;
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: 0 0 5px #0003;
`;
const NewDestinationsPopup = styled.div<SizeProps>`
  position: absolute;
  z-index: 100;
  margin-top: ${(props) => props.pageHeight * 0.1}px;
`;
const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
const TableCheckMark = styled.div`
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  border: none;
  background-color: #fff0;
  outline-width: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Destinations = () => {
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showInactive, setShowInactive] = useState(false);
  const [results, setResults]: [any, any] = useState([]);
  const [currentResult, setCurrentResult] = useState('');
  const userContext = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const DESTINATION_FIELDS = [
    'Destination Name',
    'Color',
    'Barcode',
    'Actions',
  ];

  const getDestinations = (): Promise<boolean> => {
    return fetch(API_URL + 'getDestinations', {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((response) => {
        setResults(response.destinations);
        return true;
      })
      .catch((err) => {
        Log(2, err);
        return false;
      });
  };

  useEffect(() => {
    getDestinations();
  }, []);

  const setActive = (
    name: any,
    isActive: boolean,
    auth: string
  ): Promise<boolean> => {
    return fetch(API_URL + 'updateDestination', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: auth,
      },
      body: JSON.stringify({
        destination_id: name,
        fields: {
          destination_active: isActive,
        },
      }),
    })
      .then(() => {
        getDestinations();
        return true;
      })
      .catch((err) => {
        Log(2, err);
        return false;
      });
  };

  const listResults = () => {
    let list: Array<any> = [];
    if (results !== undefined && results.length > 0) {
      list = results;
      list.sort((a, b) => b.destination_active - a.destination_active);
    }
    let items: any;
    items = list.map(
      (result: any) =>
        (result.destination_active || showInactive) && (
          <tr
            key={'TR-' + result.destination_name}
            style={{
              borderBottom: 'thin solid black',
            }}
          >
            {DESTINATION_FIELDS.map((field: any) => {
              switch (field) {
                case 'Destination Name':
                  return (
                    <Styles.StyledTD key={DESTINATION_FIELDS.indexOf(field)}>
                      {(userContext.isMobile
                        ? result.destination_name
                        : result.destination_name) || 'NONE'}
                    </Styles.StyledTD>
                  );
                case 'Color':
                  return (
                    <Styles.StyledTD
                      key={DESTINATION_FIELDS.indexOf(field)}
                      style={{ backgroundColor: result.destination_color }}
                    >
                      {(userContext.isMobile
                        ? result.destination_color
                        : result.destination_color) || 'NONE'}
                    </Styles.StyledTD>
                  );
                case 'Barcode':
                  return (
                    <Styles.StyledTD key={DESTINATION_FIELDS.indexOf(field)}>
                      {(userContext.isMobile
                        ? result.destination_barcode
                        : result.destination_barcode) || 'NONE'}
                    </Styles.StyledTD>
                  );
                default:
                  return <></>;
              }
            })}

            <ActionsContainer>
              <Styles.TableButton
                onClick={(_) => {
                  setShowForm(true);
                  setIsEdit(true);
                  setCurrentResult(result);
                }}
                style={{
                  padding: '10px 15px 2px 0px',
                  backgroundColor: '#FFF0',
                  margin: 0,
                }}
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  style={{ width: '24px', height: '24px', marginBottom: 0 }}
                  color={Colors.gray}
                />
                <p
                  style={{
                    fontSize: 12,
                    marginTop: 0,
                    fontFamily: `Open Sans, sans-serif`,
                  }}
                >
                  Edit
                </p>
              </Styles.TableButton>
              <TableCheckMark>
                <Checkbox
                  checked={result.destination_active}
                  onChange={() =>
                    setActive(
                      result.destination_name,
                      !result.destination_active,
                      userContext.auth
                    ).then((success) => {
                      if (success) {
                        enqueueSnackbar(
                          `Updated active status of ${result.destination_name} in database.`,
                          { variant: 'success' }
                        );
                      } else {
                        enqueueSnackbar(
                          'Error updating active status of destination. Please check your internet connection.',
                          { variant: 'error' }
                        );
                      }
                    })
                  }
                />
                <p
                  style={{ fontSize: 12, marginTop: 0 }}
                  onClick={() =>
                    setActive(
                      result.destination_name,
                      !result.destination_active,
                      userContext.auth
                    ).then((success) => {
                      if (success) {
                        enqueueSnackbar(
                          `Updated active status of ${result.destination_name} in database.`,
                          { variant: 'success' }
                        );
                      } else {
                        enqueueSnackbar(
                          'Error updating active status of destination. Please check your internet connection.',
                          { variant: 'error' }
                        );
                      }
                    })
                  }
                >
                  Active
                </p>
              </TableCheckMark>
            </ActionsContainer>
          </tr>
        )
    );
    return items;
  };

  return (
    <Styles.Page>
      <Styles.PageHeader>Destinations</Styles.PageHeader>
      <Styles.FlexDiv
        style={{
          display: 'flex',
          flex: '1',
          flexDirection: 'column',
          marginTop: '20px',
          minHeight: '15vh',
          maxHeight: '80vh',
          minWidth: userContext.isMobile ? PAGE_WIDTH : 'calc(60vw + 60px)',
          maxWidth: userContext.isMobile ? PAGE_WIDTH : 'calc(60vw + 60px)',
        }}
      >
        <MainTableContainer
          pageWidth={PAGE_WIDTH}
          pageHeight={PAGE_HEIGHT}
          style={{
            maxWidth: userContext.isMobile ? PAGE_WIDTH : '60vw',
            minWidth: userContext.isMobile ? PAGE_WIDTH : '60vw',
            overflowX: userContext.isMobile ? 'hidden' : 'scroll',
            padding: userContext.isMobile ? 0 : '30px',
            paddingTop: userContext.isMobile ? 0 : '10px',
          }}
        >
          <TableCheckMark
            style={{ flexDirection: 'row', alignSelf: 'flex-end', padding: 0 }}
          >
            <Styles.Text onClick={() => setShowInactive(!showInactive)}>
              Show Inactive Destinations
            </Styles.Text>
            <Checkbox
              checked={showInactive}
              sx={{ marginLeft: '10px' }}
              onChange={() => setShowInactive(!showInactive)}
            />
          </TableCheckMark>
          <table
            style={{
              borderCollapse: 'collapse',
              tableLayout: userContext.isMobile ? 'fixed' : 'auto',
              width: userContext.isMobile ? window.innerWidth : '60vw',
            }}
          >
            <thead>
              <tr>
                {DESTINATION_FIELDS.map((field: any) => {
                  return (
                    <Styles.StyledTH
                      key={DESTINATION_FIELDS.indexOf(field)}
                      style={{
                        width: userContext.isMobile ? '35%' : '',
                        textAlign: 'match-parent',
                      }}
                    >
                      {field}
                    </Styles.StyledTH>
                  );
                })}
              </tr>
            </thead>
            <tbody>{listResults()}</tbody>
          </table>
        </MainTableContainer>
        <MuiButton
          variant="contained"
          style={{
            marginTop: '5px',
            minWidth: '6vw',
            maxWidth: '30vw',
            alignSelf: 'center',
          }}
          onClick={(_) => setShowForm(true)}
        >
          <FontAwesomeIcon icon={faPlus} style={{ marginRight: '10px' }} />
          Add Destination
        </MuiButton>
      </Styles.FlexDiv>
      {showForm && !isEdit && (
        <NewDestinationsPopup pageWidth={PAGE_WIDTH} pageHeight={PAGE_HEIGHT}>
          <DestinationForm
            setShowForm={(e: boolean) => setShowForm(e)}
            showForm={showForm}
            edit={false}
            setIsEdit={(e: boolean) => setIsEdit(e)}
            results=""
            getDestinations={() => getDestinations()}
          />
        </NewDestinationsPopup>
      )}
      {showForm && isEdit && (
        <NewDestinationsPopup pageWidth={PAGE_WIDTH} pageHeight={PAGE_HEIGHT}>
          <DestinationForm
            setShowForm={(e: boolean) => setShowForm(e)}
            showForm={showForm}
            edit={true}
            setIsEdit={(e: boolean) => setIsEdit(e)}
            results={currentResult}
            getDestinations={() => getDestinations()}
          />
        </NewDestinationsPopup>
      )}
      <div
        onClick={() => {
          setShowForm(false);
          setIsEdit(false);
        }}
        style={{
          display: showForm ? '' : 'none',
          position: 'absolute',
          left: 0,
          top: window.innerHeight - PAGE_HEIGHT,
          width: PAGE_WIDTH,
          height: PAGE_HEIGHT,
          backgroundColor: '#000C',
          zIndex: 50,
        }}
      />
    </Styles.Page>
  );
};

export default Destinations;
