import {API_URL} from 'env';
import Log from 'components/Logger';

// INDIVIDUAL PRODUCTS SECTION %%%%%%%%%%%%%
export const getIndividualProductCategories = ():Promise<any[]> => {
    return fetch(API_URL + 'getIndividualProductCategories', {
        method: 'GET',
    })
    .then((response)=>response.json())
    .then((response) => {
        return response.categories;
    })
    .catch((err)=> {
        Log(2, err);
        return [];
    });
};

export const addIndividualProductCategory = (categoryInfo:any):Promise<boolean> => {
    return fetch(API_URL + 'addIndividualProductCategory', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
            fields: categoryInfo,
        }),
    })
    .then( (response) => {
        if (response.ok)
            return true;
        else
            return false;
    })
    .catch((err)=> {
        Log(2, err);
        return false;
    })
};

export const updateIndividualProductCategory = (productInfo:any):Promise<boolean> => {
    return fetch(API_URL + 'updateIndividualProductCategory', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
            fields: productInfo
        }),
    })
    .then((response)=> {
        if (response.ok)
            return true;
        else
            return false;
    })
    .catch((err)=>{
        Log(2, err);
        return false;
    });
};

export const deleteIndividualProductCategory = (productID: number):Promise<boolean> => {
    return fetch(API_URL + 'deleteIndividualProductCategory', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
            fields: {
                id: productID
            }
        }),
    })
    .then((response)=> {
        if (response.ok){
            return true;
        }
        else{
            return false;
        }
    })
    .catch((err)=>{
        Log(2, err);
        return false;
    });
};
// ------


// PALLET CATEGORIES SECTION %%%%%%%%%%%%%%%%%%%%%%
export const getPalletCategories = ():Promise<any[]> => {
    return fetch(API_URL + 'getPalletCategories', {
        method: 'GET',
    })
    .then((response)=>response.json())
    .then((response) => {
        return response.categories;
    })
    .catch((err)=> {
        Log(2, err);
        return [];
    });
};

export const addPalletCategory = (productInfo:any):Promise<boolean> => {
    return fetch(API_URL + 'addPalletCategory', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
            fields: productInfo,
        }),
    })
    .then( (response) => {
        if (response.ok)
            return true;
        else
            return false;
    })
    .catch((err)=> {
        Log(2, err);
        return false;
    })
};

export const updatePalletCategory = (productInfo:any):Promise<boolean> => {
    return fetch(API_URL + 'updatePalletCategory', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
            fields: productInfo
        }),
    })
    .then((response)=> {
        if (response.ok)
            return true;
        else
            return false;
    })
    .catch((err)=>{
        Log(2, err);
        return false;
    });
};

export const deletePalletCategory = (productID: number):Promise<boolean> => {
    return fetch(API_URL + 'deletePalletCategory', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
            fields: {
                id: productID
            }
        }),
    })
    .then((response)=> {
        if (response.ok){
            return true;
        }
        else{
            return false;
        }
    })
    .catch((err)=>{
        Log(2, err);
        return false;
    });
};
// -----

// PALLET LOT CATEGORIES SECTION %%%%%%%%%%%%%%%
export const getPalletLotCategories = async ():Promise<boolean> => {
    return await fetch(API_URL + 'getPalletLotCategories', {
        method: 'GET',
    })
    .then((response)=>response.json())
    .then((response) => {
        return response.categories;
    })
    .catch((err)=> {
        Log(2, err);
        return [];
    });
};

export const addPalletLotCategory = async (productInfo:any):Promise<boolean> => {
    return await fetch(API_URL + 'addPalletLotCategory', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
            fields: productInfo,
        }),
    })
    .then( (response) => {
        if (response.ok)
            return true;
        else
            return false;
    })
    .catch((err)=> {
        Log(2, err);
        return false;
    })
};

export const updatePalletLotCategory = async (productInfo:any):Promise<boolean> => {
    return await fetch(API_URL + 'updatePalletLotCategory', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
            fields: productInfo
        }),
    })
    .then((response)=> {
        if (response.ok)
            return true;
        else
            return false;
    })
    .catch((err)=>{
        Log(2, err);
        return false;
    });
};

export const deletePalletLotCategory = async (productID: number):Promise<boolean> => {
    return await fetch(API_URL + 'deletePalletLotCategory', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
            fields: {
                id: productID
            }
        }),
    })
    .then((response)=> {
        if (response.ok){
            return true;
        }
        else{
            return false;
        }
    })
    .catch((err)=>{
        Log(2, err);
        return false;
    });
};
// ------
