import React, { useContext, useEffect, useState } from 'react';
import { API_URL } from '../env';
import Log from './Logger';
import Styles, { Colors } from 'Styles';
import { PAGE_WIDTH, UserContext } from '../utilities/DataTypes';
import { useIsMounted } from '../utilities/Helpers';
import { CircularProgress } from '@mui/material';

const getLoadDetails = (loadName: string, auth: string): Promise<any> => {
  return fetch(API_URL + 'getLoadDetails', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: auth,
    },
    body: JSON.stringify({
      load_name: loadName,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      Log(0, `getLoadDetails response: ${JSON.stringify(response)}`);
      if (Object.keys(response).length > 0) {
        return response;
      } else {
        return { total_quantity: null };
      }
    })
    .catch((err) => {
      Log(2, err);
      return { total_quantity: -1 };
    });
};

interface LoadDetailsProps {
  loadName: string;
}

const LoadDetails = (props: LoadDetailsProps) => {
  const [loadDetails, setLoadDetails]: [any, any] = useState({});
  const [refreshLoop, setRefreshLoop] = useState(false);
  const userContext = useContext(UserContext);
  const isMounted = useIsMounted();
  useEffect(() => {
    if (isMounted()) {
      setLoadDetails([]);
      getLoadDetails(props.loadName, userContext.auth).then((response: any) =>
        setLoadDetails(response)
      );
    }
  }, [props.loadName, userContext.auth, isMounted]);

  useEffect(() => {
    if (isMounted() && refreshLoop) {
      setRefreshLoop(false);
      getLoadDetails(props.loadName, userContext.auth).then((response: any) =>
        setLoadDetails(response)
      );
    }
  }, [isMounted, refreshLoop, props.loadName, userContext.auth]);

  useEffect(() => {
    if (isMounted() && !refreshLoop) {
      setTimeout(() => setRefreshLoop(true), 60000);
    }
  }, [isMounted, refreshLoop]);

  if (loadDetails.total_quantity !== null && loadDetails.total_quantity >= 0) {
    return (
      <Styles.Page
        style={{ marginTop: 0, paddingTop: 0, maxWidth: PAGE_WIDTH * 0.2 }}
      >
        <Styles.StyledUl>
          <li>
            <Styles.Text>
              <Styles.TextBold>Total Quantity in Manifest</Styles.TextBold>
              <br />
              {loadDetails.total_quantity.toLocaleString()}
            </Styles.Text>
          </li>
          <li>
            <Styles.Text>
              <Styles.TextBold>Total Rows in Manifest</Styles.TextBold>
              <br />
              {loadDetails.total_rows.toLocaleString()}
            </Styles.Text>
          </li>
          <li>
            <Styles.Text>
              <Styles.TextBold>Total Scanned</Styles.TextBold>
              <br />
              {loadDetails.total_scanned.toLocaleString()}
            </Styles.Text>
          </li>
          <li>
            <Styles.Text>
              <Styles.TextBold>
                Total Items Not Scanned, Not Received or Scanned Multiple Times
              </Styles.TextBold>
              <br />
              {loadDetails.total_not_scanned.toLocaleString()}
            </Styles.Text>
          </li>
          <li>
            <Styles.Text>
              <Styles.TextBold>Total Items Found</Styles.TextBold>
              <br />
              {loadDetails.total_found.toLocaleString()}
            </Styles.Text>
          </li>
          <li>
            <Styles.Text>
              <Styles.TextBold>
                Positivity Rate of Scanned Items
              </Styles.TextBold>
              <br />
              {loadDetails.positivity_of_scanned}
            </Styles.Text>
          </li>
          <li>
            <Styles.Text>
              <Styles.TextBold>Positivity Rate of Entire Load</Styles.TextBold>
              <br />
              {loadDetails.positivity_of_load}
            </Styles.Text>
          </li>
          <li>
            <Styles.Text>
              <Styles.TextBold>Total Load Value</Styles.TextBold>
              <br />
              {loadDetails.total_value > 0
                ? '$' + loadDetails.total_value.toLocaleString()
                : ''}
            </Styles.Text>
          </li>
        </Styles.StyledUl>
      </Styles.Page>
    );
  } else if (loadDetails.total_quantity < 0) {
    return (
      <Styles.Page style={{ marginTop: 0, paddingTop: 0 }}>
        <Styles.Text style={{ color: Colors.red }}>
          Error loading details
        </Styles.Text>
      </Styles.Page>
    );
  } else {
    return (
      <Styles.Page style={{ marginTop: 0, paddingTop: 0 }}>
        <CircularProgress sx={{ mx: 'auto' }} />
      </Styles.Page>
    );
  }
};

export default LoadDetails;
