import React, { useContext, useState } from 'react';
import Styles, { Colors, MuiButton } from 'Styles';
import {
  UserContext,
} from '../utilities/DataTypes';

import { useSnackbar } from 'notistack';

import styled from 'styled-components';
import { API_URL } from 'env';
import Log from './Logger';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin-bottom: 10px;
  width: 100%;
  font-family: Open Sans, sans-serif;
`;

interface DestinationFormProps {
  setShowForm: any;
  showForm: boolean;
  setIsEdit: any;
  edit: boolean;
  results: any;
  getDestinations: any;
}

const DestinationForm = (props: DestinationFormProps) => {
  const [destinationInfo, setDestinationInfo] = useState({
    destination_name: props.results.destination_name,
    destination_color: props.results.destination_color,
    destination_barcode: props.results.destination_barcode,
  });
  const [newDestinationInfo, setNewDestinationInfo] = useState({
    destination_name: destinationInfo.destination_name,
    destination_color: destinationInfo.destination_color,
    destination_barcode: destinationInfo.destination_barcode,
  });
  const [showMistakes, setShowMistakes] = useState(false);
  const edit = props.edit;
  const userContext = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const addDestination = (destInfo: any, auth: string): Promise<boolean> => {
    return fetch(API_URL + 'addDestination', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: auth,
      },
      body: JSON.stringify({
        fields: destInfo,
      }),
    })
      .then(() => {
        props.getDestinations();
        return true;
      })
      .catch((err) => {
        Log(2, err);
        return false;
      });
  };
  const editDestination = (
    name: any,
    destInfo: any,
    auth: string
  ): Promise<boolean> => {
    return fetch(API_URL + 'updateDestination', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: auth,
      },
      body: JSON.stringify({
        destination_id: name,
        fields: {
          destination_name: destInfo.destination_name,
          destination_color: destInfo.destination_color,
          destination_barcode: destInfo.destination_barcode,
        },
      }),
    })
      .then(() => {
        props.getDestinations();
        return true;
      })
      .catch((err) => {
        Log(2, err);
        return false;
      });
  };
  return (
    <Dialog
      maxWidth={'sm'}
      fullWidth
      open={props.showForm}
    >
      <DialogTitle>
        {edit ? (
          <Styles.PageHeader>Edit Destination</Styles.PageHeader>
        ) : (
          <Styles.PageHeader>Add New Destination</Styles.PageHeader>
        )}
      </DialogTitle>
      <DialogContent>
          <FormRow>
            <label>Destination Name</label>
            <TextField
              placeholder="e.g. RGT, Black Fridays, Ventas"
              value={
                edit
                  ? newDestinationInfo.destination_name
                  : destinationInfo.destination_name
                    ? destinationInfo.destination_name
                    : ''
              }
              onChange={(e) => {
                if (edit) {
                  setNewDestinationInfo({
                    ...newDestinationInfo,
                    destination_name: e.target.value,
                  });
                } else {
                  setDestinationInfo({
                    ...destinationInfo,
                    destination_name: e.target.value,
                  });
                }
              }}
            />
            {showMistakes &&
              (!destinationInfo.destination_name ||
                (!newDestinationInfo.destination_name && edit)) && (
                <Styles.Text style={{ margin: 0, color: Colors.red }}>
                  Destination Name required
                </Styles.Text>
              )}
          </FormRow>
          <FormRow>
            <label>Hexadecimal Color</label>

            <Styles.FlexDiv>
              <Styles.TextBox
                style={{
                  padding: 0,
                  height: 'auto',
                  flex: 1
                }}
                type="color"
                value={
                  edit
                    ? newDestinationInfo.destination_color
                    : destinationInfo.destination_color
                      ? destinationInfo.destination_color
                      : ''
                }
                onChange={(e) => {
                  if (edit) {
                    setNewDestinationInfo({
                      ...newDestinationInfo,
                      destination_color: e.target.value,
                    });
                  } else {
                    setDestinationInfo({
                      ...destinationInfo,
                      destination_color: e.target.value,
                    });
                  }
                }}
              />
              <TextField
                placeholder="e.g. #FF0000, #ffffff, #000000"
                style={{ flex: 13 }}
                value={
                  edit
                    ? newDestinationInfo.destination_color
                    : destinationInfo.destination_color
                      ? destinationInfo.destination_color
                      : ''
                }
                onChange={(e) => {
                  if (edit) {
                    setNewDestinationInfo({
                      ...newDestinationInfo,
                      destination_color: e.target.value,
                    });
                  } else {
                    setDestinationInfo({
                      ...destinationInfo,
                      destination_color: e.target.value,
                    });
                  }
                }}
              />
            </Styles.FlexDiv>
            {showMistakes &&
              (!destinationInfo.destination_color ||
                (!newDestinationInfo.destination_color && edit)) && (
                <Styles.Text style={{ margin: 0, color: Colors.red }}>
                  Color required
                </Styles.Text>
              )}
          </FormRow>
          <FormRow>
            <label>Barcode</label>
            <TextField
              placeholder="e.g. MI-BF-BLUE, MI-RGT, MI-CLOTHING"
              value={
                edit
                  ? newDestinationInfo.destination_barcode
                  : destinationInfo.destination_barcode
                    ? destinationInfo.destination_barcode
                    : ''
              }
              onChange={(e) => {
                if (edit) {
                  setNewDestinationInfo({
                    ...newDestinationInfo,
                    destination_barcode: e.target.value,
                  });
                } else {
                  setDestinationInfo({
                    ...destinationInfo,
                    destination_barcode: e.target.value,
                  });
                }
              }}
            />
            {showMistakes &&
              (!destinationInfo.destination_barcode ||
                (!newDestinationInfo.destination_barcode && edit)) && (
                <Styles.Text style={{ margin: 0, color: Colors.red }}>
                  Barcode required
                </Styles.Text>
              )}
          </FormRow>
      </DialogContent>

      <DialogActions
        sx = {{
          m: 2
        }}
      >
        <MuiButton
          variant="contained"
          color="info"
          onClick={(_) => {
            props.setShowForm(false);
            props.setIsEdit(false);
          }}
        >
          Cancel
        </MuiButton>
        {edit ? (
          <MuiButton
            variant="contained"
            disabled={
              (!newDestinationInfo.destination_name ||
                newDestinationInfo.destination_name === '' ||
                !newDestinationInfo.destination_color ||
                newDestinationInfo.destination_color === '' ||
                !newDestinationInfo.destination_barcode ||
                newDestinationInfo.destination_barcode === '') &&
              showMistakes
            }
            // style={{ marginLeft: '20px' }}
            onClick={(_) => {
              if (
                !newDestinationInfo.destination_name ||
                newDestinationInfo.destination_name === '' ||
                !newDestinationInfo.destination_color ||
                newDestinationInfo.destination_color === '' ||
                !newDestinationInfo.destination_barcode ||
                newDestinationInfo.destination_barcode === ''
              ) {
                setShowMistakes(true);
              } else {
                editDestination(
                  destinationInfo.destination_name,
                  newDestinationInfo,
                  userContext.auth
                ).then((success) => {
                  if (success) {
                    props.setShowForm(false);
                    enqueueSnackbar(
                      `Updated destination ${destinationInfo.destination_name} in database.`,
                      { variant: 'success' }
                    );
                  } else {
                    enqueueSnackbar(
                      'Error updating destination. Please check your internet connection.',
                      { variant: 'error' }
                    );
                  }
                });
              }
            }}
          >
            Apply
          </MuiButton>
        ) : (
          <MuiButton
            variant="contained"
            // style={{ marginLeft: '20px' }}
            disabled={
              (!destinationInfo.destination_name ||
                !destinationInfo.destination_color ||
                !destinationInfo.destination_barcode) &&
              showMistakes
            }
            onClick={(_) => {
              if (
                !destinationInfo.destination_name ||
                !destinationInfo.destination_color ||
                !destinationInfo.destination_barcode
              ) {
                setShowMistakes(true);
              } else {
                addDestination(destinationInfo, userContext.auth).then(
                  (success) => {
                    if (success) {
                      props.setShowForm(false);
                      enqueueSnackbar(
                        `Added destination ${destinationInfo.destination_name} to database.`,
                        { variant: 'success' }
                      );
                    } else {
                      enqueueSnackbar(
                        'Error adding destination. Please check your internet connection.',
                        { variant: 'error' }
                      );
                    }
                  }
                );
              }
            }}
          >
            Add Destination
          </MuiButton>
        )}
      </DialogActions>
    </Dialog >
  );
};
export default DestinationForm;
