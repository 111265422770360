import React, { useContext, useEffect, useState } from 'react';
import Styles, { Colors, MuiButton } from 'Styles';
import {
  UserContext,
} from '../utilities/DataTypes';
import { formattedDate } from '../utilities/Helpers';
import {
  addPallet,
  getSkuCategoryCounter,
  updateSkuCategoryCounter,
} from '../utilities/APICalls';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import Autocomplete from '@mui/material/Autocomplete';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import Log from './Logger';
import { API_URL } from 'env';

const FormRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin-bottom: 10px;
  font-family: Open Sans, sans-serif;
`;

interface AddPalletFormProps {
  setShowForm: any;
  getPallets: any;
  showForm: boolean;
  SkuMap: Map<string, string>;
  PalletCategoryMap: Map<string, string>;
  PalletLotCategoryMap: Map<string, string>
  PalletCategories: string[];
  PalletLotCategories: string[];
}

const AddPalletForm = (props: AddPalletFormProps) => {
  const [palletInfo, setPalletInfo] = useState({
    pallet_sku: '',
    pallet_destination: 'VENTAS',
    pallet_status: 'In Progress',
    pallet_start_date: formattedDate(new Date()),
  });
  const [showMistakes, setShowMistakes] = useState(false);
  const userContext = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const [palletCategory, setPalletCategory] = useState('');
  const [palletLot, setPalletLot] = useState('');
 

  const destinationOptions = ['VENTAS'];
  const statusOptions = ['Not Started', 'In Progress', 'On Hold', 'Completed'];

  const [palletSku, setPalletSku] = useState(palletInfo.pallet_sku);
  const [categoryValue, setCategoryValue] = useState('');
  const [lotValue, setLotValue] = useState('');
  
  const palletOptions: string[] = props.PalletCategories;
  const palletLotOptions: string[] = props.PalletLotCategories;
  const palletCategoryMap: Map<string, string> = props.PalletCategoryMap;
  const palletLotCategoryMap: Map<string, string> = props.PalletLotCategoryMap;

  useEffect(()=>{
    console.log(palletOptions, palletLotOptions, palletCategoryMap, palletLotCategoryMap);
  }, [])
  // gets the next counter value for pallets, different from pallet lots
  const generateSku = (productCategory: string) => {
    getSkuCategoryCounter(productCategory).then((nextSku) => {
      setPalletCategory(productCategory);
      setPalletLot('');
      let sku:string = ''; 
      if (palletCategoryMap.has(productCategory)){
        sku = palletCategoryMap.get(productCategory) + '-' + nextSku;
      }
      setPalletInfo({
        ...palletInfo,
        pallet_sku: sku,
      });
      setPalletSku(sku);
    });
  };

  // gets the next counter value for pallet lots, different from pallets
  const generateLotSku = (productLot: string) => {
    getSkuCategoryCounter(productLot).then((nextSku) => {
      setPalletLot(productLot);
      setPalletCategory('');
      let sku: string = '';
      if (palletLotCategoryMap.has(productLot)) {
        sku = palletLotCategoryMap.get(productLot) + '-' + nextSku;
      }
      setPalletInfo({
        ...palletInfo,
        pallet_sku: sku,
      });

      setPalletSku(sku);
    });
  };

  return (
    <Dialog
      open={props.showForm}
      maxWidth={'sm'}
      fullWidth
    >
      <DialogTitle> Create New Pallet </DialogTitle>
      <DialogContent>
          <FormRow>
            <label>Pallet Category</label>
            <Autocomplete
              disablePortal
              id="product-category"
              options={palletOptions}
              renderInput={(params) => <TextField {...params} />}
              inputValue={categoryValue}
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setCategoryValue(newValue);
                  setLotValue('');
                  generateSku(newValue);
                }
              }}
              disableClearable
            />
          </FormRow>
          <FormRow>
            <label>Pallet Lot</label>
            <Autocomplete
              disablePortal
              id="product-lot"
              options={palletLotOptions}
              renderInput={(params) => <TextField {...params} />}
              inputValue={lotValue}
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setLotValue(newValue);
                  setCategoryValue('');
                  generateLotSku(newValue);
                }
              }}
              disableClearable
            />
          </FormRow>
          <FormRow>
            <label>Pallet SKU</label>
            <TextField
              disabled
              value={palletSku}
            />
            {showMistakes && palletInfo.pallet_sku.length === 0 && (
              <Styles.Text style={{ margin: 0, color: Colors.red }}>
                Pallet SKU required
              </Styles.Text>
            )}
          </FormRow>

          <FormRow>
            <label>Destination</label>
            <Autocomplete
              disabled
              id="destinations"
              options={destinationOptions}
              renderInput={(params) => <TextField {...params} />}
              inputValue={palletInfo.pallet_destination}
              onChange={(event, newValue) => {
                setPalletInfo({
                  ...palletInfo,
                  pallet_destination: newValue,
                });
              }}
              disableClearable
              style={{
                backgroundColor: Colors.gray,
                borderRadius: '4px'
              }}
            />
          </FormRow>

          <FormRow>
            <label>Start Date</label>
            <DatePicker
              value={palletInfo.pallet_start_date}
              renderInput={(params) => (
                <TextField {...params} sx={{width: 175}}/>
              )}
              onChange={(newValue) => {
                setPalletInfo({
                  ...palletInfo,
                  pallet_start_date: formattedDate(newValue),
                });
              }}
            />
          </FormRow>

          <FormRow>
            <label>Status</label>
            <Autocomplete
              disablePortal
              id="status"
              options={statusOptions}
              renderInput={(params) => <TextField {...params} />}
              defaultValue={palletInfo.pallet_status}
              // inputValue={palletInfo.pallet_status}
              onChange={(event, newValue) => {
                setPalletInfo({ ...palletInfo, pallet_status: newValue });
              }}
              disableClearable
            />
          </FormRow>
      </DialogContent>

      <DialogActions
        sx={{
          m: 2
        }}
      >
        <MuiButton
          variant="contained"
          color="info"
          onClick={(_) => props.setShowForm(false)}
        >
          Cancel
        </MuiButton>
        <MuiButton
          variant="contained"
          disabled={
            (palletInfo.pallet_sku.length <= 0 ||
              palletInfo.pallet_destination.length <= 0 ||
              palletInfo.pallet_start_date.length <= 0 ||
              palletInfo.pallet_status.length <= 0) &&
            showMistakes
          }
          style={{ marginLeft: '20px' }}
          onClick={(_) => {
            if (
              palletInfo.pallet_sku.length <= 0 ||
              palletInfo.pallet_destination.length <= 0 ||
              palletInfo.pallet_start_date.length <= 0 ||
              palletInfo.pallet_status.length <= 0
            ) {
              setShowMistakes(true);
            } else {
              addPallet(palletInfo, userContext.auth).then((success) => {
                if (success) {
                  updateSkuCategoryCounter(palletCategory || palletLot)
                    .then((_) => {
                      enqueueSnackbar(
                        `Added pallet ${palletInfo.pallet_sku} to database.`,
                        { variant: 'success' }
                      );
                      props.getPallets();
                    })
                    .catch((_) => {
                      enqueueSnackbar(
                        'Error adding pallet. Please check your internet connection.',
                        { variant: 'error' }
                      );
                    });
                } else {
                  enqueueSnackbar(
                    'Error adding pallet. Please check your internet connection.',
                    { variant: 'error' }
                  );
                }
              });
              props.setShowForm(false);
            }
          }}
        >
          Save
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddPalletForm;
