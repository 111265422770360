import Styles, { MuiButton } from 'Styles';
import React, { useContext, useEffect, useState } from 'react';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API_URL } from 'env';
import Log from 'components/Logger';
import { getLoads } from '../utilities/APICalls';
import { SEVEN_DAYS_AGO, UserContext } from '../utilities/DataTypes';
import { createDownload, formattedDate, useIsMounted } from 'utilities/Helpers';
import styled from 'styled-components';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const DateSubContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const Container = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const getSorterCSV = (
  auth: string,
  startDate: string,
  endDate: string
): Promise<boolean> => {
  return fetch(API_URL + 'requestScanCSV', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: auth,
    },
    body: JSON.stringify({
      start_date: startDate, // Format: YYYY-MM-DD e.g. 2021-06-24
      end_date: endDate,
    }),
  })
    .then((response) => response.blob())
    .then((response) => {
      createDownload(
        response,
        `Sorter Report (${startDate} to ${endDate}).csv`
      );
      return true;
    })
    .catch((error) => {
      Log(2, 'getSorterCSV error: ' + error);
      return true;
    });
};

const getRGTCSV = (
  auth: string,
  startDate: string,
  endDate: string
): Promise<boolean> => {
  return fetch(API_URL + 'requestRGTCSV', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: auth,
    },
    body: JSON.stringify({
      start_date: startDate,
      end_date: endDate,
    }),
  })
    .then((response) => response.blob())
    .then((response) => {
      createDownload(response, `RGT Report (${startDate} to ${endDate}).csv`);
      return true;
    })
    .catch((error) => {
      Log(2, 'getRGTCSV error: ' + error);
      return true;
    });
};

const getLoadStats = (auth: string, load: string): Promise<Response> => {
  return fetch(API_URL + 'getLoadStats', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: auth,
    },
    body: JSON.stringify({
      load: load,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      return response.count;
    });
};

interface ReportingProps {}

const Reporting = (props: ReportingProps) => {
  const [novotexStartDate, setNovotexStartDate]: [string, any] = useState('');
  const [novotexEndDate, setNovotexEndDate]: [string, any] = useState('');
  const [rgtStartDate, setRgtStartDate]: [string, any] = useState('');
  const [rgtEndDate, setRgtEndDate]: [string, any] = useState('');
  const [loadCount, setLoadCount]: [string, any] = useState('');
  const [loadName, setLoadName]: [string, any] = useState('');
  const [loads, setLoads]: [any, any] = useState([]);
  const [rgtDownloading, setRgtDownloading] = useState(false);
  const [novotexDownloading, setNovotexDownloading] = useState(false);
  const userContext = useContext(UserContext);
  const isMounted = useIsMounted();
  useEffect(() => {
    if (isMounted()) {
      setNovotexStartDate(formattedDate(SEVEN_DAYS_AGO));
      setNovotexEndDate(formattedDate());
      setRgtStartDate(formattedDate(SEVEN_DAYS_AGO));
      setRgtEndDate(formattedDate());
    }
  }, [isMounted]);
  useEffect(() => {
    if (isMounted()) {
      getLoads(props).then((response) => {
        if (isMounted()) {
          //console.log('RESPONSE: ', response);
          setLoads(response.items);
        }
      });
    }
  }, [props, isMounted]);
  return (
    <Styles.Page>
      <Styles.PageHeader>Reporting</Styles.PageHeader>
      <Container
        style={{
          display:
            userContext.userRole === 'novotex-admin' ||
            userContext.userRole === 'admin'
              ? 'flex'
              : 'none',
          maxWidth: userContext.isMobile ? '80vw' : '40vw',
        }}
      >
        <Styles.Text>Novotex Sorting Report:</Styles.Text>
        <DateContainer>
          <DateSubContainer>
            <label>From</label>
            <DatePicker
              value={novotexStartDate}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: 200, height: 60 }} />
              )}
              onChange={(newValue) => {
                setNovotexStartDate(formattedDate(newValue));
              }}
            />
          </DateSubContainer>
          <DateSubContainer>
            <label>To</label>
            <DatePicker
              value={novotexEndDate}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: 200, height: 60 }} />
              )}
              onChange={(newValue) => {
                setNovotexEndDate(formattedDate(newValue));
              }}
            />
          </DateSubContainer>
        </DateContainer>
        <MuiButton
          startIcon={
            novotexDownloading ? (
              <CircularProgress sx={{ mx: 'auto' }} />
            ) : (
              <FontAwesomeIcon
                className={novotexDownloading ? 'fa-spin' : ''}
                icon={faDownload}
              />
            )
          }
          variant="contained"
          style={{ width: '100%' }}
          onClick={() => {
            setNovotexDownloading(true);
            getSorterCSV(
              userContext.auth,
              novotexStartDate,
              novotexEndDate
            ).then((_) => setNovotexDownloading(false));
          }}
        >
          {novotexDownloading ? 'Downloading' : 'Download Report'}
        </MuiButton>
      </Container>
      <Container
        style={{
          display:
            userContext.userRole === 'rgt-admin' ||
            userContext.userRole === 'admin'
              ? 'flex'
              : 'none',
          maxWidth: userContext.isMobile ? '80vw' : '40vw',
        }}
      >
        <Styles.Text>RGT Feedback Report:</Styles.Text>
        <DateContainer>
          <DateSubContainer>
            <label>From</label>
            <DatePicker
              value={rgtStartDate}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: 200, height: 60 }} />
              )}
              onChange={(newValue) => {
                setRgtStartDate(formattedDate(newValue));
              }}
            />
          </DateSubContainer>
          <DateSubContainer>
            <label>To</label>
            <DatePicker
              value={rgtEndDate}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: 200, height: 60 }} />
              )}
              onChange={(newValue) => {
                setRgtEndDate(formattedDate(newValue));
              }}
            />
          </DateSubContainer>
        </DateContainer>
        <MuiButton
          startIcon={
            rgtDownloading ? (
              <CircularProgress sx={{ mx: 'auto' }} />
            ) : (
              <FontAwesomeIcon
                className={rgtDownloading ? 'fa-spin' : ''}
                icon={faDownload}
              />
            )
          }
          variant="contained"
          style={{ width: '100%' }}
          onClick={() => {
            setRgtDownloading(true);
            getRGTCSV(userContext.auth, rgtStartDate, rgtEndDate).then((_) =>
              setRgtDownloading(false)
            );
          }}
        >
          {rgtDownloading ? 'Downloading' : 'Download Report'}
        </MuiButton>
      </Container>
      <Container
        style={{
          display:
            userContext.userRole === 'novotex-admin' ||
            userContext.userRole === 'admin'
              ? ''
              : 'none',
          maxWidth: userContext.isMobile ? '80vw' : '40vw',
        }}
      >
        <Styles.FlexDiv
          style={{
            display: 'flex',
            flex: '5 1 auto',
            justifyContent: 'center',
          }}
        >
          <Styles.FlexDiv style={{ flexDirection: 'column' }}>
            <Styles.Text style={{ marginBottom: 0 }}>Load Info: </Styles.Text>
            <Autocomplete
              id="status"
              options={loads}
              renderInput={(params) => <TextField {...params} />}
              onChange={(event, newValue) => {
                setLoadName(newValue);
              }}
              sx={{ width: 150, marginRight: '10px' }}
            />
          </Styles.FlexDiv>
          <Styles.FlexDiv
            style={{
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <MuiButton
              variant="contained"
              onClick={() => {
                setLoadCount('loading...');
                getLoadStats(userContext.auth, loadName).then((response) => {
                  setLoadCount(response);
                });
              }}
            >
              Search
            </MuiButton>
          </Styles.FlexDiv>
        </Styles.FlexDiv>
        <Styles.Text>Items scanned: {loadCount}</Styles.Text>
      </Container>
    </Styles.Page>
  );
};

export default Reporting;
