import React from 'react';
import logo from '../assets/logo.png';
import { formatter } from '../utilities/Helpers';
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import {
  IndividualProductDefaultRetailPercent,
  QrCodeUrl,
} from 'utilities/DataTypes';

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4nY1M2xLER.ttf',
    },
    {
      fontWeight: 700,
      src: 'https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf',
    },
  ],
});

Font.register({
  family: 'Libre Barcode 39',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/librebarcode39/v19/-nFnOHM08vwC6h8Li1eQnP_AHzI2K_d709jy92k.ttf',
    },
  ],
});

Font.register({
  family: 'Roboto Mono 700',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/robotomono/v21/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_Of2PQ--5Ip2sSQ.ttf',
    },
  ],
});

Font.registerHyphenationCallback((word) => [word]);

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Open Sans',
    fontWeight: 'light',
    backgroundColor: '#FFF',
    width: 288,
    height: 432,
    padding: 7.2,
    margin: 0,
    display: 'flex',
    flexGrow: 0,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  logo: {
    color: 'black',
    width: 72,
    marginBottom: 15,
  },
  titleText: {
    display: 'flex',
    fontSize: 16,
    textAlign: 'center',
    fontWeight: 'bold',
    width: 268,
    maxLines: 6,
    textOverflow: 'ellipsis',
  },
  retailContainer: {
    width: 268,
    margin: '10pt 0',
    padding: '2pt 0',
    borderTop: '1pt solid gray',
    borderBottom: '1pt solid gray',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  retailText: {
    fontSize: 14,
    fontWeight: 'bold',
    padding: 1,
  },
  yourPriceText: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  priceText: {
    fontWeight: 'bold',
    fontSize: 40,
  },
  footer: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  barcode: {
    fontFamily: 'Libre Barcode 39',
    fontSize: '33px',
    marginBottom: '0px',
    padding: '0px',
  },
  qrCode: {
    width: '100px',
    margin: '26px 0',
  },
});

interface PalletPrintSheetProps {
  productInfo: any;
  barcode: string;
  retailPercent: number | null | undefined;
}

function PalletIndividualProductPdf(props: PalletPrintSheetProps) {
  const discountPercentage =
    props.retailPercent ?? IndividualProductDefaultRetailPercent;
  const actualItemPrice = formatter.format(
    parseFloat(props.productInfo.pallet_individual_product_retail_price) *
      discountPercentage
  );

  return (
    <Document>
      <Page size={[288, 432]} style={styles.page}>
        <Image style={styles.logo} src={logo} />
        <Text wrap={false} style={styles.titleText}>
          {props.productInfo.pallet_individual_product_title}
        </Text>
        <View style={styles.retailContainer}>
          <Text style={styles.retailText}>Precio de Tienda / Retail</Text>
          <Text style={styles.retailText}>
            {formatter.format(
              props.productInfo.pallet_individual_product_retail_price
            )}
          </Text>
        </View>
        <Text style={styles.yourPriceText}>Tu precio / Your Price</Text>
        <Text style={styles.priceText}>{actualItemPrice}</Text>
        {props.barcode !== undefined && (
          <View style={styles.footer}>
            <Image style={styles.qrCode} src={QrCodeUrl + props.barcode} />
            <Text style={{ fontFamily: 'Roboto Mono 700' }}>
              {props.barcode}
            </Text>
          </View>
        )}
      </Page>
    </Document>
  );
}

export default PalletIndividualProductPdf;
