import {
  faBalanceScaleLeft,
  faBan,
  faBolt,
  faBoxOpen,
  faCheck,
  faDesktop,
  faDollarSign,
  faMinus,
  faPlus,
  faTimes,
  faExclamation
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import Styles, { Colors } from 'Styles';
import { useSnackbar } from 'notistack';
import { submitRGTFeedback } from '../utilities/APICalls';
import { FeedbackType, UserContext } from '../utilities/DataTypes';
import styled from 'styled-components';
import { TextField } from '@mui/material';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 1px 15px 2px 0;
  margin: 0;
`;

interface FeedbackButtonsProps {
  setShowRejectFeedback: any;
  record: any;
  update: () => void;
  rejectFeedback: any;
}

const FeedbackButtons = (props: FeedbackButtonsProps) => {
  const [showCounter, setShowCounter] = useState(false);
  const userContext = useContext(UserContext);
  const [submitType, setSubmitType]: [FeedbackType, any] = useState('Accept');
  const [count, setCount] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <div>
      {!showCounter && (
        <div>
          <Styles.TableButton
            style={{
              padding: '10px 15px 2px 0px',
              backgroundColor: '#FFF0',
              margin: 0,
            }}
            onClick={() => {
              setSubmitType('Accept');
              setShowCounter(true);
            }}
          >
            <FontAwesomeIcon
              icon={faCheck}
              style={{ width: '24px', height: '24px', marginBottom: 0 }}
              color={'green'}
            />
            <Styles.Text style={{ fontSize: 12, marginTop: 0 }}>
              Accept
            </Styles.Text>
          </Styles.TableButton>

          <Styles.TableButton
            style={{
              padding: '10px 15px 2px 0px',
              backgroundColor: '#FFF0',
              margin: 0,
            }}
            onClick={() => {
              setSubmitType('Reject-D');
              setShowCounter(true);
            }}
          >
            <FontAwesomeIcon
              icon={faDesktop}
              style={{ width: '24px', height: '24px', marginBottom: 0 }}
            />
            <FontAwesomeIcon
              icon={faBolt}
              style={{
                width: '12px',
                height: '12px',
                marginBottom: '8px',
                marginLeft: '-18px',
              }}
              color={Colors.gray}
            />
            <Styles.Text style={{ fontSize: 12, marginTop: 0 }}>
              Reject-D
            </Styles.Text>
          </Styles.TableButton>

          <Styles.TableButton
            style={{
              padding: '10px 15px 2px 0px',
              backgroundColor: '#FFF0',
              margin: 0,
            }}
            onClick={() => {
              setSubmitType('Reject-V');
              setShowCounter(true);
            }}
          >
            <div>
              <FontAwesomeIcon
                icon={faDollarSign}
                style={{ width: '16px', height: '16px', marginBottom: '4px' }}
                color={Colors.gray}
              />
              <FontAwesomeIcon
                icon={faBan}
                style={{
                  width: '24px',
                  height: '24px',
                  marginBottom: 0,
                  marginLeft: '-20px',
                }}
                color={Colors.red}
              />
            </div>
            <Styles.Text style={{ fontSize: 12, marginTop: 0 }}>
              Reject-V
            </Styles.Text>
          </Styles.TableButton>

          <Styles.TableButton
            style={{
              padding: '10px 15px 2px 0px',
              backgroundColor: '#FFF0',
              margin: 0,
            }}
            onClick={() => {
              setSubmitType('Reject-W');
              setShowCounter(true);
            }}
          >
            <FontAwesomeIcon
              icon={faBalanceScaleLeft}
              style={{ width: '24px', height: '24px', marginBottom: 0 }}
              color="#222"
            />
            <Styles.Text style={{ fontSize: 12, marginTop: 0 }}>
              Reject-W
            </Styles.Text>
          </Styles.TableButton>

          <Styles.TableButton
            style={{
              padding: '10px 15px 2px 0px',
              backgroundColor: '#FFF0',
              margin: 0,
            }}
            onClick={() => {
              setSubmitType('Reject-C');
              setShowCounter(true);
            }}
          >
            <div>
              <FontAwesomeIcon
                icon={faBoxOpen}
                style={{ width: '24px', height: '24px', marginBottom: 0 }}
                color="#806c51"
              />
            </div>
            <Styles.Text style={{ fontSize: 12, marginTop: 0 }}>
              Reject-C
            </Styles.Text>
          </Styles.TableButton>
          <Styles.TableButton
            style={{
              padding: '10px 15px 2px 0px',
              backgroundColor: '#FFF0',
              margin: 0,
            }}
            onClick={() => {
              setSubmitType('Reject-F');
              setShowCounter(true);
              props.setShowRejectFeedback(true);
            }}
          >
            <FontAwesomeIcon
              icon={faDesktop}
              style={{ width: '24px', height: '24px', marginBottom: 0 }}
            />
            <FontAwesomeIcon
              icon={faExclamation}
              style={{
                width: '12px',
                height: '12px',
                marginBottom: '11px',
                marginLeft: '-18px',
              }}
              color={Colors.red}
            />
            <Styles.Text style={{ fontSize: 12, marginTop: 0 }}>
              Reject-F
            </Styles.Text>
          </Styles.TableButton>
        </div>
      )}

      {showCounter && (
        <Styles.FlexDiv
          style={{
            padding: '2px',
            marginRight: '10px',
          }}
        >
          <TextField
            style={{
              display: '',
              width: '31px',
              height: 'auto',
              padding: '10px',
              backgroundColor: '#FFF0',
              margin: 0,
              textAlign: 'center',
            }}
            value={count}
            onChange={(e: any) => setCount(parseInt(e.target.value))}
          />
          <StyledDiv>
            <Styles.TableButton
              onClick={() => setCount(count + 1)}
              style={{ margin: 0, backgroundColor: '#FFF0', padding: 0 }}
            >
              <FontAwesomeIcon
                icon={faPlus}
                style={{ width: '14px', height: '14px' }}
                color={Colors.gray}
              />
            </Styles.TableButton>
            <Styles.TableButton
              onClick={() => setCount(Math.max(count - 1, 1))}
              style={{ margin: 0, backgroundColor: '#FFF0', padding: 0 }}
            >
              <FontAwesomeIcon
                icon={faMinus}
                style={{ width: '14px', height: '14px' }}
                color={Colors.gray}
              />
            </Styles.TableButton>
          </StyledDiv>

          <Styles.TableButton
            style={{
              padding: '10px 15px 2px 0px',
              backgroundColor: '#FFF0',
              margin: 0,
              marginLeft: '10px',
            }}
            onClick={() => {
              setShowCounter(false);
              setCount(1);
            }}
          >
            <FontAwesomeIcon
              icon={faTimes}
              style={{ width: '24px', height: '24px', marginBottom: 0 }}
              color={Colors.gray}
            />
          </Styles.TableButton>
          <Styles.TableButton
            style={{
              padding: '10px 15px 2px 0px',
              margin: 0,
              marginLeft: '10px',
            }}
            onClick={() => {
              setShowCounter(false);
              let record = {...props.record, reject_reason: props.rejectFeedback};
              submitRGTFeedback(
                submitType,
                count,
                userContext.accountName,
                userContext.auth,
                record,
                enqueueSnackbar
              ).then(props.update);
              setCount(1);
            }}
          >
            <FontAwesomeIcon
              icon={faCheck}
              style={{ width: '24px', height: '24px', marginBottom: 0 }}
              color={Colors.gray}
            />
          </Styles.TableButton>
        </Styles.FlexDiv>
      )}
    </div>
  );
};

export default FeedbackButtons;
