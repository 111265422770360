import React, { useContext, useEffect, useRef, useState } from 'react';
import Styles, { Colors, MuiButton } from 'Styles';
import {
  PAGE_WIDTH,
  PALLET_SEARCH_FIELDS,
  UserContext,
} from '../utilities/DataTypes';
import styled from 'styled-components';
import { API_URL, DB_URL, ES_INDEX } from '../env';
import Log from './Logger';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { CircularProgress, TextField } from '@mui/material';
import IndividualProductForm from './IndividualProductForm';
import AddUnlistedPalletProduct from './AddUnlistedPalletProduct';

// Styles

const SearchBarForm = styled.form`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
  align-self: flex-start;
  justify-content: flex-start;
  align-items: center;
`;

// Smart components

const addPalletProduct = (fields: any, auth: string): Promise<boolean> => {
  return fetch(API_URL + 'addPalletProduct', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
    },
    body: JSON.stringify({
      fields: fields,
    }),
  })
    .then((response) => {
      return response.status.toString().substring(0, 1) === '2';
    })
    .catch((err) => {
      Log(2, `Error adding pallet product ${err}`);
      throw err;
    });
}; //FIXME

const search = (query: string, auth: string): Promise<any[]> => {
  let queryAnyCase = '';
  for (let i = 0; i < query.length; i++) {
    queryAnyCase += `[${query[i].toLowerCase()}${query[i].toUpperCase()}]`;
  }

  return fetch(DB_URL + ES_INDEX + '/_search', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
    },
    body: JSON.stringify({
      size: 25,
      query: {
        bool: {
          filter: {
            bool: {
              should: [
                {
                  multi_match: {
                    query: query,
                    fields: ['ASIN', 'UPC', 'LPN'],
                  },
                },
                {
                  regexp: {
                    'OTHER_BARCODES.keyword': {
                      value: `.*:[ ]${queryAnyCase}[ ]\\|.*`,
                      flags: 'ALL',
                    },
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
        },
      },
    }),
  })
    .then((response) => response.json())
    .then((response: { hits?: { hits?: any } }) => {
      if (response.hits !== undefined && response.hits.hits.length > 0) {
        return response.hits.hits;
      } else {
        return [];
      }
    })
    .catch((err) => {
      Log(2, err);
      throw err;
    });
};

// Dumb components

interface SearchResultsTableProps {
  palletSku?: string;
  results: any[];
  loading: boolean;
  setReloadProducts: any;
  addType: 'pallet' | 'individual';
  setIndividualProductInfo: Function;
  setShowForm: Function;
}

const SearchResultsTable = (props: SearchResultsTableProps) => {
  const userContext = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const results = props.results;

  if (results !== undefined && results.length > 0) {
    return (
      <table
        style={{
          borderCollapse: 'collapse',
          tableLayout: 'fixed',
          boxSizing: 'border-box',
          width: '100%',
        }}
      >
        <thead>
          <tr>
            {PALLET_SEARCH_FIELDS.map((field: any) => {
              return (
                <Styles.StyledTH
                  key={PALLET_SEARCH_FIELDS.indexOf(field)}
                  style={{
                    width: field === 'TITLE' ? '10vw' : '5vw',
                  }}
                >
                  {field.replace(/_/g, ' ')}
                </Styles.StyledTH>
              );
            })}
            <Styles.StyledTH
              style={{
                width: 100,
              }}
            >
              ACTIONS
            </Styles.StyledTH>
          </tr>
        </thead>
        <tbody>
          {results.map((result: any, top_index:number) => (
            <tr
              key={'TR-' + result._id}
              style={{ borderBottom: 'thin solid black' }}
            >
              {PALLET_SEARCH_FIELDS.map((field: any) => {
                switch(field){
                default:
                return (
                  <Styles.StyledTD key={PALLET_SEARCH_FIELDS.indexOf(field)}>
                    {result._source[field] || 'NONE'}
                  </Styles.StyledTD>
                );
                }
              })}
              <Styles.StyledTD>
                <Styles.TableButton
                  style={{
                    padding: '10px 15px 2px 0px',
                    backgroundColor: '#FFF0',
                    margin: 0,
                  }}
                  onClick={() => {
                    switch (props.addType) {
                      case 'individual':
                          props.setIndividualProductInfo({
                            pallet_individual_product_sku: '',
                            pallet_individual_product_title:
                              result._source['TITLE'],
                            pallet_individual_product_retail_price:
                              result._source['RETAIL_PRICE']?.replace(
                                /[^\d.]/g,
                                ''
                              ),
                            pallet_individual_product_retail_percentage: null,
                            pallet_individual_product_cost: null,
                          });
                          props.setShowForm(true);
                        break;
                      case 'pallet':
                        addPalletProduct(
                          {
                            pallet_sku: props.palletSku,
                            pallet_product_title: result._source['TITLE'],
                            pallet_product_retail_price: result._source[
                              'RETAIL_PRICE'
                            ]?.replace(/\$/g, ''),
                            pallet_product_retail_percentage: result._source[
                              'RGT_PERCENTAGE'
                            ]?.replace(/%/g, ''),
                            pallet_product_cost_per_unit: result._source[
                              'COST_PER_PIECE'
                            ]?.replace(/\$/g, ''),
                            pallet_product_source_load_number:
                              result._source['LOAD_NUMBER'],
                            pallet_product_quantity: 1
                          },
                          userContext.auth
                        )
                          .then((success) => {
                            if (success) {
                              enqueueSnackbar(
                                `Added product ${result._source[
                                  'TITLE'
                                ].substring(0, 25)}`,
                                { variant: 'success' }
                              );
                              props.setReloadProducts();
                            } else {
                              enqueueSnackbar(
                                `Failed to add product ${result._source[
                                  'TITLE'
                                ].substring(0, 25)}`,
                                { variant: 'error' }
                              );
                            }
                          })
                          .catch(() => {
                            enqueueSnackbar(
                              `Failed to add product ${result._source[
                                'TITLE'
                              ].substring(0, 25)}`,
                              { variant: 'error' }
                            );
                          });
                        break;
                    }
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPlusSquare}
                    style={{ width: '24px', height: '24px', marginBottom: 0 }}
                    color={Colors.green}
                  />
                  <p style={{ fontSize: 12, marginTop: 0 }}>Add</p>
                </Styles.TableButton>
              </Styles.StyledTD>
            </tr>
          ))}
        </tbody>
      </table>
    );
  } else {
    return (
      <p>
        {props.loading ? (
          <CircularProgress sx={{ mx: 'auto' }} />
        ) : (
          'No Results'
        )}
      </p>
    );
  }
};

interface PalletSearchTableProps {
  palletSku?: string;
  setReloadProducts: any;
  addType: 'pallet' | 'individual';
  showIndividualForm?: Function;
}

const PalletSearchTable = (props: PalletSearchTableProps) => {
  const userContext = useContext(UserContext);

  const searchBox = useRef(document.createElement('input'));

  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults]: [any[], any] = useState([]);
  const [loadingResults, setLoadingResults] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showForm, setShowForm] = useState(false);

  const [individualProductInfo, setIndividualProductInfo] = useState({
    pallet_individual_product_sku: '',
    pallet_individual_product_title: '',
    pallet_individual_product_retail_price: '',
    pallet_individual_product_retail_percentage: null,
    pallet_individual_product_cost: null,
  });

  useEffect(() => {
    if (!showForm) {
      const keyDownHandler = (e: KeyboardEvent) => {
        searchBox.current.focus();
        if (e.repeat && e.key === 'Enter') {
          e.preventDefault();
        }
      };
  
      window.addEventListener('keydown', keyDownHandler);
  
      return () => {
        window.removeEventListener('keydown', keyDownHandler);
      };
    }
  });

  return (
    <Styles.Page
      style={{
        flex: '0 0',
        marginTop: 40,
        marginBottom: 20,
        maxWidth: PAGE_WIDTH * 0.9,
        alignItems: 'flex-start',
      }}
    >
      <SearchBarForm
        onSubmit={(e) => {
          e.preventDefault();
          searchBox.current.focus();
          searchBox.current.select();
        }}
        style={{
          marginBottom: 40,
          width: PAGE_WIDTH * 0.9,
        }}
      >
        <TextField
          inputRef={searchBox}
          type="text"
          style={{
            marginRight: userContext.isMobile ? 0 : '10px',
            marginBottom: userContext.isMobile ? 0 : '',
            maxWidth: userContext.isMobile ? PAGE_WIDTH - 30 : '40vw',
            minWidth: userContext.isMobile ? PAGE_WIDTH - 30 : '40vw',
            height: 'auto',
          }}
          placeholder="Search all manifests"
          autoCapitalize="none"
          autoFocus={false}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <MuiButton
          variant="contained"
          style={{
            marginRight: '10px',
          }}
          className="button"
          type="submit"
          onClick={() => {
            setLoadingResults(true);
            search(searchText, userContext.auth)
              .then((results) => {
                setSearchResults(results);
                setLoadingResults(false);
              })
              .catch(() => {
                setLoadingResults(false);
                enqueueSnackbar(
                  'Search failed. Please check your internet connection.',
                  { variant: 'error' }
                );
              });
          }}
        >
          Search
        </MuiButton>
        <Styles.FlexDiv
          style={{ flexGrow: 1, justifyContent: 'space-between' }}
        >
          <MuiButton
            variant="contained"
            style={{
              marginRight: '10px',
              minWidth: userContext.isMobile
                ? (window.innerWidth - 50) / 3
                : '',
              minHeight:
                userContext.userRole === 'novotex-sorter' ? '64px' : '48px',
            }}
            className="button"
            type="submit"
            onClick={() => {
              setSearchText('');
              setSearchResults([]);
            }}
          >
            Clear
          </MuiButton>
          <MuiButton
            variant="contained"
            color="success"
            style={{
              minWidth: userContext.isMobile
                ? (window.innerWidth - 50) / 3
                : '',
              minHeight:
                userContext.userRole === 'novotex-sorter' ? '64px' : '48px',
              alignSelf: 'right',
            }}
            onClick={() => {
              setShowForm(true);
            }}
          >
            Add Unlisted Product
          </MuiButton>
        </Styles.FlexDiv>
      </SearchBarForm>
      <div
        style={{
          display: 'flex',
          flex: '1',
          minWidth: PAGE_WIDTH * 0.9,
          maxWidth: PAGE_WIDTH * 0.9,
          overflow: 'auto',
        }}
      >
        <div
          style={{
            display: 'inline',
            flex: 1,
            maxHeight: 220,
            minWidth: 1200,
          }}
        >
          <SearchResultsTable
            addType={props.addType}
            palletSku={props.palletSku}
            results={searchResults}
            loading={loadingResults}
            setReloadProducts={props.setReloadProducts}
            setIndividualProductInfo={(result: any)=> setIndividualProductInfo(result)}
            setShowForm = {(visible: boolean) => setShowForm(visible)}
          />
        </div>
      </div>
      {showForm && props.addType === 'individual' && (
          <IndividualProductForm
            visible={showForm}
            setVisible = {(visible: boolean ) => setShowForm(visible)}
            defaultProductInfo = {individualProductInfo}
            setReloadProducts={() => props.setReloadProducts((oldState: boolean) => {
              return !oldState;
            })}
          />
        )}
      {showForm && props.addType === 'pallet' && (
          <AddUnlistedPalletProduct
            setShowForm={setShowForm}
            palletSku={props.palletSku ?? ''}
            showForm = {showForm}
            setReloadProducts={() => props.setReloadProducts((oldState: boolean) => {
              return !oldState;
            })}
          />
        )}
    </Styles.Page>
  );
};

export default PalletSearchTable;
