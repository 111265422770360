import React, { useContext, useState } from 'react';
import Styles, { Colors, MuiButton } from 'Styles';
import { UserContext } from '../utilities/DataTypes';
import { formattedDate } from '../utilities/Helpers';
import { updatePallet } from '../utilities/APICalls';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import {
  Autocomplete,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  flex: 1;
  margin-bottom: 10px;
  font-family: Open Sans, sans-serif;
`;

interface EditPalletFormProps {
  setShowForm: any;
  showForm: boolean;
  palletInfo: any;
  setIsEdit: any;
  getPallets: any;
}

const statusOptions = ['Not Started', 'In Progress', 'On Hold', 'Completed'];
const destinationOptions = ['VENTAS'];

const EditPalletForm = (props: EditPalletFormProps) => {
  const [palletInfo, setPalletInfo] = useState({
    pallet_sku: props.palletInfo.pallet_sku,
    pallet_destination: props.palletInfo.pallet_destination,
    pallet_status: props.palletInfo.pallet_status,
    pallet_start_date: formattedDate(props.palletInfo.pallet_start_date),
  });
  const [showMistakes, setShowMistakes] = useState(false);
  const userContext = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Dialog
      maxWidth={'sm'}
      fullWidth
      onClose={() => props.setShowForm(false)}
      open={props.showForm}
    >
      <DialogTitle>Edit Pallet</DialogTitle>

      <DialogContent>
        <FormRow>
          <label>Pallet SKU</label>
          <TextField
            placeholder="e.g. HD-01"
            value={palletInfo.pallet_sku}
            onChange={(e) =>
              setPalletInfo({ ...palletInfo, pallet_sku: e.target.value })
            }
          />
          {showMistakes && palletInfo.pallet_sku.length === 0 && (
            <Styles.Text style={{ margin: 0, color: Colors.red }}>
              Pallet SKU required
            </Styles.Text>
          )}
        </FormRow>

        <FormRow>
          <label>Destination</label>
          <Autocomplete
            disabled
            id="destination"
            options={destinationOptions}
            renderInput={(params) => <TextField {...params} />}
            // inputValue={palletInfo.pallet_destination}
            defaultValue={destinationOptions[0]}
            onChange={(event, newValue) => {
              setPalletInfo({
                ...palletInfo,
                pallet_destination: newValue,
              });
            }}
            disableClearable
          />
        </FormRow>

        <FormRow>
          <label>Start Date</label>
          <DatePicker
            value={palletInfo.pallet_start_date}
            renderInput={(params) => (
              <TextField {...params} sx={{ width: 175 }} />
            )}
            onChange={(newValue) => {
              setPalletInfo({
                ...palletInfo,
                pallet_start_date: formattedDate(newValue),
              });
            }}
          />
        </FormRow>

        <FormRow>
          <label>Status</label>
          <Autocomplete
            disablePortal
            id="status"
            options={statusOptions}
            renderInput={(params) => <TextField {...params} />}
            defaultValue={palletInfo.pallet_status}
            onChange={(event, newValue) => {
              setPalletInfo({ ...palletInfo, pallet_status: newValue });
            }}
            disableClearable
          />
        </FormRow>
      </DialogContent>

      <DialogActions
        sx={{
          m: 2,
        }}
      >
        <MuiButton
          variant="contained"
          color="info"
          onClick={(_) => {
            props.setShowForm(false);
            props.setIsEdit(false);
          }}
        >
          Cancel
        </MuiButton>
        <MuiButton
          variant="contained"
          disabled={
            (palletInfo.pallet_sku.length <= 0 ||
              palletInfo.pallet_destination.length <= 0 ||
              palletInfo.pallet_start_date.length <= 0 ||
              palletInfo.pallet_status.length <= 0) &&
            showMistakes
          }
          onClick={(_) => {
            if (
              palletInfo.pallet_sku.length <= 0 ||
              palletInfo.pallet_destination.length <= 0 ||
              palletInfo.pallet_start_date.length <= 0 ||
              palletInfo.pallet_status.length <= 0
            ) {
              setShowMistakes(true);
            } else {
              updatePallet(
                props.palletInfo.pallet_sku,
                palletInfo,
                userContext.auth
              ).then((success) => {
                if (success) {
                  enqueueSnackbar(
                    `Updated pallet ${palletInfo.pallet_sku} in database.`,
                    { variant: 'success' }
                  );
                  props.setShowForm(false);
                  props.getPallets();
                } else {
                  enqueueSnackbar(
                    'Error updating pallet. Please check your internet connection.',
                    { variant: 'error' }
                  );
                }
              });
            }
          }}
        >
          Save
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditPalletForm;
