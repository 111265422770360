import React, { useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import Styles, { Colors, MuiButton } from 'Styles';
import { FeedbackType, UserContext } from '../utilities/DataTypes';
import { submitRGTFeedback } from '../utilities/APICalls';
import styled from 'styled-components';
import {
  Autocomplete,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 10px;
`;

const rejectOptions = {
  Defect: 'Reject-D',
  Value: 'Reject-V',
  Weight: 'Reject-W',
  Condition: 'Reject-C',
  Fraud: 'Reject-F'
};
interface RGTMissingItemFormProps {
  isRejecting: boolean;
  setShowMissingItemForm: any;
  showMissingItemForm: boolean;
}

const isASIN = (value: string): boolean => {
  return value.replace(' ', '').length <= 10;
};

const asinMessage = (value: string): string => {
  if (value.length > 12 || value.length < 10 || value.length === 11) {
    return 'Value must be 10 or 12 characters';
  }
  if (value.length === 12 && !/^\d*$/.test(value)) {
    return 'UPC can only contain numbers';
  }
  return '';
};

const RGTMissingItemForm = (props: RGTMissingItemFormProps) => {
  const [record, setRecord]: [
    {
      ASIN: string;
      UPC: string;
      RETAIL_PRICE: string;
      LOAD_NAME: string;
      SOURCE: string;
      TITLE: string;
      DESTINATION: string;
    },
    any
  ] = useState({
    ASIN: '',
    UPC: '',
    RETAIL_PRICE: '',
    LOAD_NAME: '',
    SOURCE: '',
    TITLE: '',
    DESTINATION: '',
  });
  const [rejectReason, setRejectReason]: [FeedbackType, any] = useState('Accept');
  const [count, setCount]: [number, any] = useState(0);
  const [showMistakes, setShowMistakes] = useState(false);
  const userContext = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Dialog
      maxWidth={'sm'}
      fullWidth
      onClose={() => props.setShowMissingItemForm(false)}
      open={props.showMissingItemForm}
      style={{
        fontFamily: 'Open Sans, sans-serif',
      }}
    >
      <DialogTitle>
        {props.isRejecting ? 'Reject' : 'Accept'} Unlisted Item
      </DialogTitle>
      <DialogContent>
        <StyledDiv>
          <label>
            {record.ASIN.length >= 10
              ? 'ASIN'
              : record.UPC.length >= 12
              ? 'UPC'
              : 'UPC or ASIN'}
          </label>
          <TextField
            placeholder="##########"
            value={record.ASIN.length > 0 ? record.ASIN : record.UPC}
            onChange={(e) =>
              isASIN(e.target.value)
                ? setRecord({
                    ...record,
                    ASIN: e.target.value,
                    UPC: '',
                  })
                : setRecord({ ...record, ASIN: '', UPC: e.target.value })
            }
          />
          {showMistakes && record.ASIN.length + record.UPC.length > 0 && (
            <Styles.Text
              style={{
                margin: 0,
                color: Colors.red,
              }}
            >
              {asinMessage(record.ASIN.length > 0 ? record.ASIN : record.UPC)}
            </Styles.Text>
          )}
        </StyledDiv>

        <StyledDiv>
          <label>Product Title</label>
          <TextField
            placeholder="Enter product title..."
            onChange={(e) => setRecord({ ...record, TITLE: e.target.value })}
          />
        </StyledDiv>

        <StyledDiv>
          <label>Load Name</label>
          <TextField
            placeholder="Enter load name..."
            onChange={(e) => setRecord({ ...record, LOAD_NAME: e.target.value })}
          />
        </StyledDiv>

        <StyledDiv>
          <label>Retail Price</label>
          <TextField
            placeholder="$0.00"
            value={'$' + record.RETAIL_PRICE}
            onChange={(e) =>
              setRecord({
                ...record,
                RETAIL_PRICE: e.target.value.replace(/[^\d.]/g, ''),
              })
            }
          />
          {showMistakes && record.RETAIL_PRICE.length === 0 && (
            <Styles.Text style={{ margin: 0, color: Colors.red }}>
              Retail Price required
            </Styles.Text>
          )}
        </StyledDiv>

        {props.isRejecting && (
          <StyledDiv>
            <label>Reject Reason</label>
            <Autocomplete
              disablePortal
              id="reject"
              options={Object.entries(rejectOptions).map(
                ([key, value]) => `${key}`
              )}
              renderInput={(params) => <TextField {...params} />}
              // inputValue={loadInfo.load_status}
              onChange={(event, newValue) => {
                setRejectReason(
                  rejectOptions[newValue as keyof typeof rejectOptions]
                );
              }}
              disableClearable
              // sx={{ width: 150 }}
            />
          </StyledDiv>
        )
        }
        {rejectReason !== 'Accept' && (
          <StyledDiv>
          <label>Reject Feedback</label>
          <TextField
            placeholder="Enter reason for rejecting..."
            onChange={(e) => setRecord({ ...record, reject_reason: e.target.value })}
          />
        </StyledDiv>
        )}
        <StyledDiv style={{ marginBottom: 0 }}>
          <label>Quantity</label>
          <TextField
            placeholder="#"
            value={count > 0 ? count : ''}
            onChange={(e) =>
              setCount(parseInt(e.target.value.replace(/^\D/g, '')))
            }
          />
          {showMistakes && !(count > 0) && (
            <Styles.Text style={{ margin: 0, color: Colors.red }}>
              Quantity required
            </Styles.Text>
          )}
        </StyledDiv>
      </DialogContent>

      <DialogActions
        sx={{
          m: 2,
        }}
      >
        <MuiButton
          variant="contained"
          color="info"
          onClick={(_) => props.setShowMissingItemForm(false)}
        >
          Cancel
        </MuiButton>
        <MuiButton
          variant="contained"
          disabled={
            showMistakes &&
            !(
              rejectReason.length > 0 &&
              count > 0 &&
              (record.ASIN.length === 10 ||
                (record.UPC.length === 12 && /^\d*$/.test(record.UPC)) ||
                record.ASIN.length + record.UPC.length <= 0) &&
              record.RETAIL_PRICE.length > 0
            )
          }
          // style={{ marginLeft: '20px' }}
          onClick={async() => {
            if (
              rejectReason.length > 0 &&
              count > 0 &&
              (record.ASIN.length === 10 ||
                (record.UPC.length === 12 && /^\d*$/.test(record.UPC)) ||
                record.ASIN.length + record.UPC.length <= 0) &&
              record.RETAIL_PRICE.length > 0
            ) {
              await submitRGTFeedback(
                rejectReason,
                count,
                userContext.accountName,
                userContext.auth,
                record,
                enqueueSnackbar,
                true
              );
              setTimeout(()=>{window.location.reload();}, 1500);
              props.setShowMissingItemForm(false);
            } else {
              setShowMistakes(true);
            }
          }}
        >
          Submit
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
};

export default RGTMissingItemForm;
