import React, { useContext, useEffect, useState } from 'react';
import { PAGE_WIDTH, UserContext } from '../utilities/DataTypes';
import Styles, { Colors } from 'Styles';
import { API_URL } from '../env';
import Log from './Logger';
import {
  colorByPercent,
  getBFHeaderColors,
  useIsMounted,
} from '../utilities/Helpers';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';

interface TableProps {
  itemCount: number;
}

const StyledTable = styled.table<TableProps>`
  border-collapse: collapse;
  table-layout: fixed;
  width: ${(props: TableProps) => props.itemCount * 130}px;
`;

const StyledCaption = styled.caption`
  font-size: 20px;
  text-align: left;
  font-family: Poppins, sans-serif;
  padding: 5px;
`;

interface LoadPositivityProps {
  loadName: string;
}

const getDestinations = (auth: string): Promise<Array<any>> => {
  return fetch(API_URL + 'getDestinations', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
    },
    body: JSON.stringify({
      active: true,
    }),
  })
    .then((response) => response.json())
    .then((response: { destinations: any }) => {
      return response.destinations;
    });
};

const getScanDestinations = (
  loadName: string,
  auth: string
): Promise<Array<any>> => {
  return fetch(API_URL + 'getScanDestinations', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: auth,
    },
    body: JSON.stringify({
      load_name: loadName,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      Log(
        0,
        `getScanDestinations response: ${JSON.stringify(
          response.table_data[0]
        )}`
      );
      if (response.table_data.length > 0) {
        return response.table_data;
      } else {
        Log(1, 'No data from getScanDestinations');
        return [{ NAME: 'NONE' }];
      }
    })
    .catch((err) => {
      Log(2, err);
      return [{ NAME: 'ERROR' }];
    });
};

const LoadPositivity = (props: LoadPositivityProps) => {
  const [tableData, setTableData]: [Array<any>, any] = useState([]);
  const [refreshLoop, setRefreshLoop] = useState(false);
  const [fields, setFields]: [any, any] = useState([]);
  const userContext = useContext(UserContext);
  const isMounted = useIsMounted();
  useEffect(() => {
    if (isMounted()) {
      setTableData([]);
      getDestinations(userContext.auth).then((response: any[]) => {
        let destinations: any[] = [];
        response.forEach((destination) => {
          destinations.push(destination.destination_name);
        });
        setFields([
          'NAME',
          ...destinations,
          'NOT_FOUND',
          'ITEMS_SCANNED',
          'POSITIVITY_RATE',
        ]);
      });
      getScanDestinations(props.loadName, userContext.auth).then(
        (response: Array<any>) => {
          setTableData(response);
        }
      );
    }
  }, [props.loadName, userContext.auth, isMounted]);

  useEffect(() => {
    if (isMounted() && refreshLoop) {
      setRefreshLoop(false);
      getDestinations(userContext.auth).then((response: any[]) => {
        let destinations: any[] = [];
        response.forEach((destination) => {
          destinations.push(destination.destination_name);
        });
        setFields([
          'NAME',
          ...destinations,
          'NOT_FOUND',
          'ITEMS_SCANNED',
          'POSITIVITY_RATE',
        ]);
      });
      getScanDestinations(props.loadName, userContext.auth).then(
        (response: Array<any>) => {
          if (isMounted()) {
            setTableData(response);
          }
        }
      );
    }
  }, [isMounted, refreshLoop, props.loadName, userContext.auth]);

  useEffect(() => {
    if (isMounted() && !refreshLoop) {
      setTimeout(() => setRefreshLoop(true), 60000);
    }
  }, [isMounted, refreshLoop]);

  let itemRows: Array<any> = [];

  if (
    tableData.length > 0 &&
    tableData[0].hasOwnProperty('NAME') &&
    tableData[0].NAME !== 'ERROR' &&
    tableData[0].NAME !== 'NONE'
  ) {
    itemRows = tableData.map((result: any, index: any) => (
      <tr key={index} style={{ borderBottom: 'thin solid grey' }}>
        {fields.map((field: string) => {
          if (field === 'POSITIVITY_RATE') {
            return (
              <Styles.StyledTD
                key={'POSITIVITY-ROW-' + field + index}
                style={{
                  backgroundColor:
                    colorByPercent(parseInt(result[field].split('.')[0])) +
                    'AA',
                }}
              >
                {result[field] || 0}
              </Styles.StyledTD>
            );
          } else {
            return (
              <Styles.StyledTD key={'POSITIVITY-ROW-' + field + index}>
                {result[field] || 0}
              </Styles.StyledTD>
            );
          }
        })}
      </tr>
    ));
    return (
      <Styles.Page
        style={{
          flex: '0 0 auto',
          minHeight: 0,
          minWidth: PAGE_WIDTH * 0.7,
          marginBottom: 30,
          alignItems: 'flex-start',
        }}
      >
        <StyledTable itemCount={fields.length}>
          <StyledCaption>Sorter Totals</StyledCaption>
          <thead>
            <tr>
              {fields.map((field: string, index: number) => {
                return (
                  <Styles.StyledTH
                    style={{
                      width: '100%',
                      fontSize: '16px',
                      backgroundColor: getBFHeaderColors(field),
                    }}
                    key={'POSITIVITY-HEADER-' + index}
                  >
                    {field
                      .replace(/_/g, ' ')
                      .replace(/BLACK FRIDAYS.*/, 'BLACK FRIDAYS')}
                  </Styles.StyledTH>
                );
              })}
            </tr>
          </thead>
          <tbody>{itemRows}</tbody>
        </StyledTable>
      </Styles.Page>
    );
  } else if (
    tableData.length > 0 &&
    tableData[0].hasOwnProperty('NAME') &&
    tableData[0].NAME === 'ERROR'
  ) {
    return (
      <Styles.Page>
        <Styles.Text style={{ color: Colors.red }}>
          Error loading data.
        </Styles.Text>
      </Styles.Page>
    );
  } else if (
    tableData.length > 0 &&
    tableData[0].hasOwnProperty('NAME') &&
    tableData[0].NAME === 'NONE'
  ) {
    return (
      <Styles.Page>
        <Styles.Text>No scanned items</Styles.Text>
      </Styles.Page>
    );
  } else {
    return (
      <Styles.Page>
        <CircularProgress sx={{ mx: 'auto' }} />
      </Styles.Page>
    );
  }
};

export default LoadPositivity;
