import { Link } from 'react-router-dom';
import styled from 'styled-components';
import './index.css';
import { PAGE_HEIGHT, PAGE_WIDTH } from './utilities/DataTypes';
import { Button, ButtonProps } from '@mui/material';

const Colors: any = {
  // Main colors:
  green: '#53a451',
  red: '#be3634',
  yellow: '#ffca42',
  gray: '#969696',
  // Specific colors:
  text: '#222222',
  altText: '#ffffff',
  header: '#0d74ae',
  button: '#0d74ae',
  buttonDisabled: '#325c73',
  darkGray: '#333',
  greenBlue: '#17A2B8',
  // Destination based colors:
  blackfridaysblue: '#00ffff',
  blackfridaysgreen: '#00ff00',
  blackfridaysyellow: '#ffff00',
};

const Styles = {
  Text: styled.p`
    font-family: Open Sans, sans-serif;
    color: ${Colors.text};
    font-size: 16px;
  `,
  TextBold: styled.b`
    font-family: Open Sans, sans-serif;
    color: ${Colors.text};
    font-size: 16px;
    font-weight: bold;
  `,
  Page: styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    font-family: Open Sans, sans-serif;
    max-width: ${PAGE_WIDTH}px;
    max-height: ${PAGE_HEIGHT}px;
  `,
  CategoriesPage: styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    font-family: Open Sans, sans-serif;
    max-height: ${PAGE_HEIGHT}px;
  `,
  StyledTD: styled.td`
    font-family: 'Open Sans', sans-serif;
    white-space: nowrap;
    font-size: 16px;
    padding: 5px 10px 5px 10px;
    display: table-cell;
    max-width: 30vw;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  StyledTH: styled.th`
    font-size: 20px;
    font-family: Poppins, sans-serif;
    background-color: rgb(245, 245, 250);
    border-bottom: 2px solid rgb(235, 235, 240);
    padding: 10px;
    cursor: pointer;
    color: black;
    text-align: left;
  `,
  StyledButton: styled.button`
    font-size: 16px;
    border: none;
    background-color: ${Colors.button};
    color: ${Colors.altText};
    border-radius: 8px;
    padding: 14px 20px 14px 20px;
    cursor: pointer;
    outline-width: 0;
    min-height: 48px;
    max-height: 48px;

    &:disabled {
      color: #aaa;
      background-color: ${Colors.buttonDisabled};
    }
  `,
  TableButton: styled.button`
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
    border: none;
    background-color: #fff0;
    outline-width: 0;
  `,
  StyledLI: styled.li`
    font-size: 16px;
    font-family: Open Sans, sans-serif;
    margin: 5px;
    list-style: none;
  `,
  NavLink: styled(Link)`
    font-size: 16px;
    font-family: Open Sans, sans-serif;
    text-decoration: none;
    color: ${Colors.altText};
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: center;
    outline-width: 0;
    margin: 0;
    padding: 0 10px;
  `,
  PageHeader: styled.h2`
    color: ${Colors.text};
    font-family: Poppins, sans-serif;
    margin-bottom: 0;
  `,
  TextBox: styled.input`
    padding: 10px;
    font-size: 16px;
    color: ${Colors.text};
    font-family: Open Sans, sans-serif;
    border-radius: 4px;
    border: thin solid ${Colors.gray};
    outline-width: 0;

    &:disabled {
      color: ${Colors.gray};
    }
  `,
  Date: styled.input`
    padding: 10px;
    font-size: 16px;
    color: ${Colors.text};
    font-family: Open Sans, sans-serif;
    border-radius: 4px;
    border: thin solid ${Colors.gray};
    outline-width: 0;
  `,
  CheckBox: styled.input.attrs((_) => ({
    type: 'checkbox',
    value: true,
  }))`
    padding: 10px;
    color: ${Colors.text};
    border-radius: 4px;
    border: thin solid ${Colors.gray};
    outline-width: 0;
    width: 24px;
    height: 24px;

    &:disabled {
      color: ${Colors.gray};
    }
  `,
  Dropdown: styled.select`
    padding: 10px;
    font-size: 16px;
    color: ${Colors.text};
    font-family: Open Sans, sans-serif;
    border-radius: 4px;
    border: thin solid ${Colors.gray};
    outline-width: 0;
  `,
  StyledUl: styled.ul`
    list-style-type: none;
    margin-top: 0;
    padding-top: 0;
  `,
  FlexDiv: styled.div`
    display: flex;
    flex-direction: row;
  `,
  Print: styled.div`
    display: none;
    @media print {
      display: block;
    }
  `,
};
const MuiButton = styled(Button)<ButtonProps>(({ theme }) => ({
  // padding: '14px 20px 14px 20px',
  // outlineWidth: 0,
  minHeight: '48px',
  maxHeight: '48px',
}));

export { MuiButton };
export { Colors };
export default Styles;
