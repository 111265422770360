import React, { useContext, useEffect, useRef, useState } from 'react';
import Styles, { MuiButton } from '../Styles';
import Log from 'components/Logger';
import {
  PAGE_HEIGHT,
  PAGE_WIDTH,
  SizeProps,
  SUPPORTED_IMPORT_FIELDS,
  UserContext,
} from '../utilities/DataTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { getDestinations, sendFile, upload } from 'utilities/APICalls';

const StyledP = styled.p`
  margin: 0;
  display: flex;
  justify-content: flex-start;
  flex: 1;
  font-size: 16px;
`;
const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 5px 10px 5px;
  font-family: Open Sans, sans-serif;
`;

const SelectFieldsContainer = styled.div<SizeProps>`
  max-height: ${(props) => props.pageHeight * 0.8}px;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 20vw;
  overflow-y: scroll;
  margin: 10px 10px 30px;
  border-radius: 4px;
  box-shadow: 0 0 5px #0003;
  padding: 30px;
`;
const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const ListContainer = styled.div<SizeProps>`
  max-width: ${(props) => props.pageWidth * 0.9}px;
  max-height: ${(props) => props.pageHeight * 0.8}px;
  overflow: scroll;
  border-radius: 4px;
`;

/**
 * Import CSV files, preview their data, and send them to the API for upload.
 */
const Import = () => {
  const [selectedFile, setSelectedFile] = useState('');
  const [fileData, setFileData] = useState([]);
  const [uploaded, setUploaded] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [mapFields, setMapFields]: [Array<any>, any] = useState([]);
  const [columns, setColumns]: [any, any] = useState({});
  const [otherBarcodes, setOtherBarcodes]: [Array<any>, any] = useState([]);
  const [fieldsSubmitted, setFieldsSubmitted]: [any, any] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [forceLoadName, setForceLoadName] = useState('');
  const [forceLoadSource, setForceLoadSource] = useState('');
  const [destinations, setDestinations]: [any, any] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  //code block triggers when user selects a file, handleSubmit populates mapFields
  useEffect(() => {
    getDestinations(userContext.auth)
      .then((response) => response.json())
      .then((response: { destinations: any[] }) => {
        let dests: any = {};
        response.destinations.forEach((destination: any) => {
          dests[destination.destination_name] = destination.destination_color;
        });
        setDestinations(dests);
      });
    SUPPORTED_IMPORT_FIELDS.forEach((field: string) => {
      let value = getDefault(field); //returns mapFields column header that matches supported_import_fields by string
      if (value.length <= 0) value = 'None';
      setColumn(field, value); //adds object [field]:map to columns state object
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapFields]);

  useEffect(() => {
    let params = new URLSearchParams(history.location.search);
    if (params.has('load_name')) {
      setForceLoadName(params.get('load_name') || '');
    }
    if (params.has('load_source')) {
      setForceLoadSource(params.get('load_source') || '');
    }
  }, [history.location.search]);

  useEffect(() => {
    if (errorMsg.length > 0) {
      enqueueSnackbar(errorMsg, { variant: 'error' });
    }
  }, [errorMsg, enqueueSnackbar]);

  const userContext = useContext(UserContext);

  const fileInput = useRef<HTMLInputElement>(document.createElement('input'));
  const inputForm = useRef<HTMLFormElement>(document.createElement('form'));

  const listPreview = () => {
    let list: Array<any> = [];
    if (fileData.length > 0) {
      list = fileData;
    }

    let items = list.map((item: any) => (
      <tr
        key={list.indexOf(item).toString()}
        style={{ borderBottom: 'thin solid black', padding: '10px' }}
      >
        <Styles.StyledTD
          style={{
            fontSize: '20px',
            backgroundColor: destinations[item.DESTINATION] || '#f00',
          }}
        >
          {item.DESTINATION}
        </Styles.StyledTD>
        <Styles.StyledTD>{item.UPC}</Styles.StyledTD>
        <Styles.StyledTD style={{ whiteSpace: 'normal' }}>
          {item.TITLE}
        </Styles.StyledTD>
        <Styles.StyledTD>{item.ASIN}</Styles.StyledTD>
        <Styles.StyledTD>{item.SOURCE}</Styles.StyledTD>
        <Styles.StyledTD>{item.RETAIL_PRICE}</Styles.StyledTD>
        <Styles.StyledTD>{item.RGT_PRICE}</Styles.StyledTD>
      </tr>
    ));
    if (fileData.length > 0) {
      return (
        <table
          style={{
            borderCollapse: 'collapse',
            tableLayout: 'auto',
            width: '90vw',
          }}
        >
          <thead>
            <tr style={{ backgroundColor: '#5AF' }}>
              <Styles.StyledTH style={{ fontSize: '20px', width: '20%' }}>
                DESTINATION
              </Styles.StyledTH>
              <Styles.StyledTH>UPC</Styles.StyledTH>
              <Styles.StyledTH>TITLE</Styles.StyledTH>
              <Styles.StyledTH>ASIN</Styles.StyledTH>
              <Styles.StyledTH>SOURCE</Styles.StyledTH>
              <Styles.StyledTH>RETAIL PRICE</Styles.StyledTH>
              <Styles.StyledTH>RGT PRICE</Styles.StyledTH>
            </tr>
          </thead>
          <tbody>{items}</tbody>
        </table>
      );
    } else {
      return <p>{selectedFile.length > 0 ? 'Loading Data Preview' : ''}</p>;
    }
  };

  const handleSubmit = (event: any) => {
    setUploaded(false);
    setUploading(false);
    setErrorMsg('');
    event.preventDefault();
    if (fileInput.current.files)
      setSelectedFile(fileInput.current.files[0].name);
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let fields: Array<any> = ['None'];
      let hasLetters = /[a-zA-Z]+/;
      //gets first row of csv file and gets fields
      for (const item of e.target.result.split('\n')[0].split(',')) {
        if (item.length > 0 && hasLetters.test(item)) {
          fields.push(item);
        }
      }
      setMapFields(fields);
    };
    if (fileInput.current.files && mapFields.length <= 0)
      reader.readAsText(fileInput.current.files[0]);
  };

  const runSubmit = () => {
    setUploaded(false);
    setUploading(false);
    setErrorMsg('');
    if (
      fileInput.current.files &&
      (fileInput.current.files[0].type === 'text/csv' ||
        fileInput.current.files[0].type === 'application/vnd.ms-excel')
    ) {
      let reader = new FileReader();

      reader.onload = (e: any) => {
        let requestBody: any = {
          file: e.target.result,
          columns: columns,
          other_barcodes: otherBarcodes,
        };
        if (forceLoadName.length > 0) {
          requestBody.force_load_name = forceLoadName;
        }
        if (forceLoadSource.length > 0) {
          requestBody.force_load_source = forceLoadSource;
        }

        sendFile(userContext.auth, requestBody)
          .then((response) => response.json())
          .then((response) => {
            Log(0, response);
            if (selectedFile.length > 0) {
              setFileData(response);
            }
          })
          .catch((error) => {
            Log(2, 'sendFile error:' + error);
            setErrorMsg(
              'Error processing manifest. Make sure all fields are mapped correctly.'
            );
          });
      };
      reader.readAsText(fileInput.current.files[0]);
    } else {
      alert('Invalid file type. Please submit a ".csv" file.');
      inputForm.current.reset();
      setSelectedFile('');
      setFileData([]);
      setFieldsSubmitted(false);
    }
  };

  const uploadData = () => {
    if (fileData.length > 0) {
      setUploading(true);
      upload(userContext.auth)
        .then(() => {
          setUploaded(true);
          setUploading(false);
          setSelectedFile('');
          setFileData([]);
          setFieldsSubmitted(false);
          inputForm.current.reset();
          if (
            new URLSearchParams(history.location.search).get('return') ===
            'true'
          ) {
            enqueueSnackbar('Successfully imported load', {
              variant: 'success',
            });
            history.push('/loads');
          }
        })
        .catch((error) => {
          Log(2, 'upload error: ' + error);
          setErrorMsg(
            'Error uploading manifest. Please check your internet connection.'
          );
        });
    }
  };

  const cancel = () => {
    setSelectedFile('');
    setFileData([]);
    setMapFields([]);
    setFieldsSubmitted(false);
    setColumns({});
    setUploading(false);
    setUploaded(false);
    setOtherBarcodes([]);
    inputForm.current.reset();
  };

  const setColumn = (column: string, value: string) => {
    if (column !== 'None') {
      setColumns(Object.assign(columns, { [column]: value }));
    }
    if (value === 'None') {
      let newColumns = columns;
      delete newColumns[column];
      setColumns(newColumns);
    }
  };

  const addOtherBarcode = (column: string) => {
    if (column !== 'None' && !otherBarcodes.includes(column)) {
      setOtherBarcodes((currentState: Array<string>) => [
        ...currentState,
        column,
      ]);
    }
  };

  const removeOtherBarcode = (column: string) => {
    setOtherBarcodes(otherBarcodes.filter((item) => item !== column));
  };

  const getFields = () => {
    return (
      <div>
        {SUPPORTED_IMPORT_FIELDS.map((item: string) => {
          if (item === 'OTHER_BARCODES') {
            return (
              <div style={{ marginBottom: '0px', padding: '0px' }}>
                <FormRow
                  style={{
                    minWidth: userContext.isMobile
                      ? PAGE_WIDTH * 0.95
                      : PAGE_WIDTH * 0.5,
                  }}
                >
                  <StyledP>{item.toUpperCase()}:</StyledP>
                  <Autocomplete
                    disableClearable
                    options={mapFields}
                    renderInput={(params) => <TextField {...params} />}
                    defaultValue={'Select column to add'}
                    onChange={(event, newValue) => {
                      addOtherBarcode(newValue);
                    }}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flex: '1',
                    }}
                  />
                </FormRow>
                <FormRow
                  style={{
                    minWidth: userContext.isMobile
                      ? PAGE_WIDTH * 0.95
                      : PAGE_WIDTH * 0.5,
                  }}
                >
                  {otherBarcodes.map((column: any) => (
                    <MuiButton
                      onClick={() => removeOtherBarcode(column)}
                      variant="contained"
                      style={{
                        marginRight: '10px',
                        minHeight: '36px',
                        maxHeight: '36px',
                        flexShrink: 0,
                        whiteSpace: 'nowrap',
                        flexWrap: 'nowrap',
                      }}
                    >
                      {column}
                      <FontAwesomeIcon
                        icon={faTimes}
                        style={{ marginLeft: '5px', color: 'white' }}
                      />
                    </MuiButton>
                  ))}
                </FormRow>
              </div>
            );
          } else {
            return (
              <FormRow
                style={{
                  minWidth: userContext.isMobile
                    ? PAGE_WIDTH * 0.95
                    : PAGE_WIDTH * 0.5,
                }}
              >
                <StyledP>{item.toUpperCase()}:</StyledP>
                <Autocomplete
                  options={mapFields}
                  renderInput={(params) => <TextField {...params} />}
                  // inputValue={categoryValue}
                  defaultValue={getDefault(item)}
                  onChange={(event, newValue) => {
                    setColumn(item, newValue);
                  }}
                  disableClearable
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flex: '1',
                  }}
                />
              </FormRow>
            );
          }
        })}
      </div>
    );
  };

  const getDefault = (search: string) => {
    let searchSimple = search.toLowerCase().replace(/[^a-z]+/g, ''); //searchSimple is the lowercase of each DEFAULT_IMPORT_FIELD
    let optionsSimple = [...mapFields]; //optionsSimple has every item in the first row of the csv file
    for (let i = 0; i < optionsSimple.length; i++) {
      optionsSimple[i] = optionsSimple[i].toLowerCase();
      optionsSimple[i] = optionsSimple[i].replace(/[^a-z]+/g, '');
      if (
        optionsSimple[i] === searchSimple ||
        optionsSimple[i] === searchSimple.replace('loadname', 'loadnumber')
      ) {
        if (mapFields[i].length > 0) {
          return mapFields[i];
        } else {
          return 'None';
        }
      }
    }
    return 'None';
  };

  return (
    <Styles.Page
      style={{
        minWidth: PAGE_WIDTH,
        maxWidth: PAGE_WIDTH,
        minHeight: PAGE_HEIGHT,
        maxHeight: PAGE_HEIGHT,
      }}
    >
      <Styles.PageHeader>Import File</Styles.PageHeader>
      <MuiButton
        variant="contained"
        onClick={() => fileInput.current.click()}
        style={{
          display: selectedFile.length > 0 ? 'none' : '',
        }}
      >
        <input
          disabled={uploading}
          style={{ display: 'none', width: 0, height: 0 }}
          type="file"
          accept=".csv,"
          ref={fileInput}
          onChange={(e: any) => handleSubmit(e)}
        />
        Select File
      </MuiButton>
      {selectedFile}
      <StyledDiv
        style={{
          flexDirection: 'row',
          minWidth: '20vw',
          justifyContent: 'space-between',
        }}
      >
        <MuiButton
          variant="contained"
          disabled={fileData.length <= 0}
          style={{
            display: selectedFile.length > 0 ? '' : 'none',
            marginRight: '10px',
            flex: '1',
          }}
          onClick={() => uploadData()}
        >
          Upload
        </MuiButton>
        <MuiButton
          variant="contained"
          color="error"
          disabled={selectedFile.length <= 0 || uploading}
          style={{
            display: selectedFile.length > 0 ? '' : 'none',
            flex: '1',
          }}
          onClick={() => cancel()}
        >
          Cancel
        </MuiButton>
      </StyledDiv>

      {uploading && (
        <FormRow>
          <CircularProgress sx={{ mx: 'auto' }} />
          <Styles.Text style={{ marginLeft: '10px' }}>
            Uploading Manifest
          </Styles.Text>
        </FormRow>
      )}
      {uploaded && <Styles.Text>Uploaded Successfully</Styles.Text>}
      <SelectFieldsContainer
        pageWidth={PAGE_WIDTH}
        pageHeight={PAGE_HEIGHT}
        style={{
          display:
            fileData.length > 0 || selectedFile.length <= 0 || fieldsSubmitted
              ? 'none'
              : 'flex',
        }}
      >
        <p>Select the fields you want to upload.</p>
        {mapFields.length > 0 && getFields()}
        <MuiButton
          variant="contained"
          style={{ marginTop: '5px' }}
          onClick={() => {
            setFieldsSubmitted(true);
            runSubmit();
          }}
        >
          Submit
        </MuiButton>
      </SelectFieldsContainer>
      <ListContainer
        pageWidth={PAGE_WIDTH}
        pageHeight={PAGE_HEIGHT}
        style={{ display: fieldsSubmitted ? '' : 'none' }}
      >
        {listPreview()}
      </ListContainer>
    </Styles.Page>
  );
};

export default Import;
