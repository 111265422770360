import Styles, { MuiButton } from 'Styles';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import LoadsTable from '../components/LoadsTable';
import LoadPositivity from '../components/LoadPositivity';
import LoadTotals from '../components/LoadTotals';
import LoadDetails from '../components/LoadDetails';
import {
  LoadInfoRow,
  PAGE_HEIGHT,
  PAGE_WIDTH,
  SizeProps,
  THIRTY_DAYS_AGO,
  UserContext,
} from '../utilities/DataTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import LoadForm from '../components/LoadForm';
import LoadViewFull from '../components/LoadViewFull';
import styled from 'styled-components';
import { Autocomplete, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { formattedDate } from 'utilities/Helpers';

const FiltersContainer = styled.div<SizeProps>`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
  max-width: ${(props) => props.pageWidth * 0.9}px;
  min-width: ${(props) => props.pageWidth * 0.9}px;
  min-height: ${(props) => props.pageHeight * 0.1}px;
  max-height: ${(props) => props.pageHeight * 0.1}px;
`;

const AddLoadContainer = styled.div<SizeProps>`
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  min-height: ${(props) => props.pageHeight * 0.1}px;
  max-height: ${(props) => props.pageHeight * 0.1}px;
  min-width: ${(props) => props.pageWidth * 0.1}px;
`;
const MainTableContainer = styled.div<SizeProps>`
  display: flex;
  flex-direction: column;
  flex: 20 1 0;
  max-width: ${(props) => props.pageWidth * 0.9}px;
  min-width: ${(props) => props.pageWidth * 0.9}px;
  min-height: 0;
  overflow: scroll;
  padding: 30px;
  border-radius: 4px;
  box-shadow: 0 0 5px #0003;
`;
const ReportsContainer = styled.div<SizeProps>`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: 0;
  min-width: ${(props) => props.pageWidth}px;
  min-height: ${(props) => props.pageHeight}px;
`;

const DetailsLink = styled(Link)`
  align-self: flex-end;
  margin-right: 50px;
  margin-bottom: 0;
`;

const InfoContainer = styled.div<SizeProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 3;
  min-width: ${(props) => props.pageWidth * 0.75}px;
  max-width: ${(props) => props.pageWidth * 0.75}px;
  margin-top: 15px;
  margin-left: 15px;
`;

const FullViewContainer = styled.div<SizeProps>`
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: ${(props) => props.pageWidth}px;
  max-width: ${(props) => props.pageWidth}px;
  min-height: ${(props) => props.pageHeight * 0.9}px;
  max-height: ${(props) => props.pageHeight * 0.9}px;
`;
const NewLoadPopup = styled.div<SizeProps>`
  position: absolute;
  z-index: 100;
  margin-top: ${(props) => props.pageHeight * 0.1}px;
`;

const statusOptions = [
  'All',
  'Not Started',
  'In Progress',
  'On Hold',
  'Completed',
  'Undated',
  '30 Days'
];

const Loads = () => {
  const history = useHistory();
  const [startDate, setStartDate]: [string, any] = useState(
    THIRTY_DAYS_AGO.toISOString().slice(0, 10)
  );
  const [endDate, setEndDate]: [string, any] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [status, setStatus]: [string, any] = useState('30 Days');
  const [selectedLoad, setSelectedLoad]: [string, any] = useState(
    new URLSearchParams(history.location.search).get('load_name') || ''
  );
  const [showForm, setShowForm] = useState(false);
  const [showLoadViewFull, setShowLoadViewFull] = useState(
    new URLSearchParams(history.location.search).has('view_load_full')
  );
  const [loadTable, setLoadTable]: [Array<LoadInfoRow>, any] = useState([]);
  const userContext = useContext(UserContext);
  const [deleteRows, setDeleteRows] = useState(false);

  const [loadsHasSelection, setLoadsHasSelection] = useState(false);

  useEffect(() => {
    return history.listen(() => {
      let params = new URLSearchParams(history.location.search);
      if (params.has('view_load_full')) {
        setShowLoadViewFull(true);
      } else {
        setShowLoadViewFull(false);
      }
      if (params.has('load_name')) {
        setSelectedLoad(params.get('load_name'));
      } else {
        setSelectedLoad('');
      }
    });
  }, [history, selectedLoad]);

  return (
    <Styles.Page
      style={{
        minHeight: PAGE_HEIGHT,
        minWidth: PAGE_WIDTH,
        maxWidth: PAGE_WIDTH,
      }}
    >
      <Styles.PageHeader>Production Loads & Reporting</Styles.PageHeader>

      {/* Filters: StartDate(From), EndDate(To), Status, and Reset Filters Button */}
      <FiltersContainer
        pageWidth={PAGE_WIDTH}
        pageHeight={PAGE_HEIGHT}
        style={{ display: selectedLoad.length > 0 ? 'none' : 'flex' }}
      >
        <Styles.FlexDiv
          style={{
            flex: '1 0 auto',
            minWidth: PAGE_WIDTH * 0.1,
            minHeight: PAGE_HEIGHT * 0.1,
          }}
        />
        <Styles.FlexDiv
          style={{
            flex: '5 1 auto',
            justifyContent: 'center',
          }}
        >
          <Styles.FlexDiv style={{ flexDirection: 'column' }}>
            <Styles.Text style={{ marginBottom: 0 }}>From</Styles.Text>
            <DatePicker
              value={startDate}
              renderInput={(params) => <TextField {...params} sx={{width: 175, marginRight: '10px'}} />}
              onChange={(newValue) => {
                setStartDate(formattedDate(newValue));
              }}
            />
          </Styles.FlexDiv>
          <Styles.FlexDiv style={{ flexDirection: 'column' }}>
            <Styles.Text style={{ marginBottom: 0 }}>To</Styles.Text>
            <DatePicker
              value={endDate}
              renderInput={(params) => <TextField {...params} sx={{width: 175, marginRight: '10px'}} />}
              onChange={(newValue) => {
                setEndDate(formattedDate(newValue));
              }}
            />
          </Styles.FlexDiv>
          <Styles.FlexDiv style={{ flexDirection: 'column' }}>
            <Styles.Text style={{ marginBottom: 0 }}>Status</Styles.Text>
            <Autocomplete
              disablePortal
              id="status"
              options={statusOptions}
              renderInput={(params) => <TextField {...params} />}
              defaultValue={'30 Days'}
              inputValue={status}
              onChange={(event, newValue) => {
                setStatus(newValue);
              }}
              disableClearable
              sx={{ width: 150, marginRight: '10px' }}
            />
          </Styles.FlexDiv>
          <Styles.FlexDiv
            style={{
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <MuiButton
              variant="contained"
              onClick={() => {
                setStartDate(THIRTY_DAYS_AGO.toISOString().slice(0, 10));
                setEndDate(new Date().toISOString().slice(0, 10));
                setStatus('30 Days');
              }}
            >
              Reset Filters
            </MuiButton>
          </Styles.FlexDiv>
          
        {/* Delete Selected Button */}
        </Styles.FlexDiv>
        <Styles.FlexDiv
          style={{
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}>
            <MuiButton
            disabled={!loadsHasSelection}
            variant='contained'
            color='error'
            onClick={()=>{
              setDeleteRows(true);

              }}>
              Delete Loads
            </MuiButton>
          </Styles.FlexDiv>
        <AddLoadContainer pageWidth={PAGE_WIDTH} pageHeight={PAGE_HEIGHT}>
          <MuiButton
            variant="contained"
            color="success"
            onClick={(_) => setShowForm(true)}
          >
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: '10px' }} />
            Add New Load
          </MuiButton>
        </AddLoadContainer>
      </FiltersContainer>

      {/* Main Table */}
      {selectedLoad.length <= 0 && (
        <MainTableContainer
          pageWidth={PAGE_WIDTH}
          pageHeight={PAGE_HEIGHT}
          style={{
            overflowX: userContext.isMobile ? 'hidden' : 'scroll',
          }}
        >
          <LoadsTable
            startDate={startDate}
            endDate={endDate}
            status={status}
            setSelectedLoad={(e: string) => setSelectedLoad(e)}
            setLoadRows = {setLoadTable}
            deleteRows={deleteRows}
            setDeleteRows={setDeleteRows}
            setLoadsHasSelection={setLoadsHasSelection}
          />
        </MainTableContainer>
      )}

      {/* Details */}
      <DetailsLink
        to={'/loads'}
        style={{
          display: selectedLoad.length > 0 && !showLoadViewFull ? '' : 'none',
          textDecoration: 'none',
        }}
      >
        <MuiButton variant="contained" style={{ marginBottom: 0 }}>
          Back
        </MuiButton>
      </DetailsLink>
      {selectedLoad.length > 0 && !showLoadViewFull && (
        <ReportsContainer pageWidth={PAGE_WIDTH} pageHeight={PAGE_HEIGHT}>
          <InfoContainer pageWidth={PAGE_WIDTH} pageHeight={PAGE_HEIGHT}>
            <InfoContainer
              pageWidth={PAGE_WIDTH}
              pageHeight={PAGE_HEIGHT}
              style={{
                alignItems: 'stretch',
                flex: '1 1 0',
                maxHeight: PAGE_HEIGHT * 0.8,
                overflow: 'scroll',
                margin: 0,
              }}
            >
              <LoadPositivity loadName={selectedLoad} />
              <LoadTotals loadName={selectedLoad} />
            </InfoContainer>
          </InfoContainer>
          <Styles.FlexDiv
            style={{
              flex: '1',
              maxWidth: PAGE_WIDTH * 0.2,
              minHeight: PAGE_HEIGHT,
            }}
          >
            <LoadDetails loadName={selectedLoad} />
          </Styles.FlexDiv>
        </ReportsContainer>
      )}

      {/* Add New Load Popup */}
      {showForm && (
        <NewLoadPopup pageWidth={PAGE_WIDTH} pageHeight={PAGE_HEIGHT}>
          <LoadForm
            setShowForm={(visible: boolean) => setShowForm(visible)}
            showForm={showForm}
            refreshTable = {() => void 0}
            loadsTable = {loadTable}
            isEditing = {false}
          />
        </NewLoadPopup>
      )}

      {/* Add New Load Tint */}
      <div
        onClick={() => setShowForm(false)}
        style={{
          display: showForm ? '' : 'none',
          position: 'absolute',
          left: 0,
          top: window.innerHeight - PAGE_HEIGHT,
          width: PAGE_WIDTH,
          height: PAGE_HEIGHT,
          backgroundColor: '#000C',
          zIndex: 50,
        }}
      />

      {/* View Full Load */}
      {showLoadViewFull && selectedLoad.length > 0 && (
        <FullViewContainer pageWidth={PAGE_WIDTH} pageHeight={PAGE_HEIGHT}>
          <LoadViewFull load={selectedLoad} />
        </FullViewContainer>
      )}

      <Styles.FlexDiv style={{ flex: '1' }} />
    </Styles.Page>
  );
};

export default Loads;
